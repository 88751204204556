import type { FranchiseeAreaGeoJsonFeature } from '@nexdynamic/squeegee-common';
import {
    getAreaGeoJsonFeatureIsValid,
    type FranchiseeAreaData,
    type FranchiseeAreaGeoJsonFeatureCollection,
    type LngLat,
    type StandardApiResponseWithData,
    type UpdateFranchiseeAreaRequestBody,
} from '@nexdynamic/squeegee-common';
import * as turf from '@turf/turf';
import { enqueueSnackbar } from 'notistack';
import type { StateCreator } from 'zustand';
import { Api } from '../../../../Server/Api';
import { getUpdatedFranchiseeAreaData } from '../../helpers/getUpdatedFranchiseeAreaData';
import type { BoundFranchiseMapStore } from '../boundFranchiseMapStore';

type EditAreaStoreState = {
    isEditing: boolean;
    franchiseeId: string | null;
    candidateFeatureCollection: FranchiseeAreaGeoJsonFeatureCollection | null;
    currentFeatureCollection: FranchiseeAreaGeoJsonFeatureCollection | null;
    error: string | null;
};
type EditAreaStoreActions = {
    createPolygonFeature: (feature: GeoJSON.Feature<GeoJSON.Polygon>) => void;
    updatePolygonFeature: (features: Array<FranchiseeAreaGeoJsonFeature>) => void;
    deletePolygonFeature: (features: Array<FranchiseeAreaGeoJsonFeature>) => void;
    clearError: () => void;
    saveCandidateAreaGeoJson: () => void;
    cancelEdit: () => void;
    getExpandedArea: ({
        franchiseeId,
        currentAreaPolygonFeature,
        pointsToInclude,
    }: {
        franchiseeId: string;
        currentAreaPolygonFeature: FranchiseeAreaGeoJsonFeatureCollection;
        pointsToInclude: Array<LngLat>;
    }) => Promise<void>;
    editFranchiseeArea: (franchiseeId: string, currentPolygon: FranchiseeAreaGeoJsonFeatureCollection) => void;
};

export type EditAreaStoreSlice = EditAreaStoreState & EditAreaStoreActions;

// helpers
const initialState: EditAreaStoreState = {
    isEditing: false,
    franchiseeId: null,
    candidateFeatureCollection: null,
    currentFeatureCollection: null,
    error: null,
};
export const createEditAreaSlice: StateCreator<BoundFranchiseMapStore, [], [], EditAreaStoreSlice> = (set, get) => ({
    ...initialState,
    clearError: () => {
        set({ error: null });
    },
    createPolygonFeature: feature => {
        if (!getAreaGeoJsonFeatureIsValid(feature)) {
            set({ error: 'Invalid area polygon feature' });
            return;
        }

        const existingCandidateFeatureCollection = get().candidateFeatureCollection;
        if (!existingCandidateFeatureCollection?.features.length) {
            throw new Error('Cannot create candidate area without candidateFeatureCollection');
        }

        const existingFeatures = existingCandidateFeatureCollection.features;

        const featureToAdd: FranchiseeAreaGeoJsonFeature = {
            ...feature,
            properties: { ...existingFeatures[0].properties },
        };
        const updatedFeatureCollection = { ...existingCandidateFeatureCollection, features: [...existingFeatures, featureToAdd] };

        set({ candidateFeatureCollection: updatedFeatureCollection });
    },
    updatePolygonFeature: updatedFeatures => {
        const candidateFeatureCollection = get().candidateFeatureCollection;
        if (!candidateFeatureCollection) {
            throw new Error('Cannot update candidate area without candidateFeatureCollection');
        }
        const updatedFeatureIds = updatedFeatures.map(f => f.id);
        const updatedCandidateFeatureCollectionFeatures = [
            ...candidateFeatureCollection.features.filter(feature => !updatedFeatureIds.includes(feature.id)),
            ...updatedFeatures,
        ];

        const updatedFeatureCollection = { ...candidateFeatureCollection, features: updatedCandidateFeatureCollectionFeatures };

        if (!updatedFeatures.every(feature => getAreaGeoJsonFeatureIsValid(feature))) {
            set({ error: 'Invalid area polygon feature' });
        } else {
            set({ candidateFeatureCollection: updatedFeatureCollection });
        }
    },
    deletePolygonFeature: features => {
        const candidateFeatureCollection = get().candidateFeatureCollection;
        if (!candidateFeatureCollection) {
            throw new Error('cannot delete candidate area without candidateFeatureCollection');
        }

        const featuresToDelete = features.map(f => f.id);
        const updatedCandidateFeatureCollectionFeatures = candidateFeatureCollection.features.filter(
            feature => !featuresToDelete.includes(feature.id)
        );
        const updatedFeatureCollection = { ...candidateFeatureCollection, features: updatedCandidateFeatureCollectionFeatures };

        set({ candidateFeatureCollection: updatedFeatureCollection });
    },
    cancelEdit: () => {
        set(initialState);
    },
    saveCandidateAreaGeoJson: async () => {
        // todo set area on franchsisee
        const candidateAreaGeoJson = get().candidateFeatureCollection;
        if (!candidateAreaGeoJson) {
            console.warn('Cannot save candidate area without candidateAreaGeoJson');
            return;
        }

        const franchiseeId = get().franchiseeId;
        if (!franchiseeId) {
            console.warn('Cannot save candidate area without franchiseeId');
            return;
        }

        const { data: response } =
            (await Api.put<StandardApiResponseWithData<UpdateFranchiseeAreaRequestBody>>(null, '/api/franchise/update-area', {
                areaPolygonFeatureCollection: candidateAreaGeoJson,
                franchiseeId,
            })) || {};

        if (!response?.success) {
            set({ error: 'Failed to update franchisee area' });
            enqueueSnackbar('Failed to update franchisee area', { variant: 'error' });
        } else {
            const updatedAreas = new Array<FranchiseeAreaData>();

            get().areas.forEach(area => {
                if (area.franchiseeId === franchiseeId) {
                    const { areaGeoJson: updatedAreaGeoJson, markers: updatedMarkers } = getUpdatedFranchiseeAreaData(
                        area.markers,
                        candidateAreaGeoJson
                    );

                    const updatedAreaData = { ...area };
                    updatedAreaData.areaGeoJson = updatedAreaGeoJson;
                    updatedAreaData.markers = updatedMarkers;

                    updatedAreas.push(updatedAreaData);
                } else updatedAreas.push(area);
            });

            set({ ...initialState, areas: updatedAreas });
        }
    },
    getExpandedArea: async ({ franchiseeId, currentAreaPolygonFeature, pointsToInclude }) => {
        if (!currentAreaPolygonFeature) {
            console.warn('No area polygon feature found for franchiseeId:', franchiseeId);
            return;
        }

        const convertedPoints = pointsToInclude.map(([lng, lat]) => turf.point([lng, lat]));

        const response = await Api.post<StandardApiResponseWithData<FranchiseeAreaGeoJsonFeature>>(null, '/api/franchise/extend-area', {
            points: convertedPoints,
            areaPolygonFeature: currentAreaPolygonFeature,
        }).then(res => res?.data);

        if (!response?.success) {
            console.warn('Failed to extend franchisee area:', response);
            return;
        }

        const newPolygon = { ...response.data, properties: currentAreaPolygonFeature.features[0].properties };

        const mergedCollection = turf.featureCollection([newPolygon]);

        set({
            franchiseeId,
            candidateFeatureCollection: mergedCollection,
            currentFeatureCollection: currentAreaPolygonFeature,
        });
    },
    editFranchiseeArea: (franchiseeId: string, currentAreaGeoJson: FranchiseeAreaGeoJsonFeatureCollection) => {
        set({
            selectedAreaIds: [franchiseeId],
            selectedMarkerIds: [],
            isEditing: true,
            franchiseeId,
            currentFeatureCollection: currentAreaGeoJson,
            candidateFeatureCollection: currentAreaGeoJson,
        });
    },
});

// selectors
export const selectIsEditing = (state: EditAreaStoreSlice) => state.isEditing;
export const selectCurrentEditingFranchiseeId = (state: EditAreaStoreSlice) => state.franchiseeId;
export const selectCandidateFeatureCollection = (state: EditAreaStoreSlice) => state.candidateFeatureCollection;
export const selectCurrentFeatureCollection = (state: EditAreaStoreSlice) => state.currentFeatureCollection;
