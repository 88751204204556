import { wait } from '@nexdynamic/balance-core';
import type { AuditEventAction, AuditSubject, StoredObject } from '@nexdynamic/squeegee-common';
import { AuditEvent } from '@nexdynamic/squeegee-common';
import type { Dexie, Table } from 'dexie';
import dexie from 'dexie';
import { ApplicationState } from './ApplicationState';
import { Logger } from './Logger';
import { Api } from './Server/Api';

export class AuditManager {
    private static _debouncedDeleteAuditItems: StoredObject[] = [];
    private static _debouncedDeleteAuditTimeout: any;
    public static debouncedDeleteAudit(items: StoredObject[]) {
        if (items.length === 0) return;

        clearTimeout(AuditManager._debouncedDeleteAuditTimeout);
        AuditManager._debouncedDeleteAuditItems.push(...items);
        AuditManager._debouncedDeleteAuditTimeout = setTimeout(() => {
            const items = AuditManager._debouncedDeleteAuditItems;
            AuditManager._debouncedDeleteAuditItems = [];
            AuditManager.recordAuditEvent({
                action: items.length > 1 ? 'bulk-delete' : 'delete',
                items: items.filter(x => x.resourceType !== 'alerts'),
            });
        }, 10);
    }
    private static _initialising: Promise<Dexie & { events: Table<AuditEvent, string> }> | null = null;
    private static _auditDb: Dexie & { events: Table<AuditEvent, string> };

    public static async initialise(): Promise<Dexie & { events: Table<AuditEvent, string> }> {
        if (AuditManager._initialising) return AuditManager._initialising;

        AuditManager._initialising = new Promise(async (resolve, reject) => {
            try {
                AuditManager._auditDb = <Dexie & { events: Table<AuditEvent, string> }>(
                    new dexie('squegee_audit', { chromeTransactionDurability: 'relaxed' })
                );
                AuditManager._auditDb.version(1).stores({ events: '&_id' });
                await AuditManager._auditDb.open();
                resolve(AuditManager._auditDb);
            } catch (error) {
                Logger.error('Failed to initialise AuditManager', error);
                reject(error);
            }
        });

        window.sq.AuditManager = AuditManager;

        return AuditManager._initialising;
    }

    public static async recordAuditEvent(
        params: {
            action: AuditEventAction;
        } & (
            | {
                  /** The items that this action relates to e.g. the customer(s) that were deleted. */
                  items: Array<StoredObject>;
              }
            | {
                  /** The full details of the trigger for this action e.g. the paramaters of the shift schedule that triggered this backup action.  */
                  actionDetailsDescription: string;
                  /** The trigger for this action e.g. a backup triggered by a shift schedule. */
                  actionDetailsSummary: string;
              }
        )
    ) {
        const auditData = await AuditManager.initialise();
        if (!auditData) return;

        let auditEvent: AuditEvent;

        const action = params.action;
        if ('items' in params) {
            const items = params.items;
            const subject: AuditSubject = items[0];
            const relatedSubjects: Array<AuditSubject> | undefined = items.length > 1 ? items.slice(1) : undefined;
            auditEvent = new AuditEvent({
                subject,
                action,
                appVersion: ApplicationState.version,
                deviceDescription: ApplicationState.deviceDescription,
                relatedSubjects,
            });
        } else {
            const actionDetailsDescription = params.actionDetailsDescription;
            const actionDetailsSummary = params.actionDetailsSummary;
            auditEvent = new AuditEvent({
                action,
                appVersion: ApplicationState.version,
                deviceDescription: ApplicationState.deviceDescription,
                actionDetailsDescription,
                actionDetailsSummary,
            });
        }

        auditEvent.ownerEmail = ApplicationState.dataEmail;
        auditEvent.createdOnDevice = ApplicationState.deviceId;

        await auditData.events.add(auditEvent);

        AuditManager.initAuditEventSender();
    }

    private static _auditEventSenderTimeout: any;
    public static async initAuditEventSender() {
        const auditData = await AuditManager.initialise();
        if (!auditData) return;

        clearTimeout(AuditManager._auditEventSenderTimeout);

        const sendAuditEvents = async () => {
            try {
                // if (!Api.isConnected || window.location.hostname === 'localhost') return;
                // I've enabled audits to run locally for testing.  Can be disabled by uncommenting the above line and commenting out the line below
                if (!Api.isConnected) return;
                const events = await auditData.events.toArray();
                if (events.length === 0) return;

                let batch = events.splice(0, 200);
                while (batch.length > 0) {
                    try {
                        const submittedEventIds = await Promise.all(events.map(event => submitEvent(event)));
                        const submittedEventIdsSuccess = submittedEventIds.filter(id => id !== null) as string[];
                        if (submittedEventIdsSuccess.length !== events.length) {
                            Logger.error(
                                `Failed to log ${events.length - submittedEventIdsSuccess.length} of ${
                                    events.length
                                } audit events with Squeegee.`
                            );
                        } else {
                            Logger.info(`Logged all ${submittedEventIdsSuccess.length} audit events with Squeegee.`);
                        }
                        if (submittedEventIdsSuccess.length === 0) return;
                        await auditData.events
                            .bulkDelete(events.map(event => event._id))
                            .catch(error => Logger.error('Failed to delete audit events', error));

                        await wait(50);

                        batch = events.splice(0, 200);
                    } catch (error) {
                        Logger.error('Failed to send audit event batch', error);
                    }
                }
            } catch (error) {
                Logger.info('Error sending audit events', error);
            } finally {
                AuditManager._auditEventSenderTimeout = setTimeout(sendAuditEvents, 15000);
            }
        };

        sendAuditEvents();
    }
}
// have put this in a promise due to dexie errors about not being ready to delete items
// https://dexie.org/docs/DexieErrors/Dexie.PrematureCommitError.html
// now seems to be able to do the bulk delete successfully after awaiting our api call to submit the events
const submitEvent = async (event: AuditEvent): Promise<string | null> => {
    return new Promise((resolve, reject) => {
        Api.V2.submitAuditEvent(event)
            .then(success => {
                if (success) {
                    resolve(event._id);
                }
            })
            .catch(error => {
                Logger.error('Failed to submit audit event', error);
                reject(null);
            });
    });
};

export const recordDatabaseUsage = (count: number) => {
    AuditManager.recordAuditEvent({
        action: 'database-usage',
        actionDetailsDescription: `Database has ${count} records`,
        actionDetailsSummary: `Database has ${count} records`,
        items: [],
    });
};
