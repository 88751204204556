import type { ICustomerSummary } from '@nexdynamic/squeegee-common';
import { inject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { AccountActions } from '../../Account/AccountActions';
import { AccountBillingService } from '../../Account/AccountBillingService';
import { SubscriptionFormFields } from '../../Account/Components/subscription-form';
import type { Crumb } from '../../Components/Breadcrumb/breadcrumb';
import { getRedirectOnSuccessUrl } from '../../getRedirectOnSuccessUrl';
@inject(Router)
export class Subscription {
    crumbs: Array<Crumb> = [
        { text: 'route-config.account-details-title', href: '/account' },
        { text: 'route-config.account-subscription-title', href: '/account/subscription' },
    ];
    fields?: SubscriptionFormFields;
    account?: ICustomerSummary;

    constructor(public router: Router) {}
    async attached() {
        this.account = await AccountBillingService.getAccount();

        const currentPlan = this.account?.subscriptions?.length ? this.account.subscriptions[0].plans[0] : null;

        if (currentPlan) {
            this.fields = new SubscriptionFormFields(currentPlan);
        } else {
            this.fields = new SubscriptionFormFields();
        }
    }

    subscribe = async (fields: SubscriptionFormFields) => {
        if (fields.planId) {
            const { success } = await AccountActions.subscribe(fields.planId, fields.users, Boolean(fields.previousPlanId), true);
            const redirectUrl = getRedirectOnSuccessUrl();
            if (success) redirectUrl ? (window.location.href = redirectUrl) : this.router.navigate('/account');
        }
    };
}
