import { Breadcrumbs, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { PageContainer } from '@nexdynamic/nex-ui-react';
import useTranslation from '../../../../hooks/useTranslation';
import AudienceList from '../component/AudienceList';
import AudiencesCreateButton from '../component/AudiencesCreateButton';

export const Audiences = () => {
    const { t } = useTranslation();

    return (
        <PageContainer>
            <Grid item xs={12}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ flex: 1 }}>
                        <Breadcrumbs>
                            <Typography component="h1" variant="subtitle1" color="text.primary">
                                {t('audiences.title')}
                            </Typography>
                        </Breadcrumbs>
                    </Box>
                    <Box>
                        <AudiencesCreateButton />
                    </Box>
                </Box>
            </Grid>

            <Grid item xs={12}>
                <AudienceList />
            </Grid>
        </PageContainer>
    );
};

export default Audiences;
