import type { Campaign, Customer } from '@nexdynamic/squeegee-common';
import type React from 'react';
import { createContext } from 'react';
import useStoredObject from '../../../hooks/useStoredObject';
import useStoredObjects from '../../../hooks/useStoredObjects';
import { useNewCustomerIds } from './hooks/useNewCustomerIds';

export type CampaignProviderType = {
    loading: boolean;
    newCustomers: Array<Customer>;
    campaign: Campaign;
};

export const CampaignProviderContext = createContext<CampaignProviderType>({
    loading: true,
    newCustomers: [],
    campaign: {} as Campaign,
} as CampaignProviderType);

export type CampaignProviderProps = {
    children: React.ReactNode;
} & { id: string };

export const CampaignProvider = ({ children, id }: CampaignProviderProps) => {
    const campaign = useStoredObject<Campaign>(id);

    const { newCustomerIds, loading } = useNewCustomerIds(campaign);

    const newCustomers = useStoredObjects<Customer>('customers', { in: newCustomerIds }).slice();

    if (!campaign) return null;

    const context: CampaignProviderType = {
        loading,
        newCustomers,
        campaign,
    };

    return <CampaignProviderContext.Provider value={context}>{children}</CampaignProviderContext.Provider>;
};
