import type {
    AutoInvoiceMethods,
    AutoInvoiceNotification,
    Customer,
    CustomerNotificationMethodTypes,
    CustomerState,
    JobOccurrence,
    SettingIds,
    TranslationKey,
} from '@nexdynamic/squeegee-common';
import { Account, FrequencyType, JobOccurrenceStatus, Transaction, TransactionType } from '@nexdynamic/squeegee-common';
import { computedFrom, inject } from 'aurelia-framework';
import moment from 'moment';
import { ApplicationState } from '../../ApplicationState';
import { Data } from '../../Data/Data';
import { CustomDialog } from '../../Dialogs/CustomDialog';
import { DialogAnimation } from '../../Dialogs/DialogAnimation';
import { Prompt } from '../../Dialogs/Prompt';
import { Select } from '../../Dialogs/Select';
import { TextDialog } from '../../Dialogs/TextDialog';
import { GlobalFlags } from '../../GlobalFlags';
import { JobService } from '../../Jobs/JobService';
import { LocationUtilities } from '../../Location/LocationUtilities';
import { Logger } from '../../Logger';
import { NotifyUserMessage } from '../../Notifications/NotifyUserMessage';
import { TransactionService } from '../../Transactions/TransactionService';
import { Utilities } from '../../Utilities';
import { CustomerService } from '../CustomerService';
import { updateCustomerUtmParams } from './updateCustomerUtmParams';

@inject(Element)
export class CustomerFormDialog extends CustomDialog<Customer> {
    private static _cssClasses = 'details-dialog has-header-content';
    protected menuTitle: TranslationKey;
    protected menuTitleParams: { [key: string]: string };
    protected changedCustomerSettings: { [Prop in SettingIds]?: any } = {};
    protected failedValidation = false;
    protected startingBalance = 0;
    protected startBalanceOwing = true;
    protected hasAdvancedOrAbove = ApplicationState.hasAdvancedOrAbove;
    protected hasGoCardless = ApplicationState.account.goCardlessPublishableKey;
    protected isAutoInvoiced: boolean;
    private _cleanCustomer: string;
    public isEdit: boolean;
    protected customerNameInput: HTMLInputElement;
    protected balanceType = 'balanced';
    protected globalInvoiceGenerationMethod: undefined | 'manual' | 'auto';
    protected globalAutoInvoiceNotification: undefined | 'manual' | 'auto';
    protected globalHideStripePayButtonFromInvoice: undefined | true | false;
    protected globalHideStripeFromInvites: undefined | true | false;
    protected globalHideGoCardlessSignupFromInvoice: undefined | true | false;
    protected globalHideGocardlessFromInvites: undefined | true | false;
    protected globalHidePrices: undefined | boolean;
    protected isCanvasserOnly: boolean;
    protected emailErrors?: string;

    protected invoiceTemplates = ApplicationState.getSetting<Array<string>>('global.invoice-templates', []);
    protected invoiceTemplateSelected = (event: CustomEvent<{ value: string; index: number }>) => {
        this.customer.invoiceTemplate = event.detail.value;
    };

    protected get globalTaxEnabled() {
        return ApplicationState.account.invoiceSettings.taxEnabled;
    }

    protected get globalTaxRate() {
        return ApplicationState.account.invoiceSettings.taxRate || 0;
    }

    protected get globalHideTax() {
        return ApplicationState.account.invoiceSettings.hideTax || false;
    }

    protected isNew = false;
    protected defaultPaymentPeriod = ApplicationState.account.paymentPeriod || 0;

    protected toggleDefaultNotes(hide: boolean) {
        this.customer.hideDefaultInvoiceNotes = hide;
    }

    constructor(private customer: Customer = CustomerService.create()) {
        super('customer-form-' + customer._id, '../Customers/Components/CustomerFormDialog.html', '', {
            okLabel: '',
            cancelLabel: '',
            cssClass: CustomerFormDialog._cssClasses,
            isSecondaryView: true,
            destructive: true,
        });

        if (customer.name === '' && customer.address && !customer.address.addressDescription) {
            this.isNew = true;
            customer.name = '';
        }

        this.updateNotificationsDisabled();
        if (customer.name) {
            this.menuTitle = 'customers.edit-customer-title';
            this.menuTitleParams = { name: customer.name };
        } else {
            this.menuTitle = 'customers.new-customer-title';
        }

        this.isCanvasserOnly = ApplicationState.isInAnyRole('Canvasser') && !ApplicationState.isInAnyRole(['Admin', 'Owner', 'Creator']);

        if (!this.customer.state) this.customer.state = this.isCanvasserOnly ? 'prospect' : 'active';

        this.globalInvoiceGenerationMethod = ApplicationState.account.invoiceSettings.defaultInvoiceGenerationMethod || 'auto';
        this.globalAutoInvoiceNotification = ApplicationState.account.invoiceSettings.defaultAutoInvoiceNotification || 'manual';
        this.globalHideStripePayButtonFromInvoice = ApplicationState.account.invoiceSettings.hideStripePayButtonFromInvoice;
        this.globalHideStripeFromInvites = ApplicationState.getSetting('global.stripe.hide-from-invite', false);
        this.globalHideGoCardlessSignupFromInvoice = ApplicationState.account.invoiceSettings.hideGoCardlessSignupFromInvoice;
        this.globalHideGocardlessFromInvites = ApplicationState.getSetting('global.gocardless.hide-from-invite', false);
        this.globalHidePrices = ApplicationState.account.hidePricesFromWorkerRole;

        if (customer._externalId === undefined) Data.setUniqueExternalId(customer);

        this._cleanCustomer = JSON.stringify(customer);

        this.updateDefaultNotificationMethodText();
    }
    protected defaultRequireSignature = ApplicationState.account.requireSignature ? 'yes' : 'no';

    protected selectRequireSignature(required?: boolean) {
        this.customer.requireSignature = required;
    }

    protected async editUtmParams() {
        await updateCustomerUtmParams(this.customer);
    }

    protected setHideTax(value?: boolean) {
        this.customer.hideTax = value;
    }

    @computedFrom('globalHideTax', 'customer.hideTax', 'customer.taxRate')
    protected get taxInfo() {
        const hideTax = this.customer.hideTax ?? this.globalHideTax;
        const status = hideTax ? 'but hidden' : 'and shows';

        const taxDriver = this.customer.taxRate === undefined ? 'Global' : 'Customer';

        return `${taxDriver} tax rate is applied ${status} on the customer's invoice.`;
    }

    public async cancel(autoCancelled?: boolean) {
        return (super.cancel && super.cancel(autoCancelled)) || false;
    }

    protected get customerTaxRate() {
        return this.customer.taxRate;
    }

    protected setCustomerTaxRate(taxRate: number) {
        this.customer.taxRate = taxRate;
    }

    public async selectTaxRate() {
        const dialog = new TextDialog(
            'dialogs.settings-invoice-tax-rate-title',
            'dialogs.settings-invoice-tax-rate-label',
            (this.customer.taxRate || 0).toString(),
            'dialogs.settings-invoice-tax-rate-title',
            value => (isNaN(parseFloat(value)) || parseFloat(value) < 0 ? 'validation.tax-rate-invalid' : true),
            false,
            'number-2dp'
        );
        const result = await dialog.show(DialogAnimation.SLIDE);
        if (!dialog.cancelled) this.customer.taxRate = parseFloat(result);
    }

    public async resetTaxRate() {
        this.customer.taxRate = undefined;
    }

    protected async setPaymentPeriod(custom: boolean) {
        if (!custom) {
            this.customer.paymentPeriod = undefined;
        } else {
            const dialog = new TextDialog(
                'dialogs.settings-payment-period-title',
                'dialogs.settings-payment-period-label',
                (ApplicationState.account.paymentPeriod || 0).toString(),
                'dialogs.settings-payment-period-title',
                value =>
                    isNaN(parseInt(value, 10)) || parseInt(value, 10) < 0 || parseInt(value, 10) > 1000
                        ? 'validation.payment-period-invalid'
                        : true,
                false,
                'number'
            );
            const result = await dialog.show(DialogAnimation.SLIDE);
            if (!dialog.cancelled) {
                this.customer.paymentPeriod = parseInt(result, 10);
            }
        }
    }

    protected get requireSignatureDescription() {
        return this.customer.requireSignature === true
            ? 'Yes'
            : this.customer.requireSignature === false
            ? 'No'
            : `Default (${this.defaultRequireSignature})`;
    }

    protected get requireSignature() {
        return this.customer.requireSignature === true || ApplicationState.account.requireSignature;
    }

    protected get automatedPaymentsName() {
        return ApplicationState.dd;
    }

    protected get defaultInvoicingLocalisationParams() {
        return {
            current:
                this.globalInvoiceGenerationMethod === 'manual'
                    ? ApplicationState.localise('jobs.automatic-invoice-manual')
                    : ApplicationState.localise('jobs.automatic-invoice-auto'),
        };
    }

    protected get defaultNotificationLocalisationParams() {
        return {
            current:
                this.globalAutoInvoiceNotification === 'manual'
                    ? ApplicationState.localise('jobs.invoice-notify-manual')
                    : ApplicationState.localise('jobs.invoice-notify-auto'),
        };
    }

    protected ensureTitleCase() {
        this.customer.name = Utilities.toTitleCase(this.customer.name);
        return true;
    }

    protected delegateSave = () => this.save();
    public async abortCancel() {
        const currentCustomer = JSON.stringify(this.customer);

        const customerUpdated = this._cleanCustomer !== currentCustomer;

        if (customerUpdated) await Prompt.saveOrDiscard(this.delegateSave);

        return false;
    }

    public validateCustomer = () => {
        let errors = CustomerService.validateCustomerAndNotifyUI(this.customer);

        const emailErrors = CustomerService.validateCommaSeparatedEmailsAndNotifyUI(this.customer.email);
        this.emailErrors = emailErrors.length ? Utilities.localiseList(emailErrors.map(x => x.text as TranslationKey)) : undefined;

        errors = errors.concat(emailErrors);

        if (errors.length) {
            this.failedValidation = true;
            return false;
        }

        this.failedValidation = false;
        return true;
    };

    public async saveStartingBalance() {
        if (!this.startBalanceOwing) this.startingBalance = 0 - this.startingBalance;
        await TransactionService.deleteOldCustomerStartingBalances(this.customer._id);
        if (this.startingBalance !== 0) {
            const startingBalanceTransaction = new Transaction(
                TransactionType.Adjustment,
                'adjustment.initial-balance',
                this.startingBalance,
                this.customer._id,
                undefined,
                'New customer balance'
            );
            await Data.put(startingBalanceTransaction);
        }
    }

    protected async save() {
        try {
            this.trimFormData();
            if (!this.validateCustomer()) return;

            if (this.isNew) {
                const exists =
                    Data.all<Customer>(
                        'customers',
                        c =>
                            c._id !== this.customer._id &&
                            c.name === this.customer.name &&
                            c.address &&
                            this.customer.address &&
                            c.address.addressDescription === this.customer.address.addressDescription
                    ).length > 0;

                if (exists) {
                    if (
                        !(await new Prompt('possible.duplicate-title', 'duplicate.customer-address-and-name', {
                            okLabel: 'general.save',
                            cancelLabel: 'general.cancel',
                        }).show())
                    ) {
                        return;
                    }
                }
            }

            if (this.customer._externalId && !Data.isExternalIdUnique(this.customer._id, this.customer._externalId)) {
                const prompt = new Prompt('duplicated.reference-title', 'object.this-reference-already-exists', {
                    localisationParams: { reference: this.customer._externalId },
                });
                await prompt.show();
                return;
            }

            this.updateJobStartDatesOnActivation();

            const originalCustomer = <Customer>JSON.parse(this._cleanCustomer);

            if (originalCustomer.address && !LocationUtilities.locationsAreTheSame(originalCustomer.address, this.customer.address)) {
                await LocationUtilities.updateCustomerAndJobLocationsOnVerification(
                    this.customer,
                    this.customer._id,
                    originalCustomer.address,
                    this.customer.address
                );
            }

            for (const setting of Object.keys(this.changedCustomerSettings) as SettingIds[]) {
                const settingValue = this.changedCustomerSettings[setting];
                await ApplicationState.setSetting(setting, settingValue, this.customer._id);
            }

            if (originalCustomer.state !== 'inactive' && this.customer.state === 'inactive') {
                const reason = await CustomerService.getDeactivateDeleteCustomerReasonIfRequired('Deactivation');
                if (reason.cancelled) throw 'You must provide a reason for deactivating the customer';

                this.customer.deactivatedReason = reason.reason;
                this.customer.deactivatedDate = moment().format('YYYY-MM-DD');
            }

            await CustomerService.addOrUpdateCustomer(this.customer);
            if (this.isNew) {
                await this.saveStartingBalance();
                CustomerService.createCustomerCanvasedAlert(this.customer);
            }
            await this.ok(this.customer);
        } catch (error) {
            new NotifyUserMessage('customers.save-error');
            return Logger.error(`Error during save customer ${this.customer._id} in customer form dialog`, error);
        }
    }

    protected updateJobStartDatesOnActivation() {
        const originalCustomer = <Customer>JSON.parse(this._cleanCustomer);
        if (originalCustomer.state === 'inactive' && this.customer.state === 'active') {
            for (const jobId in this.customer.jobs) {
                JobService.setJobDateToNextAvailable(this.customer.jobs[jobId]);
            }
        }
    }

    protected focusBalanceInputElement(elementId: string) {
        const inputElement = document.getElementById(elementId);
        if (inputElement) {
            if (GlobalFlags.isTouchEnabled) inputElement.dispatchEvent(new Event('touchstart'));
            else {
                inputElement.focus();
                inputElement.dispatchEvent(new KeyboardEvent('keypress', { code: '13' }));
            }
        }
    }

    protected emailNotifyDisabled = true;
    protected smsNotifyDisabled = true;
    protected sms2NotifyDisabled = true;
    protected updateNotificationsDisabled() {
        this.emailNotifyDisabled =
            !this.customer.email || !this.customer.email.trim().length || !Account.EMAIL_REGEX.test(this.customer.email);
        this.smsNotifyDisabled = !this.customer.telephoneNumber || !this.customer.telephoneNumber.trim().length;
        this.sms2NotifyDisabled = !this.customer.telephoneNumberOther || !this.customer.telephoneNumberOther.trim().length;
    }

    protected trimFormData() {
        if (this.customer.email)
            this.customer.email = this.customer.email
                .split(',')
                .map(x => x.trim())
                .join(',');
        if (this.customer.telephoneNumber) this.customer.telephoneNumber = this.customer.telephoneNumber.trim();
        if (this.customer.telephoneNumberOther) this.customer.telephoneNumberOther = this.customer.telephoneNumberOther.trim();
    }

    protected async selectInvoiceGenerationMethod(method: AutoInvoiceMethods) {
        this.customer.autoInvoiceMethod = method;
    }

    protected async selectAutoInvoiceNotification(method: AutoInvoiceNotification) {
        this.customer.autoInvoiceNotification = method;
    }

    protected async selectCustomerState(state: CustomerState) {
        this.customer.state = state;
    }

    protected async selectMarketingAccepted(accepted: boolean) {
        this.customer.marketingAccepted = accepted;
    }

    protected get customerHasStripe() {
        return (
            !!this.customer.paymentProviderMetaData?.stripe?.sourceOrMandateId &&
            this.customer.paymentProviderMetaData?.stripe?.status === 'active'
        );
    }
    protected get hideStripeButton() {
        return (
            this.customer.hideStripePayButtonFromInvoice === true ||
            (ApplicationState.account.invoiceSettings && ApplicationState.account.invoiceSettings.hideStripePayButtonFromInvoice === true)
        );
    }
    protected async selectHideStripePayButtonFromInvoice(yes?: boolean) {
        this.customer.hideStripePayButtonFromInvoice = yes;
    }

    protected get customerHasGoCardless() {
        return (
            this.customer.paymentProviderMetaData?.gocardless?.status === 'active' ||
            this.customer.paymentProviderMetaData?.gocardless?.status === 'pending'
        );
    }

    protected get hideGoCardlessButton() {
        return (
            this.customer.hideGoCardlessSignupFromInvoice === true ||
            (ApplicationState.account.invoiceSettings && ApplicationState.account.invoiceSettings.hideGoCardlessSignupFromInvoice === true)
        );
    }

    protected async selectHideGoCardlessSignupFromInvoice(yes?: boolean) {
        this.customer.hideGoCardlessSignupFromInvoice = yes;
    }

    protected get hideStripeFromInvites() {
        return this.customer.hideStripeFromInvites === true || ApplicationState.getSetting('global.stripe.hide-from-invite', undefined);
    }

    protected async selectHideStripeFromInvites(yes?: boolean) {
        this.customer.hideStripeFromInvites = yes;
    }

    protected globalTrackTime: boolean = ApplicationState.getSetting('global.jobs.track-time', false);
    protected customerTrackTime: boolean | undefined = ApplicationState.getSetting(
        'customer.jobs.track-time',
        undefined,
        this.customer._id
    );

    @computedFrom('customerTrackTime')
    protected get trackTime() {
        return this.customerTrackTime !== undefined
            ? this.customerTrackTime
            : ApplicationState.getSetting('customer.jobs.track-time', this.globalTrackTime, this.customer._id);
    }
    protected async selectTrackTime(yes?: boolean) {
        this.customerTrackTime = yes;
        this.changedCustomerSettings['customer.jobs.track-time'] = yes;
    }

    protected get hideGocardlessFromInvites() {
        return (
            this.customer.hideGocardlessFromInvites === true || ApplicationState.getSetting('global.gocardless.hide-from-invite', undefined)
        );
    }

    protected async selectHideGocardlessFromInvites(yes?: boolean) {
        this.customer.hideGocardlessFromInvites = yes;
    }

    protected setBalanceType(type: string) {
        if (type === 'balanced') {
            this.startBalanceOwing = false;
            this.startingBalance = 0;
        } else if (type === 'owing') this.startBalanceOwing = true;
        else this.startBalanceOwing = false;
        this.balanceType = type;
    }

    private _translations = {
        '': 'general.none',
        'email': 'customers.notification-email-label',
        'sms': 'customers.notification-phone-label',
        'sms2': 'customers.notification-alternative-phone-label',
        'email-and-sms': 'customers.notification-email-and-phone-label',
        'email-and-sms2': 'customers.notification-email-and-phone2-label',
    } as { [key: string]: TranslationKey };

    protected defaultNotificationMethodText: TranslationKey;

    protected async defaultNotificationMethod() {
        const options: Array<{ text: TranslationKey; value: string }> = [];

        for (const value in this._translations) options.push({ text: this._translations[value], value });

        const dialog = new Select(
            'dialogs.default-notification-method-title',
            options,
            'text',
            'value',
            this.customer.defaultNotificationMethod
        );
        const result = await dialog.show(DialogAnimation.SLIDE);

        if (!dialog.cancelled) {
            if (result.value) {
                this.customer.defaultNotificationMethod = <CustomerNotificationMethodTypes>result.value;
            } else this.customer.defaultNotificationMethod = undefined;

            this.updateDefaultNotificationMethodText();
        }
    }
    private updateDefaultNotificationMethodText() {
        this.defaultNotificationMethodText = this._translations[this.customer.defaultNotificationMethod || ''];
    }

    protected toggleInvoiceMethod() {
        if (this.customer.autoInvoiceMethod === 'manual') this.customer.autoInvoiceMethod = undefined;
        else this.customer.autoInvoiceMethod = 'manual';
    }

    private _customerHasDoneJobs: boolean | undefined;
    protected get customerHasDoneJobs() {
        if (this._customerHasDoneJobs === undefined) {
            this._customerHasDoneJobs = Data.exists<JobOccurrence>('joboccurrences', {
                customerId: this.customer._id,
                status: JobOccurrenceStatus.Done,
            });
        }

        return this._customerHasDoneJobs;
    }

    private _customerCanBeProspect: boolean | undefined;
    protected get customerCanBeProspect() {
        if (this._customerCanBeProspect === undefined) {
            let canBeProspect = true;

            for (const jobId in this.customer.jobs) {
                const job = this.customer.jobs[jobId];
                if (job.frequencyType !== FrequencyType.NoneRecurring && (job.state || 'active') === 'active') {
                    canBeProspect = false;
                    break;
                }
            }

            this._customerCanBeProspect = canBeProspect;
        }

        return this._customerCanBeProspect;
    }

    protected async selectHidePrices(hidePrices?: boolean) {
        this.customer.hideJobPricesFromWorker = hidePrices;
    }

    protected get defaultHidePricesLocalisationParams() {
        return {
            current: this.globalHidePrices
                ? ApplicationState.localise('customer.hide-prices-hide')
                : ApplicationState.localise('customer.hide-prices-show'),
        };
    }
}
