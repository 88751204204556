import type { Tag, TranslationKey } from '@nexdynamic/squeegee-common';
import { AlphanumericCharColourDictionary, JobOccurrenceStatus, TagType } from '@nexdynamic/squeegee-common';
import type { Subscription } from 'aurelia-event-aggregator';
import { bindable } from 'aurelia-framework';
import moment from 'moment';
import { ApplicationState } from '../../ApplicationState';
import { DialogAnimation } from '../../Dialogs/DialogAnimation';
import { Prompt } from '../../Dialogs/Prompt';
import { ActionBarEvent } from '../../Events/ActionBarEvent';
import { DataRefreshedEvent } from '../../Events/DataRefreshedEvent';
import { LoaderEvent } from '../../Events/LoaderEvent';
import { ViewResizeEvent } from '../../Events/ViewResizeEvent';
import { DateFilter } from '../../Jobs/Filters/DateFilter';
import { Logger } from '../../Logger';
import { NotifyUserMessage } from '../../Notifications/NotifyUserMessage';
import { SelectTagsDialog } from '../../Tags/Components/SelectTagsDialog';
import { Utilities } from '../../Utilities';
import type { ScheduleByGroup } from '../Components/ScheduleByGroup';
import { ScheduleDetailsDialog } from '../Components/ScheduleDetailsDialog';
import { ScheduleItem } from '../Components/ScheduleItem';
import type { ScheduleItemGroup } from '../Components/ScheduleItemGroup';
import { ScheduleService } from '../ScheduleService';
import type { ISortOption } from './ISortData';

export class OverdueView {
    @bindable public searchText: string;
    protected sortDirection: 1 | -1 = -1;
    private _searchDebounce = Utilities.debounce(this.refreshOverdue, 250);
    scheduleItems: Array<ScheduleItem>;
    public searchTextChanged(): void {
        this._searchDebounce();
    }

    protected currencySymbol = ApplicationState.currencySymbol();
    protected dailyTotalJobs = 0;
    protected dailyTotalPrice = '0.00';
    protected scheduleItemsAndGroupsArray: Array<ScheduleItem | ScheduleItemGroup> = [];
    protected selectedSort: ISortOption<keyof ScheduleItem> = {
        text: 'filters.date-title',
        value: [{ sortPropertyName: 'date', sortDirection: -1 }],
    };
    protected selectedRounds: Array<Tag> = [];
    protected selectableMode = false;
    protected updating = true;

    private _dataRefreshedSub: Subscription;
    private _scheduleDataOverdue: ScheduleByGroup;

    public created() {
        new LoaderEvent(true);
    }

    protected showList = false;
    public async attached() {
        await this.filterForOverdue();
        this._dataRefreshedSub = DataRefreshedEvent.subscribe(async (event: DataRefreshedEvent) => {
            if (!event.hasAnyType('joboccurrences', 'customers', 'occurrencesbyday', 'joborderdata')) return;
            this.filterForOverdue();
        });

        new LoaderEvent(false);
        this.showList = true;
        setTimeout(async () => {
            new ViewResizeEvent();
        }, 50);
    }

    public detached() {
        this.showList = false;
        this._dataRefreshedSub?.dispose();
    }

    protected async executePrimary(target: ScheduleItem): Promise<ScheduleItem | null> {
        if (!target.scheduleItemActions.primaryAction) return Promise.reject(null);
        return target.scheduleItemActions.primaryAction.handler();
    }

    protected async executeSecondary(target: ScheduleItem): Promise<ScheduleItem | null> {
        if (!target.scheduleItemActions.secondaryAction) return Promise.reject(null);
        return target.scheduleItemActions.secondaryAction.handler();
    }

    private static _colours = new AlphanumericCharColourDictionary();
    protected getAvatarTextAndColour(scheduleItem: ScheduleItem): { text: string; colour: string } {
        if (!ApplicationState.multiUserEnabled) return { text: '', colour: '' }; //not sure why this is here, but it is

        const avatarText = (this.scheduleItems.indexOf(scheduleItem) + 1).toString();
        let avatarColour = scheduleItem.roundName
            ? OverdueView._colours[scheduleItem.roundName.substring(0, 1).toUpperCase()]
            : OverdueView._colours[avatarText.substring(0, 1).toUpperCase()];

        if (scheduleItem.job.rounds && scheduleItem.job.rounds.length > 0 && scheduleItem.job.rounds[0].color) {
            avatarColour = scheduleItem.job.rounds[0].color;
        }

        return { text: avatarText, colour: avatarColour || OverdueView._colours.default };
    }

    protected getRoundColour(scheduleItem: ScheduleItem): string {
        if (scheduleItem.job.rounds && scheduleItem.job.rounds.length > 0 && scheduleItem.job.rounds[0].color) {
            return scheduleItem.job.rounds[0].color;
        }
        const avatarText = (this.scheduleItems.indexOf(scheduleItem) + 1).toString();
        return (
            (scheduleItem.roundName
                ? OverdueView._colours[scheduleItem.roundName.substring(0, 1).toUpperCase()]
                : OverdueView._colours[avatarText.substring(0, 1).toUpperCase()]) || OverdueView._colours.default
        );
    }

    protected async setSelectedRoundFilter() {
        const dialog = new SelectTagsDialog(this.selectedRounds.slice(), TagType.ROUND, 0, 1);
        const rounds = await dialog.show(DialogAnimation.SLIDE_UP);

        if (!dialog.cancelled) {
            this.selectedRounds = rounds;
            this.refreshOverdue();
        }
    }

    private _filterOverdueDebounce = Utilities.debounce(this.fireFilterForOverdue, 100);
    private async filterForOverdue() {
        this._filterOverdueDebounce();
    }

    private async fireFilterForOverdue() {
        this._scheduleDataOverdue = await ScheduleService.getScheduledJobsByDay(
            moment(),
            DateFilter.OVERDUE,
            [JobOccurrenceStatus.NotDone],
            'date'
        );
        this.refreshOverdue();
    }

    private refreshOverdue() {
        this.updating = true;
        this.scheduleItemsAndGroupsArray = [];
        requestAnimationFrame(async () => {
            try {
                this.scheduleItemsAndGroupsArray = await ScheduleService.updateScheduleByGroupArray(
                    this._scheduleDataOverdue,
                    (date: string) => moment(date).format('ddd, MMM Do'),
                    this.sortDirection,
                    this.selectedSort,
                    this.searchText,
                    this.selectedRounds.length ? this.selectedRounds[0]._id : undefined
                );
                this.updateDailyTotals();
                this.updating = false;
                this.scheduleItems = this.scheduleItemsAndGroupsArray.filter(x => !(x as ScheduleItemGroup).isGroup) as Array<ScheduleItem>;
            } catch (error) {
                Logger.error('Unable to refresh overdue', error);
            }
        });
    }

    public toggleSortDirection() {
        if (this.sortDirection === -1) this.sortDirection = 1;
        else this.sortDirection = -1;
        this.refreshOverdue();
    }

    public async viewScheduleItem(scheduleItem: ScheduleItem) {
        try {
            const dialog = new ScheduleDetailsDialog(scheduleItem);
            await dialog.show(DialogAnimation.SLIDE);
        } catch (error) {
            Logger.error('Error in viewScheduleItem() on OverdueView', { scheduleItem, error });
        }
    }

    public async markAllAsDone(scheduleGroup: ScheduleItemGroup) {
        const prompt = new Prompt('prompts.confirm-title', 'prompts.mark-all-as-done-date-text', {
            okLabel: 'general.yes',
            cancelLabel: 'general.no',
            localisationParams: { date: moment(scheduleGroup.group).format('dddd MMM D YYYY') },
        });
        const result = await prompt.show();
        if (result && scheduleGroup.items && scheduleGroup.items.length) {
            const success = await ScheduleService.completeScheduleItems(scheduleGroup.items);
            if (!success) return;

            for (let i = 0; i < scheduleGroup.items.length; i++) {
                const scheduleItem = scheduleGroup.items[i];
                for (let i = 0; i < this.scheduleItemsAndGroupsArray.length; i++) {
                    const item = this.scheduleItemsAndGroupsArray[i];
                    if (!(<ScheduleItemGroup>item).isGroup && (<ScheduleItem>item).occurrence._id === scheduleItem.occurrence._id) {
                        this.scheduleItemsAndGroupsArray.splice(this.scheduleItemsAndGroupsArray.indexOf(item), 1);
                        break;
                    }
                }
            }
        }
        this.scheduleItemsAndGroupsArray.splice(this.scheduleItemsAndGroupsArray.indexOf(scheduleGroup), 1);
        this.filterForOverdue();
    }

    private updateDailyTotals() {
        let totalPrice = 0;
        const scheduleItems = <Array<ScheduleItem>>this.scheduleItemsAndGroupsArray.filter(scheduleItemAndGroup => {
            return scheduleItemAndGroup instanceof ScheduleItem;
        });
        this.dailyTotalJobs = scheduleItems.length;
        for (let i = 0; i < scheduleItems.length; i++) {
            totalPrice += scheduleItems[i].job.price || 0;
        }
        this.dailyTotalPrice = totalPrice.toFixed(2).toString();
    }

    private setSelectableMode() {
        const selectedItems = <Array<ScheduleItem>>this.scheduleItemsAndGroupsArray.filter(x => (<any>x).selected);
        this.selectableMode = !!selectedItems.length;

        if (this.selectableMode === true) {
            ActionBarEvent.fromSelectedScheduleItems(
                this.unselectAllItems,
                ScheduleService.getBulkActionsForScheduleItem(
                    selectedItems,
                    <Array<ScheduleItem>>this.scheduleItemsAndGroupsArray,
                    this.bulkActionWrapper
                ),
                selectedItems
            );
        } else {
            new ActionBarEvent(false);
        }
    }

    protected selectItem(scheduleItem: ScheduleItem, event: Event | null) {
        if (this.selectableMode) {
            scheduleItem.selected = !scheduleItem.selected;
            this.setSelectableMode();
        } else {
            scheduleItem.selected = true;
            this.setSelectableMode();
        }

        if (event) event.stopPropagation();
    }

    protected selectAllItems = (scheduleItemGroup: ScheduleItemGroup) => {
        scheduleItemGroup.items.forEach(x => (x.selected = !x.selected));
        this.setSelectableMode();
    };

    protected unselectAllItems = () => {
        (<Array<ScheduleItem>>this.scheduleItemsAndGroupsArray).forEach(x => x.selected && (x.selected = false));
        this.setSelectableMode();
    };

    private bulkActionWrapper = async (
        action: (scheduleItems: Array<ScheduleItem>, unselectMethod: () => void) => Promise<any>,
        message: TranslationKey
    ) => {
        const selectedItems = <Array<ScheduleItem>>this.scheduleItemsAndGroupsArray.filter((scheduleItem: ScheduleItem) => {
            return scheduleItem.selected === true;
        });

        if (selectedItems.length) {
            try {
                if (await action(selectedItems, this.unselectAllItems)) {
                    new NotifyUserMessage(message, { count: selectedItems.length.toString() });
                    this.filterForOverdue();
                }
            } catch (error) {
                new NotifyUserMessage('notifications.oh-no');
                Logger.error(`Error during bulk action wrapper in overdue view`, { action, error });
            }
        }
    };
}
