import type {
    CustomerJobLocationMarkerDictionary,
    FranchiseeAreaGeoJsonFeatureCollection,
    FranchiseeMarkerGeoJsonProperties,
} from '@nexdynamic/squeegee-common';
import * as turf from '@turf/turf';
// some  client side turf calculations to determine whether a job is inside a franchisee area after the area has been edited
// so we don't have to await a server sync for this info
export const getUpdatedFranchiseeAreaData = (
    customerJobMarkerDictionary: CustomerJobLocationMarkerDictionary,
    areaGeoJson: FranchiseeAreaGeoJsonFeatureCollection
) => {
    const updatedMarkerDictionary: CustomerJobLocationMarkerDictionary = {};

    Object.entries(customerJobMarkerDictionary).forEach(([customerLngLatId, customerJobMarker]) => {
        const updatedJobInfo: FranchiseeMarkerGeoJsonProperties = { ...customerJobMarker };

        updatedJobInfo.isInFranchiseeArea = areaGeoJson.features.some(feature =>
            turf.booleanPointInPolygon(turf.point(updatedJobInfo.lngLat), feature)
        );

        updatedMarkerDictionary[customerLngLatId] = updatedJobInfo;
    });

    const updatedAreaGeoJson: FranchiseeAreaGeoJsonFeatureCollection = { ...areaGeoJson };

    const totalJobs = Object.values(customerJobMarkerDictionary).reduce((acc, customerJobMarker) => {
        acc += customerJobMarker.jobCount;
        return acc;
    }, 0);

    const jobsInsideArea = Object.values(updatedMarkerDictionary).reduce((acc, customerJobMarker) => {
        if (customerJobMarker.isInFranchiseeArea) {
            return acc + customerJobMarker.jobCount;
        }
        return acc;
    }, 0);

    for (const feature of updatedAreaGeoJson.features) {
        feature.properties = {
            ...feature.properties,
            jobCountData: {
                total: totalJobs,
                insideArea: jobsInsideArea,
                outsideArea: totalJobs - jobsInsideArea,
            },
        };
    }

    return {
        areaGeoJson: updatedAreaGeoJson,
        markers: updatedMarkerDictionary,
    };
};
