import { List } from '@mui/material';
import { EmptyList, SearchQueryField, useSearchQueryParams } from '@nexdynamic/nex-ui-react';
import type { Audience } from '@nexdynamic/squeegee-common';
import { sortByUpdatedDateDesc } from '@nexdynamic/squeegee-common';
import { withErrorBoundary } from 'react-error-boundary';
import ErrorLogger from '../../../../ErrorLogger';
import useStoredObjects from '../../../../hooks/useStoredObjects';
import useTranslation from '../../../../hooks/useTranslation';
import AudienceListItem from './AudienceListItem';
import AudiencesIcon from './AudiencesIcon';

const AudienceList = () => {
    const { t } = useTranslation();
    const audiences = useStoredObjects<Audience>('audiences', { sort: sortByUpdatedDateDesc });

    const {
        searchQuery: [search],
    } = useSearchQueryParams();

    const items = audiences
        ?.filter(template => {
            if (!search) return true;
            return template.name.toLowerCase().includes(search.toLowerCase());
        })
        ?.map(audience => {
            return <AudienceListItem key={audience._id} audienceId={audience._id} />;
        });

    return (
        <>
            <SearchQueryField placeholder={t('general.search-placeholder')} label={t('general.search')} />
            <List role="list" aria-label={t('audiences.aria-audience-list')}>
                {items?.length ? items : <EmptyList text={t('audiences.empty')} icon={<AudiencesIcon />} />}
            </List>
        </>
    );
};

export default withErrorBoundary(AudienceList, { FallbackComponent: ErrorLogger });
