import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { MapV2, useIsSmallScreen } from '@nexdynamic/nex-ui-react';
import { bbox } from '@turf/bbox';
import type { MapLibreEvent } from 'maplibre-gl';
import { LngLatBounds } from 'maplibre-gl';
import { useMemo } from 'react';
import { useBoundFranchiseMapStore } from '../../../stores/boundFranchiseMapStore';
import { createSelectMapLayersForEditMode } from '../../../stores/editAreaSlice/selectors/createSelectMapLayersForEditMode';

export const AreaEditPreviewDialog = ({ open, onClose, onSave }: { open: boolean; onClose: () => void; onSave: () => void }) => {
    const isSmallScreen = useIsSmallScreen();
    const { candidateLayer, currentLayer } = useBoundFranchiseMapStore(createSelectMapLayersForEditMode);

    const handleFitToBounds = (event: MapLibreEvent) => {
        if (candidateLayer) {
            const map = event.target;
            const bounds = bbox(candidateLayer.data);
            map.fitBounds([bounds[0], bounds[1], bounds[2], bounds[3]], { padding: 50 });
        }
    };

    const viewState = useMemo(() => {
        if (!candidateLayer)
            return {
                longitude: -0.13,
                latitude: 51.51,
            };
        const bounds = bbox(candidateLayer.data);

        return {
            bounds: new LngLatBounds([
                [bounds[0], bounds[1]],
                [bounds[2], bounds[3]],
            ]),
            fitBoundsOptions: {
                padding: 200,
            },
        };
    }, [candidateLayer]);

    return (
        <Dialog open={open} onClose={onClose} fullScreen={isSmallScreen}>
            <DialogTitle>Confirm New Area</DialogTitle>
            <DialogContent sx={{ p: 0 }}>
                <Box sx={{ width: '100%', height: { xs: '100%', sm: 500 }, minWidth: 500 }}>
                    {candidateLayer && currentLayer ? (
                        <MapV2
                            initialViewState={viewState}
                            layers={[candidateLayer, currentLayer]}
                            events={{ onMapLoad: handleFitToBounds }}
                            mapStyle="https://api.maptiler.com/maps/streets-v2/style.json?key=cCG7BvNZxuToQVuJ9R6c"
                        />
                    ) : null}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={onSave} color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};
