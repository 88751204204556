import ZoomToIcon from '@mui/icons-material/CenterFocusWeakRounded';
import CloseIcon from '@mui/icons-material/CloseRounded';
import MoreIcon from '@mui/icons-material/MoreVertRounded';
import ExtendAreaIcon from '@mui/icons-material/OpenInFullRounded';
import ExternalIcon from '@mui/icons-material/OpenInNewRounded';
import { Box, IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { useState } from 'react';
import useTranslation from '../../../../hooks/useTranslation';

type Props = {
    onExtend: () => void;
    onOpenCustomer: () => void;
    onZoomTo: () => void;
    onDeselect: () => void;
    disableExtendArea?: boolean;
    disableOpenCustomer?: boolean;
};

export const FranchiseeMarkerActionMenu: React.FC<Props> = ({
    onExtend,
    onOpenCustomer,
    onZoomTo,
    onDeselect,
    disableExtendArea,
    disableOpenCustomer,
}) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleExtend = () => {
        onExtend();
        setAnchorEl(null);
    };

    const handleOpenCustomer = () => {
        onOpenCustomer();
        setAnchorEl(null);
    };

    const handleZoomTo = () => {
        onZoomTo();
        setAnchorEl(null);
    };

    const handleDeselect = () => {
        onDeselect();
        setAnchorEl(null);
    };

    return (
        <Box>
            <IconButton onClick={handleClick}>
                <MoreIcon />
            </IconButton>

            <Menu
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                onClose={() => setAnchorEl(null)}
            >
                <MenuItem onClick={handleExtend} disabled={disableExtendArea}>
                    <ListItemIcon>
                        <ExtendAreaIcon />
                    </ListItemIcon>
                    <ListItemText>{t('franchise-map.actions-extend-area')}</ListItemText>
                </MenuItem>
                <MenuItem onClick={handleOpenCustomer} disabled={disableOpenCustomer}>
                    <ListItemIcon>
                        <ExternalIcon />
                    </ListItemIcon>
                    <ListItemText>{t('franchise-map.actions-open-customer')}</ListItemText>
                </MenuItem>
                <MenuItem onClick={handleZoomTo}>
                    <ListItemIcon>
                        <ZoomToIcon />
                    </ListItemIcon>
                    <ListItemText>{t('franchise-map.actions-zoom-to')}</ListItemText>
                </MenuItem>
                <MenuItem onClick={handleDeselect}>
                    <ListItemIcon>
                        <CloseIcon />
                    </ListItemIcon>
                    <ListItemText>{t('franchise-map.actions-deselect')}</ListItemText>
                </MenuItem>
            </Menu>
        </Box>
    );
};
