import { Box } from '@mui/material';
import { ListPagination, MultiSelectProvider, getPagingQueryVariables } from '@nexdynamic/nex-ui-react';
import type { Audience } from '@nexdynamic/squeegee-common';
import { useState } from 'react';
import Text from '../../../../components/Text';
import useTranslation from '../../../../hooks/useTranslation';
import ContactList from '../../contacts/components/ContactList';
import ContactsCount from '../../contacts/components/ContactsCount';
import useAudienceContacts from '../hooks/useAudienceContacts';

type AudienceContactListProps = {
    audience: Audience;
};
const CONTACTS_PER_PAGE = 15;

const AudienceContactList = ({ audience }: AudienceContactListProps) => {
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState<string | undefined>(undefined);
    const paging = getPagingQueryVariables(page, CONTACTS_PER_PAGE);
    const { t } = useTranslation();
    const { contacts } = useAudienceContacts(audience._id, { paging, search });

    const onChange = (newPage: number) => {
        setPage(newPage);
    };

    const pagingProps = { count: audience.contacts?.length || 0, take: CONTACTS_PER_PAGE, page, onChange };

    return (
        <>
            <MultiSelectProvider disableSelect={true}>
                <Box sx={{ display: 'flex' }}>
                    <Box sx={{ flex: 1 }}>
                        <Text variant="h6" text="general.contacts" sx={{ mb: 1 }} />
                    </Box>
                    <Box>
                        <ContactsCount count={audience.contacts?.length || 0} />
                    </Box>
                </Box>
                <ContactList onSearch={setSearch} contacts={contacts} emptyMessage={t('audiences.no-contacts-in-audience')} />
                <Box sx={{ display: 'flex', justifyContent: 'center', py: 2 }}>
                    <ListPagination {...pagingProps} />
                </Box>
            </MultiSelectProvider>
        </>
    );
};

export default AudienceContactList;
