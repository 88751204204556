import IntersectingIcon from '@mui/icons-material/AdjustRounded';
import NotIntersectingIcon from '@mui/icons-material/HighlightOffRounded';
import type { SelectChangeEvent } from '@mui/material';
import { FormControl, FormLabel, ListItemText, MenuItem, Select, Stack } from '@mui/material';
import { useState } from 'react';
import useTranslation from '../../../../hooks/useTranslation';
import { useCreateCustomerStore } from '../stores/createCustomerStore';
import { createSelectCreateCustomerData } from '../stores/selectors/createSelectCreateCustomerData';
import { MultipleFranchiseeWarningDialog } from './MultipleFranchiseeWarningDialog';
import { NoUserAccountWithPermissionsDialog } from './NoUserAccountWithPermissionsDialog';

export const SelectFranchisee = () => {
    const { t } = useTranslation();

    const { setSelectedFranchiseeId, selectedFranchiseeId } = useCreateCustomerStore();
    const { availableFranchisees, intersectingFranchisees } = useCreateCustomerStore(createSelectCreateCustomerData);
    const [noUserAccountWithPermissionsDialogOpen, setNoUserAccountWithPermissionsDialogOpen] = useState(false);

    const handleChange = (event: SelectChangeEvent<string>) => {
        const franchiseeId = event.target.value;
        if (availableFranchisees.find(f => f.franchiseeId === franchiseeId)?.canCreateCustomerInFranchisee) {
            setSelectedFranchiseeId(franchiseeId);
        } else {
            setNoUserAccountWithPermissionsDialogOpen(true);
        }
    };

    return (
        <FormControl sx={{ gap: 0.5 }}>
            <FormLabel>{t('franchise-map.create-customer-select-franchisee')}</FormLabel>
            <Select
                size="small"
                onChange={handleChange}
                disabled={!availableFranchisees.length}
                fullWidth
                slotProps={{ input: { sx: { padding: '4px 14px' } } }}
                sx={{ minHeight: 37 }} // i hate this, but it's the price we have to pay to have nice list items
                value={selectedFranchiseeId}
            >
                {availableFranchisees
                    .sort((a, b) =>
                        a.isNewPointInFranchiseeArea && !b.isNewPointInFranchiseeArea
                            ? -1
                            : !a.isNewPointInFranchiseeArea && b.isNewPointInFranchiseeArea
                            ? 1
                            : 0
                    )
                    .map(franchisee => (
                        <MenuItem key={franchisee.franchiseeId} value={franchisee.franchiseeId}>
                            <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
                                <ListItemText primary={franchisee.franchiseeName} />
                                {franchisee.isNewPointInFranchiseeArea ? (
                                    <IntersectingIcon color="success" />
                                ) : (
                                    <NotIntersectingIcon color="disabled" />
                                )}
                            </Stack>
                        </MenuItem>
                    ))}
            </Select>
            <MultipleFranchiseeWarningDialog
                open={intersectingFranchisees.length > 1 && !selectedFranchiseeId}
                onClose={() => {
                    console.info('closing multiple franchisee warning dialog');
                }}
                onSelect={(franchiseeId: string) => setSelectedFranchiseeId(franchiseeId)}
            />
            <NoUserAccountWithPermissionsDialog
                open={noUserAccountWithPermissionsDialogOpen}
                onOk={() => setNoUserAccountWithPermissionsDialogOpen(false)}
            />
        </FormControl>
    );
};
