import { Outlet, Route, Routes } from 'react-router';
import { CampaignComponent } from './views/Campaign';
import Campaigns from './views/Campaigns';
import CreateCampaign from './views/CreateCampaign';
import EditCampaign from './views/EditCampaign';

const CampaignsView = () => {
    return <Outlet></Outlet>;
};

const CampaignsRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<CampaignsView />}>
                <Route index element={<Campaigns />} />
                <Route path="create" element={<CreateCampaign />} />
                <Route path=":id" element={<CampaignComponent />} />
                <Route path=":id/edit" element={<EditCampaign />} />
            </Route>
        </Routes>
    );
};

export default CampaignsRoutes;
