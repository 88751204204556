import { Button, CircularProgress, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { EmptyList, MultiSelectProvider } from '@nexdynamic/nex-ui-react';
import type { Audience } from '@nexdynamic/squeegee-common';
import { type Campaign } from '@nexdynamic/squeegee-common';
import { useContext } from 'react';
import { ApplicationState } from '../../../../../ApplicationState';
import { Data } from '../../../../../Data/Data';
import { t } from '../../../../../t';
import Text from '../../../../components/Text';
import ContactList from '../../contacts/components/ContactList';
import ContactsCount from '../../contacts/components/ContactsCount';
import { useExcludeListActions } from '../../contacts/components/hooks/useExcludeListActions';
import { CampaignProviderContext } from '../CampaignProvider';

type NewMembersContactListProps = {
    campaign: Campaign;
};
export const NewMembersContactList = ({ campaign }: NewMembersContactListProps) => {
    const { loading, newCustomers } = useContext(CampaignProviderContext);
    const actions = useExcludeListActions(campaign);

    const audienceLinks = () => {
        const links = new Array<JSX.Element>();
        for (const audienceId of campaign.audiences) {
            const audience = Data.get<Audience>(audienceId)?.name || 'Audience';
            links.push(
                <Button
                    key={audienceId}
                    onClick={() => ApplicationState.navigateToRouteFragment(`/send/audiences/${audienceId}`)}
                    sx={{ alignContent: 'center' }}
                >
                    {audience}
                </Button>
            );
        }
        return links;
    };

    if (!campaign.ongoing && !ApplicationState.features.ongoingCampaigns) return null;

    if (loading) {
        return (
            <>
                <Text variant="h6" text="send.new-customers-in-ongoing-campaign" sx={{ mb: 1 }} />
                <Box sx={{ textAlign: 'center', border: 1, borderColor: 'divider', borderRadius: 1, p: 3, mt: 2 }}>
                    <CircularProgress />
                </Box>
            </>
        );
    }

    if (!newCustomers.length) {
        const links = audienceLinks();
        return (
            <>
                <Text variant="h6" text="send.new-customers-in-ongoing-campaign" sx={{ mb: 1 }} />
                <Box sx={{ display: 'flex' }}>
                    <Typography sx={{ textAlign: 'center', alignContent: 'center' }}>{t('general.audiences')}: </Typography>
                    {links.length ? links.map(link => link) : null}
                </Box>
                <EmptyList text={t('contacts.no-new-members')} />
            </>
        );
    }

    return (
        <Box sx={{ border: 1, borderColor: 'divider', borderRadius: 1, p: 2, mt: 2 }}>
            <Box sx={{ display: 'flex' }}>
                <Box sx={{ flex: 1 }}>
                    <Text variant="h6" text="send.new-customers-in-ongoing-campaign" sx={{ mb: 1 }} />
                </Box>
                <Box>
                    <ContactsCount count={newCustomers.length || 0} />
                </Box>
            </Box>
            <MultiSelectProvider selectableItems={actions ? newCustomers : undefined} actions={actions ? [actions.add] : undefined}>
                <ContactList contacts={newCustomers} emptyMessage={t('contacts.no-new-members')} />
            </MultiSelectProvider>
        </Box>
    );
};
