import WarningIcon from '@mui/icons-material/WarningAmberRounded';
import { Box, Chip, ListItem, ListItemText, Stack, Typography } from '@mui/material';
import { useMap } from '@nexdynamic/nex-ui-react';
import { enqueueSnackbar } from 'notistack';
import useTranslation from '../../../../hooks/useTranslation';
import { getUserHasAccessForFranchisee } from '../../../helpers/getAccessForFranchisee';
import { openCustomer } from '../../../helpers/openCustomer';
import { useBoundFranchiseMapStore } from '../../../stores/boundFranchiseMapStore';
import { createSelectFranchiseeIdToAreaGeoJsonDic } from '../../../stores/franchiseMapSlice/selectors/createSelectFranchiseeToAreaGeoJsonDic';
import type { SelectedJobsPanelData } from '../../../types/SelectedJobsPanelData';
import { FranchiseeMarkerActionMenu } from './FranchiseeMarkerActionMenu';

type Props = {
    selectedMarker: SelectedJobsPanelData;
    openEditPreviewDialog: (open: boolean) => void;
};

export const SelectedMarkerItem = ({ selectedMarker, openEditPreviewDialog }: Props) => {
    const { t } = useTranslation();
    const { getExpandedArea, deselectMarker, areas } = useBoundFranchiseMapStore();

    const map = useMap();
    const franchiseeIdToAreaGeoJsonDic = useBoundFranchiseMapStore(createSelectFranchiseeIdToAreaGeoJsonDic);

    const handleExtendFranchiseeArea = () => {
        const selectedFranchiseeArea = franchiseeIdToAreaGeoJsonDic[selectedMarker.franchiseeId];
        if (!selectedFranchiseeArea) return;
        getExpandedArea({
            currentAreaPolygonFeature: selectedFranchiseeArea,
            franchiseeId: selectedMarker.franchiseeId,
            pointsToInclude: [selectedMarker.lngLat],
        });
        openEditPreviewDialog(true);
    };

    const handleNavigateToCustomer = () => {
        const franchiseeArea = areas.find(area => area.franchiseeId === selectedMarker.franchiseeId);
        const franchiseeOwnerEmail = franchiseeArea?.franchiseeOwnerEmail;

        if (!franchiseeOwnerEmail) {
            enqueueSnackbar(t('franchise-map.error-getting-franchisee'), { variant: 'error' });
            return;
        }

        openCustomer({ customerId: selectedMarker.customerId, franchiseeOwnerEmail });
    };

    const handleZoomTo = () => {
        if (map) map.flyTo({ center: selectedMarker.lngLat, zoom: 14 });
    };

    const handleDeselect = () => {
        deselectMarker(selectedMarker.jobId);
    };

    const disableOpenCustomer = !getUserHasAccessForFranchisee(selectedMarker.franchiseeId);

    return (
        <ListItem
            secondaryAction={
                <FranchiseeMarkerActionMenu
                    onExtend={handleExtendFranchiseeArea}
                    onOpenCustomer={handleNavigateToCustomer}
                    onZoomTo={handleZoomTo}
                    onDeselect={handleDeselect}
                    disableExtendArea={selectedMarker.isInFranchiseeArea}
                    disableOpenCustomer={disableOpenCustomer}
                />
            }
            sx={{
                'pr': 5,
                '.MuiListItemSecondaryAction-root': {
                    right: 8,
                },
            }}
        >
            <ListItemText
                primary={
                    <Stack direction="row" spacing={1}>
                        <Typography>{selectedMarker.customerName}</Typography>
                        {!selectedMarker.isInFranchiseeArea && <WarningIcon color="warning" fontSize="small" />}
                    </Stack>
                }
                secondary={
                    <Box>
                        <Typography variant="body2" color="text.secondary">
                            {selectedMarker.addressDescription}
                        </Typography>
                        <Chip
                            label={selectedMarker.jobCount > 1 ? `${selectedMarker.jobCount} jobs` : `${selectedMarker.jobCount} job`}
                            size="small"
                        />
                    </Box>
                }
                disableTypography
            />
        </ListItem>
    );
};
