import type { CSVImporterProvidedCSVRow, CSVSavedMapping } from '@nexdynamic/nex-ui-react';
import { CSVImporter } from '@nexdynamic/nex-ui-react';
import { Outlet, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { TransactionCSVTemplateSchema, useImportTransactions } from './useImportTransactions';

import { Alert, Box, Button } from '@mui/material';
import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { ApplicationState } from '../ApplicationState';
import AppProviders from '../ReactUI/AppProviders';
import ApplicationStateProvider from '../ReactUI/components/ApplicationStateProvider';
import { BrowserFallBackToHashRouter } from '../ReactUI/components/BrowserFallBackToHashRouter';
import ViewLoading from '../ReactUI/components/ViewLoading';
import { useSetting } from '../ReactUI/hooks/useSetting';
import useTranslation from '../ReactUI/hooks/useTranslation';
export const ImportTransactionsApp = (element: HTMLElement) => {
    const root = ReactDOM.createRoot(element);

    root.render(
        <ApplicationStateProvider>
            <BrowserFallBackToHashRouter>
                <AppProviders>
                    <Suspense fallback={<ViewLoading />}>
                        <Outlet />
                        <Routes>
                            <Route
                                path="/import-transactions/*"
                                element={
                                    <Suspense fallback={<ViewLoading />}>
                                        <Outlet />
                                        <Routes>
                                            <Route path=":id" element={<ImportTransactionsView />} />
                                        </Routes>
                                    </Suspense>
                                }
                            />
                        </Routes>
                    </Suspense>
                </AppProviders>
            </BrowserFallBackToHashRouter>
        </ApplicationStateProvider>
    );

    return {
        unmount: () => root.unmount(),
    };
};

export const ImportTransactionsView = () => {
    const { doImport } = useImportTransactions();
    const [savedMappings, setExistingMappings] = useSetting<CSVSavedMapping[]>('importer.existing-mappings');
    const [previouslyIgnoredRows, setPreviouslyIgnored] = useSetting<string[]>('importer.previously-ignored-rows');
    const { id } = useParams();
    const { t } = useTranslation();

    const navigate = useNavigate();

    const finished = () => {
        navigate(-1);
        ApplicationState.navigateTo('chart-of-accounts', {
            paymentAccountId: id,
        });
    };

    const saveMappings = async (mappings: CSVSavedMapping[]) => {
        const currentMappings = savedMappings || [];
        // Filter out mappings that already exist
        const newMappings = mappings.filter(
            mapping =>
                !currentMappings.some(
                    existing => existing.sourceColumn === mapping.sourceColumn && existing.destinationColumn === mapping.destinationColumn
                )
        );
        // Combine current mappings with new mappings
        setExistingMappings([...currentMappings, ...newMappings]);
    };

    const saveIgnoredRows = async (rows: CSVImporterProvidedCSVRow[]) => {
        // Use a set to ensure no duplicates
        const ignoredRows = new Set(previouslyIgnoredRows);
        for (const row of rows) {
            ignoredRows.add(
                JSON.stringify({
                    ...row,
                    id: undefined, // ignore the id field
                })
            );
        }

        setPreviouslyIgnored(Array.from(ignoredRows));
    };

    if (!id) {
        return (
            <Box p={3}>
                <Alert
                    severity="error"
                    action={
                        <Button onClick={() => navigate(-1)} color="error">
                            Back
                        </Button>
                    }
                >
                    Payment account not found
                </Alert>
            </Box>
        );
    }

    return (
        <>
            <CSVImporter
                savedMappings={savedMappings}
                previouslyIgnoredRows={previouslyIgnoredRows}
                template={TransactionCSVTemplateSchema}
                onFinished={finished}
                startImport={async (data, mappings, ignoredRows) => {
                    await doImport(id, data);
                    if (mappings) {
                        saveMappings(mappings);
                        if (ignoredRows) saveIgnoredRows(ignoredRows);
                    }
                }}
                overrides={{
                    finishButtonLabel: t('importer.finish-label-reconcile'),
                }}
            />
        </>
    );
};
