import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel } from '@mui/material';
import type React from 'react';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import useTranslation from '../../../../hooks/useTranslation';

type Props = {
    open: boolean;
    onClose: () => void;
    onContinue: () => void;
};

export const ExternalCustomerWarning: React.FC<Props> = ({ open, onClose, onContinue }) => {
    const { t } = useTranslation();
    const [showExternalCustomerWarning, setExternalCustomerWarning] = useLocalStorage('showExternalCustomerWarning', true);

    const handleCheckboxChange = () => {
        setExternalCustomerWarning(!showExternalCustomerWarning);
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>External Link</DialogTitle>
            <DialogContent>
                <DialogContentText>{t('franchise-map.external-customer-warning')}</DialogContentText>
                <FormControlLabel
                    label={t('franchise-map.dont-show-again')}
                    control={<Checkbox checked={showExternalCustomerWarning ?? false} />}
                    onChange={handleCheckboxChange}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button variant="contained" color="primary" onClick={onContinue}>
                    {t('general.continue')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
