import type { Dic } from '@nexdynamic/nex-ui-react/src/components/mapsV2/types/Dic';
import { createSelector } from 'reselect';
import type { SelectedJobsPanelData } from '../../../types/SelectedJobsPanelData';
import { selectAreas, selectSelectedCustomerJobLocationMarkerIds } from '../createFranchiseMapSlice';

export const createSelectJobIdToJobInfoDic = createSelector(
    [selectAreas, selectSelectedCustomerJobLocationMarkerIds],
    (areas, selectedMarkerIds) => {
        const jobIdToJobInfoDic: Dic<SelectedJobsPanelData> = {};
        areas.forEach(area => {
            Object.entries(area.markers).forEach(([customerLngLatId, customerJobMarkerDictionary]) => {
                const { franchiseeId, customerId, customerName, addressDescription, lngLat, isInFranchiseeArea, jobCount } =
                    customerJobMarkerDictionary;
                jobIdToJobInfoDic[customerLngLatId] = {
                    markerId: customerLngLatId,
                    selected: selectedMarkerIds.includes(customerLngLatId),
                    franchiseeId,
                    jobCount,
                    jobId: customerLngLatId,
                    customerId,
                    customerName,
                    addressDescription,
                    lngLat,
                    isInFranchiseeArea,
                };
            });
        });
        return jobIdToJobInfoDic;
    }
);
