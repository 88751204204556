import { to2dp } from '@nexdynamic/squeegee-common';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import moment from 'moment';
import { useMemo } from 'react';
import type { ScheduleByGroup } from '../../../Schedule/Components/ScheduleByGroup';
import type { ScheduleItem } from '../../../Schedule/Components/ScheduleItem';
import { AssignmentService } from '../../../Users/Assignees/AssignmentService';
import type { BarData } from '../../components/charts/StackedBarChart';
import { useScheduleItemsByDay } from '../../hooks/useScheduleItemsByDay';

export const useValueFulfillmentWeekStats = (selectedDay: Dayjs, workers: Array<string>) => {
    const { scheduleItemsByDay } = useScheduleItemsByDay(selectedDay.toISOString());
    const barData = useMemo(() => {
        return dayValueData(selectedDay, scheduleItemsByDay, workers);
    }, [scheduleItemsByDay]);

    return barData;
};

export const dayValueData = (selectedDay: Dayjs, scheduleItemsByDay: ScheduleByGroup, workers: Array<string>) => {
    const startOfWeek = moment(selectedDay.toDate()).startOf('isoWeek').toDate();
    const daysOfWeek = [0, 1, 2, 3, 4, 5, 6].map(day => dayjs(startOfWeek).add(day, 'day').toDate());
    const barData: Array<BarData> = [];
    const statsByDay: Record<string, ValueFulfillmentStats> = {};
    const services: Array<string> = [];
    const rounds: Array<string> = [];

    for (const date of daysOfWeek) {
        const dateKey = dayjs(date).format('YYYY-MM-DD');
        const scheduleItems = scheduleItemsByDay[dateKey] ? Object.values(scheduleItemsByDay[dateKey]) : [];
        const stats = getValueFulfillmentForJobs(scheduleItems, workers);
        statsByDay[dateKey] = stats;

        for (const service of Object.keys(stats.serviceSummaries)) {
            if (!service.includes(service)) services.push(service);
        }

        for (const round of rounds) {
            if (!rounds.includes(round)) rounds.push(round);
        }

        const barValue: Record<string, number> = {};
        for (const scheduleItem of scheduleItems) {
            const value = scheduleItem.occurrence.price || 0;

            const assigned = !!AssignmentService.getAssignees(scheduleItem.occurrence).length;

            if (assigned) {
                barValue['Value fufilled'] = (barValue['Value fufilled'] || 0) + value;
            } else {
                barValue['Value unfufilled'] = (barValue['Value unfufilled'] || 0) + value;
            }
        }

        barData.push({ date, ...barValue });
    }

    return { barData, statsByDay };
};

type ValueFulfillmentStats = {
    totalValue: number;
    fufilledValue: number;
    unfufilledValue: number;
    valuePerWorkerId: Record<string, number>;
    serviceSummaries: Record<string, number>;
    roundSummaries: Record<string, number>;
};

export const getValueFulfillmentForJobs = (scheduleItems: Array<ScheduleItem>, workers: Array<string>): ValueFulfillmentStats => {
    let totalValue = 0;
    let fufilledValue = 0;
    let unfufilledValue = 0;

    const serviceSummaries: Record<string, number> = {};
    const roundSummaries: Record<string, number> = {};
    const valuePerWorkerId: Record<string, number> = {};
    for (const scheduleItem of scheduleItems) {
        const occurrence = scheduleItem.occurrence;
        const value = occurrence.price || 0;
        totalValue += value;

        for (const service of occurrence.services) {
            if (!serviceSummaries[service.description]) serviceSummaries[service.description] = 0;
            serviceSummaries[service.description] += value / occurrence.services.length;
        }

        const round = occurrence.rounds?.[0];

        if (round) {
            if (!roundSummaries[round.description]) roundSummaries[round.description] = 0;
            roundSummaries[round.description] += value;
        }

        const assignees: Array<string> = AssignmentService.getAssignees(occurrence, true).map(w => w._id);

        if (assignees.length === 0) {
            unfufilledValue += value;
        }

        for (const worker of workers) {
            if (!valuePerWorkerId[worker]) valuePerWorkerId[worker] = 0;
            if (assignees.includes(worker)) {
                fufilledValue += value;
                valuePerWorkerId[worker] += value;
            }
        }
    }

    for (const id in serviceSummaries) serviceSummaries[id] = to2dp(serviceSummaries[id]);

    for (const id in roundSummaries) roundSummaries[id] = to2dp(roundSummaries[id]);

    for (const id in valuePerWorkerId) valuePerWorkerId[id] = to2dp(valuePerWorkerId[id]);

    return {
        totalValue: to2dp(totalValue),
        fufilledValue: to2dp(fufilledValue),
        unfufilledValue: to2dp(unfufilledValue),
        valuePerWorkerId,
        serviceSummaries,
        roundSummaries,
    };
};
