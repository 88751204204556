import DeleteOutline from '@mui/icons-material/DeleteOutline';
import FinishOutlined from '@mui/icons-material/DoneOutline';
import EditOutlined from '@mui/icons-material/EditOutlined';
import PublishOutlined from '@mui/icons-material/PublishOutlined';
import { CampaignStatus } from '@nexdynamic/squeegee-common';
import { useNavigate } from 'react-router';
import useTranslation from '../../../../hooks/useTranslation';

import type { NexAction, NexActionHandlerResult } from '@nexdynamic/nex-ui-react';
import { useConfirm, useNexAction } from '@nexdynamic/nex-ui-react';
import { useContext } from 'react';
import { CampaignProviderContext } from '../CampaignProvider';
import { campaignFinish, campaignPublish, campaignRemove } from '../CampaignService';

type UseCampaignActions = (id: string) => {
    running: boolean;
    remove: NexAction<() => Promise<NexActionHandlerResult>>;
    publish: NexAction<() => Promise<NexActionHandlerResult>>;
    edit: NexAction<() => void>;
    publishToNewMembersInOngoingCampaign: NexAction<() => Promise<NexActionHandlerResult>>;
};

export const useCampaignActions: UseCampaignActions = id => {
    const navigate = useNavigate();
    const { askForConfirmation } = useConfirm();
    const [handleAction, running] = useNexAction();
    const { t } = useTranslation();
    const { loading, newCustomers, campaign } = useContext(CampaignProviderContext);

    const translations = { name: campaign?.name || '' };

    return {
        running,
        remove: {
            run: async () => {
                return await handleAction(
                    {
                        successMsg: t('campaigns.removed', translations),
                        errorMsg: t('campaigns.remove-failed', translations),
                    },
                    async () => {
                        if (campaign) {
                            const { agreed } = await askForConfirmation({
                                disagreeLabel: t('general.cancel'),
                                agreeLabel: t('general.remove'),
                                title: t('campaigns.remove-confirmation-title'),
                                description: t('campaigns.remove-confirmation-description', translations),
                            });
                            if (agreed) {
                                await campaignRemove(campaign);
                                return { success: true };
                            } else {
                                return { success: true, notify: false };
                            }
                        }
                        return { success: false, errorMsg: t('campaigns.campaign-not-found') };
                    }
                );
            },
            name: t('general.remove'),
            icon: <DeleteOutline />,
        },
        publish: {
            run: async () => {
                return await handleAction(
                    { successMsg: t('campaigns.published', translations), errorMsg: t('campaigns.publish-failed', translations) },
                    async () => {
                        if (campaign) {
                            const { agreed } = await askForConfirmation({
                                title: t('campaigns.publish-confirmation-title'),
                                description: t('campaigns.publish-confirmation-description', translations),
                            });
                            if (agreed && campaign?._id) {
                                const { success, errors } = await campaignPublish(campaign);
                                return { success, errorMsg: errors?.[0] };
                            } else {
                                return { success: true, notify: false };
                            }
                        }

                        return { success: false, errorMsg: 'campaigns.campaign-not-found' };
                    }
                );
            },
            name: t('general.publish'),
            icon: <PublishOutlined />,
            disabled: campaign?.ongoing || campaign?.status === CampaignStatus.Published,
        },
        edit: {
            run: () => navigate(`/send/campaigns/${id}/edit`),
            name: t('general.edit'),
            icon: <EditOutlined />,
            disabled: campaign?.status === CampaignStatus.Published,
        },
        publishToNewMembersInOngoingCampaign: {
            run: async () => {
                return await handleAction(
                    { successMsg: t('campaigns.published', translations), errorMsg: t('campaigns.publish-failed', translations) },
                    async () => {
                        if (campaign) {
                            const { agreed } = await askForConfirmation({
                                title: t('campaigns.publish-confirmation-title'),
                                description: t('campaigns.publish-to-new-members-confirmation-description', {
                                    newMembers: newCustomers.length.toString(),
                                }),
                            });
                            if (agreed && campaign?._id) {
                                const { success, errors } = await campaignPublish(
                                    campaign,
                                    newCustomers.map(c => c._id)
                                );

                                return { success, errorMsg: errors?.[0] };
                            } else {
                                return { success: false, notify: false };
                            }
                        }

                        return { success: false, errorMsg: t('campaigns.campaign-not-found') };
                    }
                );
            },
            name: loading
                ? t('general.loading')
                : campaign.status === CampaignStatus.Published
                ? t('general.republish')
                : t('campaign.ongoing-publish-audience-members-draft'),
            icon: <PublishOutlined />,
            disabled: !campaign?.ongoing || loading || newCustomers.length < 1,
        },
        finishOngoing: {
            run: async () => {
                return await handleAction(
                    {
                        successMsg: t('campaign.ongoing-finished-complete', translations),
                        errorMsg: t('campaign.ongoing-finished-failed', translations),
                    },
                    async () => {
                        if (campaign) {
                            const { agreed } = await askForConfirmation({
                                title: t('campaign.ongoing-finished-confirmation-title'),
                                description: t('campaign.ongoing-finished-confirmation-description', translations),
                            });
                            if (agreed && campaign?._id) {
                                const result = await campaignFinish(campaign);
                                return { ...result, notify: true };
                            } else {
                                return { success: false, notify: true, errorMsg: t('general.cancelled') };
                            }
                        }

                        return { success: false, errorMsg: t('campaigns.campaign-not-found') };
                    }
                );
            },
            name: t(loading ? 'general.loading' : 'campaign.ongoing-finished-button'),
            icon: <FinishOutlined />,
            disabled: !campaign?.ongoing || loading,
        },
    };
};
