import type { TranslationKey } from '@nexdynamic/squeegee-common';
import type { StoredObject } from '@nexdynamic/squeegee-portal-common';
import { ApplicationState } from '../../../ApplicationState';
import { backupServer } from '../../../Data/backupServer';
import { LoaderEvent } from '../../../Events/LoaderEvent';
import { Logger } from '../../../Logger';
import { NotifyUserMessage } from '../../../Notifications/NotifyUserMessage';
import { RethinkDbAuthClient } from '../../../Server/RethinkDbAuthClient';

export const loadBackupIntoMemory = async (backup: { id: string }) => {
    new LoaderEvent(true, true, 'Loading backup into memory at window.backup' as TranslationKey);
    try {
        if (!RethinkDbAuthClient.session) return;

        const url = `${backupServer()}/api/backups/${backup.id}/stream-data`;

        const response = await fetch(url, {
            headers: {
                'Authorization': `Bearer ${RethinkDbAuthClient.session.key}:${RethinkDbAuthClient.session.value}`,
                'data-email': ApplicationState.dataEmail,
            },
        });

        const reader = response.body?.getReader();
        if (!reader) return;

        const decoder = new TextDecoder();
        let buffer = '';
        const backupData: Array<any> = []; // Define the backupData array
        let firstChunk = true;

        const processBuffer = () => {
            let boundary;
            while ((boundary = buffer.indexOf('\n')) >= 0) {
                let chunk = buffer.slice(0, boundary);
                buffer = buffer.slice(boundary + 1);

                if (firstChunk) {
                    chunk = chunk.trim().replace(/^\[\s*/, ''); // Remove the opening bracket
                    firstChunk = false;
                }

                chunk = chunk.trim().replace(/,$/, ''); // Remove trailing comma

                if (chunk.trim() && chunk !== ']') {
                    // Ignore empty lines and the closing bracket
                    try {
                        backupData.push(JSON.parse(chunk));
                    } catch (error) {
                        Logger.error('Failed to parse chunk', { error, chunk });
                    }
                }
            }
        };

        let done = false;
        while (!done) {
            const { value, done: readerDone } = await reader.read();
            done = readerDone;
            if (value) {
                buffer += decoder.decode(value, { stream: !done });
                processBuffer();
            }
        }

        // Process any remaining buffer
        processBuffer();

        (window as any).backup = backupData;
        
        (window as any).backups ??= {} as Record<string, Array<StoredObject>>;
        (window as any).backups[backup.id] = backupData;

        console.log('Backup loaded into memory', backupData);
        new NotifyUserMessage('Backup loaded into memory' as TranslationKey);
    } catch (error) {
        Logger.error('Failed to load backup into memory', { error });
        new NotifyUserMessage('Failed to load backup into memory' as TranslationKey);
    } finally {
        new LoaderEvent(false);
    }
};
