import type { Customer } from '@nexdynamic/squeegee-common';
import type { UtmParams } from '@nexdynamic/squeegee-portal-common';
import { Data } from '../../Data/Data';
import { AureliaReactComponentDialog } from '../../Dialogs/AureliaReactComponentDialog';
import type { UtmParamsDialogOptions } from '../../ReactUI/dialogs/CustomerDialog/UtmParamsDialog';
import UtmParamsDialog from '../../ReactUI/dialogs/CustomerDialog/UtmParamsDialog';

export const updateCustomerUtmParams = async (customer: Customer) => {
    const customerUtmParamsDialog = new AureliaReactComponentDialog<UtmParams, UtmParamsDialogOptions>({
        component: UtmParamsDialog,
        componentProps: {
            utmParams: customer.utm,
        },
        dialogTitle: 'customer-dialog.utm-params-title',
        isSecondaryView: true,
    });

    const utmParams = await customerUtmParamsDialog.show();

    if (customerUtmParamsDialog.cancelled) return;

    if (utmParams) {
        customer.utm = utmParams;
        Data.put(customer);
    }

    return customer;
};
