import { Box, Grid, Typography } from '@mui/material';
import type { Audience } from '@nexdynamic/squeegee-common';
import AudienceActions from './AudienceActions';
import AudienceContactList from './AudienceContactList';

type AudienceDashboardProps = {
    audience: Audience;
};

const AudienceDashboard = ({ audience }: AudienceDashboardProps) => {
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                        <Box sx={{ flex: 1 }}>
                            <Typography m={0} variant="h4" fontWeight="bold">
                                {audience.name}
                            </Typography>
                        </Box>
                        <Box>
                            <AudienceActions audienceId={audience._id} />
                        </Box>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <AudienceContactList audience={audience} />
                </Grid>
            </Grid>
        </>
    );
};

export default AudienceDashboard;
