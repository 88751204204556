import * as turf from '@turf/turf';
import { createSelector } from 'reselect';
import {
    selectFranchiseeAreas,
    selectFranchiseeIdsOwnerHasAccountIn,
    selectIsFranchiseOwner,
    selectSelectedLocation,
} from '../createCustomerStore';

// franchise areas coming into here should already have had permisions checked server side
// although a franchise owner should see all franchisees and their data, there is a chance
// that they do not have an account in all of them with Creator/Admin permissions
// canCreateCustomerInFranchisee prop should account for this.
export const createSelectAvailableFranchisees = createSelector(
    [selectFranchiseeAreas, selectSelectedLocation, selectIsFranchiseOwner, selectFranchiseeIdsOwnerHasAccountIn],
    (franchiseeAreas, selectedLocation, isFranchiseOwner, franchiseeIdsOwnerHasAccountIn) => {
        if (!selectedLocation) return [];
        const franchisees = franchiseeAreas.map(franchiseeArea => {
            return {
                _id: franchiseeArea.franchiseeId,
                name: franchiseeArea.franchiseeName,
                areaGeoJson: franchiseeArea.areaGeoJson,
            };
        });

        const availableFranchisees = franchisees.map(franchisee => {
            if (!selectedLocation.lngLat) {
                throw new Error('Selected location does not have a lngLat');
            }

            const isNewPointInFranchiseeArea = franchisee.areaGeoJson.features.some(polygonFeature => {
                if (!selectedLocation.lngLat) throw new Error('Selected location does not have a lngLat');
                const point = turf.point(selectedLocation.lngLat);
                return turf.booleanPointInPolygon(point, polygonFeature);
            });

            return {
                franchiseeId: franchisee._id,
                franchiseeName: franchisee.name,
                areaGeoJson: franchisee.areaGeoJson,
                isNewPointInFranchiseeArea,
                canCreateCustomerInFranchisee:
                    !isFranchiseOwner || (franchiseeIdsOwnerHasAccountIn && franchiseeIdsOwnerHasAccountIn.includes(franchisee._id)),
            };
        });

        return availableFranchisees;
    }
);
