import { Checkbox, ListItemIcon, ListItemText } from '@mui/material';
import { ElevatedListItemButton, useMultiSelect } from '@nexdynamic/nex-ui-react';
import type { Customer } from '@nexdynamic/squeegee-common';
import type { ReactEventHandler } from 'react';
import { CustomerDialog } from '../../../../../Customers/Components/CustomerDialog';
import { t } from '../../../../../t';

type ContactListItemProps = {
    contact: Customer;
    preventNavigation?: boolean;
};

const ContactListItem = ({ contact, preventNavigation }: ContactListItemProps) => {
    const { select, isSelected } = useMultiSelect();

    const method = contact.email || contact.telephoneNumber || contact.address?.addressDescription || '';
    const primary = contact.name || t('customer.unknown');

    const onChecked: ReactEventHandler<HTMLButtonElement> = event => {
        event.preventDefault();
        event.stopPropagation();
        select?.(contact);
    };

    const checked = isSelected?.(contact);

    return (
        <ElevatedListItemButton
            dense={true}
            role="listitem"
            onClick={() => {
                if (!preventNavigation) {
                    new CustomerDialog(contact).show();
                } else if (select) select(contact);
            }}
        >
            {select ? (
                <ListItemIcon>
                    <Checkbox onClick={onChecked} checked={checked} edge="start" tabIndex={-1} disableRipple />
                </ListItemIcon>
            ) : null}

            <ListItemText primary={primary} secondary={method} primaryTypographyProps={{ sx: { textTransform: 'capitalize' } }} />
        </ElevatedListItemButton>
    );
};

export default ContactListItem;
