import { Breadcrumbs, Grid, Link } from '@mui/material';
import { PageContainer } from '@nexdynamic/nex-ui-react';
import { enqueueSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import { LoaderEvent } from '../../../../../Events/LoaderEvent';
import { Logger } from '../../../../../Logger';
import { Api } from '../../../../../Server/Api';
import Text from '../../../../components/Text';
import CampaignForm from '../components/forms/CampaignForm';
import type { CampaignCreateFields } from '../hooks/useCampaignCreate';
import useCampaignCreate from '../hooks/useCampaignCreate';

const CreateCampaign = () => {
    const navigate = useNavigate();
    const { campaignCreate } = useCampaignCreate();

    const create = async (fields: CampaignCreateFields) => {
        try {
            new LoaderEvent(true, true, 'send.creating-campaign');
            const campaign = await campaignCreate(fields);

            await Api.processSyncQueue();
            if (campaign) navigate(`/send/campaigns/${campaign._id}`);
        } catch (error) {
            Logger.error('Error creating campaign', error);
            enqueueSnackbar('send.error-creating-campaign', { variant: 'error' });
        } finally {
            new LoaderEvent(false);
        }
    };

    return (
        <PageContainer>
            <Grid item xs={12}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link sx={{ cursor: 'pointer' }} component="button" underline="none" onClick={() => navigate('/send/campaigns')}>
                        <Text variant="subtitle1" text="campaigns.title" />
                    </Link>
                    <Text variant="subtitle1" color="text.primary" text="campaigns.create-action" />
                </Breadcrumbs>
            </Grid>

            <Grid item xs={12}>
                <CampaignForm onSubmit={create} />
            </Grid>
        </PageContainer>
    );
};

export default CreateCampaign;
