import type { AccountUser, Job, StoredObject, Team, TranslationKey } from '@nexdynamic/squeegee-common';
import { JobOccurrence, Linker } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../../ApplicationState';
import { Data } from '../../Data/Data';
import { JobService } from '../../Jobs/JobService';
import { UserAuthorisationService } from '../../Users/UserAuthorisationService';
import { UserService } from '../../Users/UserService';
import { Utilities } from '../../Utilities';
import { default as AssignmentLegacyServiceSingleton, default as LegacyAssignmentService } from './AssignmentLegacyService';
type LegacyJob = Job & { assignedTo?: string };

type LegacyJobOccurrence = JobOccurrence & { assignedTo?: string };

type JobOrJobOccurrence = LegacyJob | LegacyJobOccurrence;
export type AssignmentSummary = {
    id: string;
    type: 'team' | 'user' | 'unassigned';
    name: string;
    skills: Array<string>;
    description: string;
};
export class AssignmentService {
    static getDefaultAssignedTo() {
        return AssignmentService.getDefaultAssigneeUserOrTeam()?._id;
    }

    static async clear(occurrence: JobOccurrence) {
        const linker = Data.get<Linker>(Linker.getId(occurrence._id, 'assignment'));
        if (!linker) return;
        await Data.delete(linker);
    }

    static isAssignedToTeam(jobOrJobOccurrence: JobOrJobOccurrence) {
        // legacy
        const legacyAssigned = LegacyAssignmentService.isAssignedToTeam(jobOrJobOccurrence);
        if (legacyAssigned) return true;

        // new
        const assignments = this._getAssignmentLinksForJobOccurrence(jobOrJobOccurrence, true);
        if (!assignments || !assignments.length) return false;
        return assignments.some(assignment => Data.get(assignment)?.resourceType === 'team');
    }

    static isAssignedToUserEmail(jobOrOccurrence: JobOrJobOccurrence, email: string, includeWhereAssignedAsTeamMembers: boolean) {
        const user = UserService.getUser(email);
        if (!user) return false;
        return this.isAssigned(jobOrOccurrence, user._id, includeWhereAssignedAsTeamMembers);
    }

    static getSkills(assignee: AccountUser | Team) {
        if (assignee.resourceType === 'accountuser') {
            return (assignee as AccountUser).skills;
        } else {
            const team = assignee as Team;
            if (!team.members || team.members.length === 0) return [];
            const skills: Array<string> = [];
            for (const member of team.members) {
                const user = Data.get<AccountUser>(member);
                if (!user?.skills) continue;
                skills.push(...user.skills);
            }
            return skills;
        }
    }

    static isAssigned(
        jobOrOccurrence: JobOrJobOccurrence,
        userOrTeamIds: Array<string> | string,
        includeWhereAssignedAsTeamMembers: boolean
    ): boolean {
        if (!Array.isArray(userOrTeamIds)) {
            userOrTeamIds = [userOrTeamIds];
        }

        if (userOrTeamIds.length === 1) {
            return !!this._isAssigned(jobOrOccurrence, userOrTeamIds[0], includeWhereAssignedAsTeamMembers);
        }

        for (const userOrTeamId of userOrTeamIds) {
            if (this._isAssigned(jobOrOccurrence, userOrTeamId, includeWhereAssignedAsTeamMembers)) return true;
        }

        return false;
    }

    static async assignDefaultAssignee(jobOrOccurrence: JobOrJobOccurrence) {
        const defaultAssignee = AssignmentService.getDefaultAssigneeUserOrTeam();
        if (!defaultAssignee) return;
        await this.assign(jobOrOccurrence, defaultAssignee._id, false, true);
    }

    static async assign(
        jobOrOccurrence: JobOrJobOccurrence,
        userOrTeamIds: Array<string> | string,
        alsoUpdateJobs = false,
        replace = false,
        isBulk = false
    ) {
        if (!Array.isArray(userOrTeamIds)) {
            userOrTeamIds = [userOrTeamIds];
        }

        if (userOrTeamIds.includes('UNASSIGNED')) {
            await this.unassign(jobOrOccurrence, undefined, alsoUpdateJobs, isBulk);
            return;
        }

        for (const userOrTeamId of userOrTeamIds) {
            if (!AssignmentService.canAssignToUserOrTeam(jobOrOccurrence, userOrTeamId)) return;
        }

        const jobOccurrenceLinker = this._getOrCreateLinker(jobOrOccurrence, replace);
        let jobLinker: Linker | undefined = undefined;

        if (alsoUpdateJobs && jobOrOccurrence.resourceType === 'joboccurrences') {
            if (!jobOrOccurrence.customerId) throw 'Failed to find customer';
            const job = JobService.getJob(jobOrOccurrence.customerId, (jobOrOccurrence as JobOccurrence).jobId) as LegacyJob;

            if (job) {
                jobLinker = this._getOrCreateLinker(job, replace);

                if (replace && job.assignedTo) {
                    //Clean up legacy assignments
                    job.assignedTo = undefined;
                    await JobService.addOrUpdateJob(jobOrOccurrence.customerId, job);
                }
            }
        } else if (jobOrOccurrence.resourceType !== 'joboccurrences') {
            if (jobOrOccurrence.assignedTo && jobOrOccurrence.customerId) {
                const job = jobOrOccurrence as Job;

                //Clean up legacy assignments
                jobOrOccurrence.assignedTo = undefined;

                await JobService.addOrUpdateJob(jobOrOccurrence.customerId, job);
            }
        }

        for (const userOrTeamId of userOrTeamIds) {
            const userOrTeam = Data.get<AccountUser | Team>(userOrTeamId);
            if (!userOrTeam) throw 'Failed to find user or team';

            Linker.link(jobOccurrenceLinker, userOrTeam);
            if (jobLinker) {
                Linker.link(jobLinker, userOrTeam);
            }
        }

        await Data.put(jobOccurrenceLinker);

        if (jobLinker) await Data.put(jobLinker);

        if (jobOrOccurrence.resourceType !== 'joboccurrences') return;

        if (!replace || !jobOrOccurrence.assignedTo) return;

        //Clean up legacy assignments
        jobOrOccurrence.assignedTo = undefined;

        await Data.put(jobOrOccurrence);
    }

    private static canAssignToUserOrTeam(jobOrOccurrence: JobOrJobOccurrence, userOrTeamId: string) {
        const userOrTeam = Data.get<AccountUser | Team>(userOrTeamId);
        if (!userOrTeam) return false;

        if (userOrTeam.resourceType === 'accountuser') {
            const user = userOrTeam as AccountUser;
            const auth = UserAuthorisationService.getUserAuthorisation(user.email);
            if (ApplicationState.dataEmail === user.email) return true;
            if (!auth || auth.deactivated) return false;
        }

        if (userOrTeam.resourceType === 'team') {
            const team = userOrTeam as Team;

            if (jobOrOccurrence.resourceType === 'joboccurrences') {
                const date = JobOccurrence.getDate(jobOrOccurrence as JobOccurrence);
                if (team.activeFrom && date < team.activeFrom) return false;
                if (team.activeTo && date > team.activeTo) return false;
            }
        }

        return true;
    }

    private static _getOrCreateLinker(jobOrOccurrence: JobOrJobOccurrence, replace: boolean) {
        if (jobOrOccurrence.resourceType !== 'joboccurrences') {
            const jobOrOccurrenceId = jobOrOccurrence._id;
            const linker = Data.get<Linker>(Linker.getId(jobOrOccurrenceId, 'assignment'));

            if (linker) {
                if (replace) {
                    Linker.unlinkAll(linker);
                }
                return linker;
            }

            return new Linker(jobOrOccurrenceId, 'joboccurrences', '', 'assignment');
        } else {
            const jobOrOccurrenceId = jobOrOccurrence._id;
            const linker = Data.get<Linker>(Linker.getId(jobOrOccurrenceId, 'assignment'));

            if (linker) {
                if (replace) {
                    Linker.unlinkAll(linker);
                }
                return linker;
            } else {
                const jobLinker = Data.get<Linker>(Linker.getId((jobOrOccurrence as JobOccurrence).jobId, 'assignment'));
                if (jobLinker && !replace) {
                    const occLinker = new Linker(jobOrOccurrenceId, 'joboccurrences', '', 'assignment');
                    const jobLinks = Linker.getLinks(jobLinker);
                    for (const jobLink of jobLinks) {
                        Linker.link(occLinker, { _id: jobLink.targetId, resourceType: jobLink.value } as StoredObject);
                    }

                    return occLinker;
                } else {
                    return new Linker(jobOrOccurrenceId, 'joboccurrences', '', 'assignment');
                }
            }
        }
    }

    static async unassign(jobOrJobOccurrence: JobOrJobOccurrence, userOrTeamId?: string, alsoUpdateJobs = false, isBulk = false) {
        // legacy
        LegacyAssignmentService.unassign(jobOrJobOccurrence, userOrTeamId || '', alsoUpdateJobs, isBulk);

        const linker = this._getOrCreateLinker(jobOrJobOccurrence, false);
        if (!userOrTeamId) {
            Linker.unlinkAll(linker);
        } else {
            Linker.unlink(linker, userOrTeamId);
        }

        await Data.put(linker);
    }

    private static _linksToAssignees(linked: Array<AccountUser | Team> = [], links: Array<string>, usersOnly: boolean) {
        for (const link of links) {
            const target = Data.get<AccountUser | Team>(link);
            if (target && (!usersOnly || target?.resourceType === 'accountuser')) {
                if (linked.find(x => x._id === target._id)) continue;
                linked.push(target);
            }
        }
        return linked;
    }

    static getAssignees(jobOrJobOccurrence: JobOrJobOccurrence, usersOnly = false): Array<AccountUser | Team> {
        const links = this._getAssignmentLinksForJobOccurrence(jobOrJobOccurrence, usersOnly);

        const assignees: Array<AccountUser | Team> = !links.length ? this.getLegacyAssignees(jobOrJobOccurrence) || [] : [];

        if (!assignees.length && !links.some(x => UserService.getUserOrTeam(x))) {
            const defaultUserOrTeam = this.getDefaultAssigneeUserOrTeam();
            if (!defaultUserOrTeam) return [];

            if (defaultUserOrTeam.resourceType === 'accountuser' || !usersOnly) return [defaultUserOrTeam];

            return UserService.getAllUsersOfTeam(defaultUserOrTeam as Team);
        }

        this._linksToAssignees(assignees, links, usersOnly);

        return assignees;
    }

    static getAssigneeSummaries(jobOrJobOccurrence: JobOrJobOccurrence): Array<AssignmentSummary> {
        const assignees = this.getAssignees(jobOrJobOccurrence);
        const summaries: Array<AssignmentSummary> = [];
        for (const assignee of assignees) {
            summaries.push(AssignmentService.assigneeToSummary(assignee));
        }
        return summaries;
    }

    static assigneeToSummary(assignee: AccountUser | Team): AssignmentSummary {
        let description: string;
        if (assignee.resourceType === 'team') {
            const team = assignee as Team;

            description =
                `Members: ` +
                Utilities.localiseList(
                    team.members
                        .map(id => Data.get<AccountUser>(id))
                        .filter(u => !!u)
                        .map(u => ((u && u.name) || '') as TranslationKey)
                );
        } else {
            const user = assignee as AccountUser;
            description = user.email || 'User';
        }

        return {
            id: assignee._id,
            type: assignee.resourceType === 'accountuser' ? 'user' : 'team',
            name: assignee.name,
            skills: this.getSkills(assignee) || [],
            description,
        };
    }

    private static _getAssignmentLinksForJobOccurrence(jobOrJobOccurrence: JobOrJobOccurrence, includeTeamMembers: boolean): Array<string> {
        const links: Array<string> = [];

        const linker = Data.get<Linker>(Linker.getId(jobOrJobOccurrence._id, 'assignment'));
        if (linker) {
            const assignmentLinksForJobOccurrence = Linker.getLinks(linker);
            links.push(...assignmentLinksForJobOccurrence.map(x => x.targetId));
            if (includeTeamMembers) {
                for (const link of assignmentLinksForJobOccurrence.filter(l => l.value === 'team')) {
                    const team = Data.get<Team>(link.targetId);
                    if (!team || !team.members || team.members.length === 0) continue;
                    links.push(...team.members);
                }
            }
        } else if (jobOrJobOccurrence.resourceType === 'joboccurrences') {
            const jobLinker = Data.get<Linker>(Linker.getId((jobOrJobOccurrence as JobOccurrence).jobId, 'assignment'));
            if (jobLinker) {
                const assignmentLinksForJob = Linker.getLinks(jobLinker);
                links.push(...assignmentLinksForJob.map(x => x.targetId));
                if (includeTeamMembers) {
                    for (const link of assignmentLinksForJob.filter(l => l.value === 'team')) {
                        const team = Data.get<Team>(link.targetId);
                        if (!team || !team.members || team.members.length === 0) continue;
                        links.push(...team.members);
                    }
                }
            }
        }

        return links;
    }

    static isUnassigned(jobOrJobOccurrence: JobOrJobOccurrence) {
        const assignments = this.getAssignees(jobOrJobOccurrence);
        if (assignments.length === 0) return true;
        return false;
    }

    private static getLegacyAssignees(jobOrOccurrence: JobOrJobOccurrence) {
        if (!ApplicationState.multiUserEnabled) return [];
        const assignee: string | undefined = jobOrOccurrence.assignedTo;
        if (!assignee) return [];
        const userOrTeam: AccountUser | Team | undefined = this._getUserOrTeamFromLegacyId(assignee);
        if (!userOrTeam) return [];
        return [userOrTeam];
    }

    private static _isAssigned(jobOrOccurrence: JobOrJobOccurrence, userOrTeamId: string, includeTeamMembers: boolean) {
        // Legacy assignment checks
        const assignments = this._getAssignmentLinksForJobOccurrence(jobOrOccurrence, includeTeamMembers);
        if (assignments.find(x => x === userOrTeamId)) return true;

        const assigned = AssignmentLegacyServiceSingleton.isAssigned(jobOrOccurrence, userOrTeamId, includeTeamMembers);
        if (assigned !== undefined) return assigned;

        if (assignments.length && assignments.some(x => UserService.getUserOrTeam(x))) return false;

        const defaultAssigneeUserOrTeam = this.getDefaultAssigneeUserOrTeam();
        if (defaultAssigneeUserOrTeam && userOrTeamId === defaultAssigneeUserOrTeam._id) return true;

        return false;
    }

    private static _defaultAssigneeUser?: AccountUser | Team | null;

    public static getDefaultAssigneeUserOrTeam(refresh = false) {
        if (refresh) delete this._defaultAssigneeUser;

        if (this._defaultAssigneeUser !== undefined) return this._defaultAssigneeUser;

        if (!ApplicationState.account.defaultAssignee) return (this._defaultAssigneeUser = null);

        const defaultAssigneeUser = UserService.getUser(ApplicationState.account.defaultAssignee);
        if (!defaultAssigneeUser) {
            return (this._defaultAssigneeUser = UserService.getUserOrTeam(ApplicationState.account.defaultAssignee) || null);
        }

        const authorisation = UserAuthorisationService.getUserAuthorisation(defaultAssigneeUser.email);
        if (!authorisation || authorisation.deactivated) return (this._defaultAssigneeUser = null);

        return (this._defaultAssigneeUser = defaultAssigneeUser);
    }

    private static _getUserOrTeamFromLegacyId(id: string) {
        if (!id) return undefined;
        let userOrTeam: AccountUser | Team | undefined;
        const user = UserService.getUser(id);
        if (user) {
            userOrTeam = user;
        } else {
            const team = Data.get<Team>(id);
            if (team) {
                userOrTeam = team;
            }
        }
        return userOrTeam;
    }

    public static async concreteAssigneesOnOccurrence(occurrenceId: string, arrayOfUsersOrTeams: Array<AccountUser | Team>) {
        const linker = Data.get<Linker>(Linker.getId(occurrenceId, 'assignment'));

        // if we dont find a linker create a new one
        if (!linker) {
            const newLinker = new Linker(occurrenceId, 'joboccurrences', '', 'assignment');
            for (const userOrTeam of arrayOfUsersOrTeams) {
                Linker.link(newLinker, userOrTeam);
            }
            await Data.put(newLinker);
        } else {
            // if we find a linker unlink all and link the new users or teams
            Linker.unlinkAll(linker);
            for (const userOrTeam of arrayOfUsersOrTeams) {
                Linker.link(linker, userOrTeam);
            }
            await Data.put(linker);
        }
    }
}
