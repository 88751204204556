import type { Franchisee, FranchiseeAreaData } from '@nexdynamic/squeegee-common';
import { Data } from '../../../Data/Data';

export const getInitialAreas = (): Array<FranchiseeAreaData> => {
    const franchisees = Data.all<Franchisee>('franchisee');

    const areas: Array<FranchiseeAreaData> = franchisees
        .filter(franchisee => franchisee.areaGeoJson)
        .map(
            franchisee =>
                ({
                    franchiseeId: franchisee._id,
                    franchiseeName: franchisee.name,
                    areaGeoJson: franchisee.areaGeoJson,
                    color: franchisee.color || '#000',
                    markers: {},
                } as FranchiseeAreaData)
        );

    return areas;
};
