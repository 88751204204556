import { FormLabel, Stack, TextField } from '@mui/material';
import useTranslation from '../../../../hooks/useTranslation';
import { useCreateCustomerStore } from '../stores/createCustomerStore';

export const CreateOrSelectCustomer = () => {
    const { t } = useTranslation();
    const { setCustomerName, customerName } = useCreateCustomerStore();

    return (
        <Stack spacing={0.5}>
            <FormLabel>{t('franchise-map.create-customer-name')}</FormLabel>
            <TextField onChange={e => setCustomerName(e.target.value)} defaultValue={customerName ?? ''} size="small" autoComplete="off" />
        </Stack>
    );
};
