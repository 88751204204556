export const amusingConsoleMessages = [
    "I don't always test my code, but when I do, I do it in production.",
    'To the optimist, the glass is half full. To the pessimist, the glass is half empty. To the developer, the glass is twice as big as it needs to be.',
    '99 little bugs in the code, 99 little bugs. Take one down, patch it around, 127 little bugs in the code.',
    'Real programmers count from 0.',
    "I'm not lazy, I'm just highly optimized.",
    'It works on my machine.',
    'Why do Java developers wear glasses? Because they don’t C#.',
    'Code never lies, comments sometimes do.',
    'Programming is 10% writing code and 90% understanding why it’s not working.',
    'The best thing about a boolean is even if you are wrong, you are only off by a bit.',
    'There are two hard things in computer science: cache invalidation, naming things, and off-by-one errors.',
    "A SQL query goes into a bar, walks up to two tables and asks, 'Can I join you?'",
    'If at first you don’t succeed; call it version 1.0.',
    'I would love to change the world, but they won’t give me the source code.',
    'In theory, there is no difference between theory and practice. In practice, there is.',
    'There are 10 types of people in the world: those who understand binary, and those who don’t.',
    "A programmer's wife tells him: 'While you’re at the store, buy some milk.' He never comes back.",
    'The hardest part of programming is thinking up the names.',
    'If you put a million monkeys at a million keyboards, eventually one of them will write a Java program. The rest of them will write Perl programs.',
    "I'm not a great programmer; I'm just a good programmer with great habits.",
    'Knock, knock. Who’s there? Recursion. Recursion who? Knock, knock.',
    'To err is human, to forgive divine. Neither is the developer’s job.',
    'Programming is like writing a book... except if you miss a single comma on page 126 the whole thing makes no sense.',
    'The computer was born to solve problems that did not exist before.',
    'Software and cathedrals are much the same – first we build them, then we pray.',
    'First, solve the problem. Then, write the code.',
    'Copy-and-Paste was programmed by programmers for programmers actually.',
    'One man’s crappy software is another man’s full-time job.',
    'It’s not a bug – it’s an undocumented feature.',
    'If debugging is the process of removing software bugs, then programming must be the process of putting them in.',
    'Computers are fast; programmers keep it slow.',
    'In order to understand recursion, one must first understand recursion.',
    'There are two major products that come out of Berkeley: LSD and UNIX. We don’t believe this to be a coincidence.',
    'A good programmer is someone who always looks both ways before crossing a one-way street.',
    'When I wrote this code, only God and I understood what I did. Now only God knows.',
    "Software undergoes beta testing shortly before it’s released. Beta is Latin for 'still doesn’t work.'",
    "It's not a bug, it's a feature.",
    "If it works, don't touch it.",
    'To understand what recursion is, you must first understand recursion.',
    'I think Microsoft named .Net so it wouldn’t show up in a Unix directory listing.',
    'Programming is like being in a dark room and looking for a black cat.',
    'It’s not a bug, it’s an unexpected feature.',
    'There’s no place like 127.0.0.1.',
    'Algorithm: Word used by programmers when they do not want to explain what they did.',
    'Programming is like playing the piano – you can’t hit all the keys at once.',
    'A good algorithm should work in theory, even if it doesn’t work in practice.',
    'I don’t always test my code, but when I do, I do it in production.',
    'Weeks of programming can save you hours of planning.',
    'A program that produces incorrect results twice as fast is infinitely slower.',
    'Programmer: A machine that turns coffee into code.',
    'The most likely way for the world to be destroyed, most experts agree, is by accident. That’s where we come in; we’re computer professionals. We cause accidents.',
    "Don't worry if it doesn't work right. If everything did, you'd be out of a job.",
    'In a room full of top software designers, if two agree on the same thing, that’s a majority.',
    "You know you're a programmer when your Google search history is filled with semicolons.",
    'Sometimes it pays to stay in bed on Monday, rather than spending the rest of the week debugging Monday’s code.',
    'Why do programmers prefer dark mode? Because light attracts bugs.',
    'Real programmers don’t write documentation. If it was hard to write, it should be hard to understand.',
    'If Java had true garbage collection, most programs would delete themselves upon execution.',
    'A syntax error a day keeps the debugger away.',
    'A language that doesn’t affect the way you think about programming is not worth knowing.',
    'It compiles; ship it.',
    'Programming is like a multiplayer game: when you fix a bug, you create more bugs for your friends.',
    'You are in a maze of twisty little code, all alike.',
    'The best code is no code at all.',
    'Always code as if the guy who ends up maintaining your code will be a violent psychopath who knows where you live.',
    'In programming, the hard part isn’t solving problems, it’s deciding what problems to solve.',
    'If brute force doesn’t solve your problems, then you aren’t using enough.',
    'Walking on water and developing software from a specification are easy if both are frozen.',
    'Programming is like humor. If you have to explain it, it’s bad.',
    'The first 90 percent of the code accounts for the first 90 percent of the development time. The remaining 10 percent of the code accounts for the other 90 percent of the development time.',
    'If it wasn’t for C, we’d all be programming in BASI and OBOL.',
    'Beware of bugs in the above code; I have only proved it correct, not tried it.',
    'Before software can be reusable it first has to be usable.',
    'The function of good software is to make the complex appear to be simple.',
    'If you don’t handle exceptions, you’ll be an exception.',
    'The only valid measurement of code quality: WTFs/minute.',
    'Measuring programming progress by lines of code is like measuring aircraft building progress by weight.',
    'If you optimize everything, you will always be unhappy.',
    'Code is like humor. When you have to explain it, it’s bad.',
    'When you are stuck, keep looking until you find the solution. But in programming, it’s the compiler that usually finds it for you.',
    'Programming can be fun, so can cryptography; however, they should not be combined.',
    "You don't need to know how to swim to jump in at the deep end.",
    'A misplaced semi-colon is all that stands between you and a functioning code.',
    'Perl – The only language that looks the same before and after RSA encryption.',
    'Debugging: Removing the needles from the haystack.',
    "Software testing is like checking the navigation system after you've reached your destination.",
    'A bug in code is worth two in the backlogs.',
    'A good programmer is like a magician; they don’t reveal their secrets.',
    'Some days it’s hard to find enough coffee to keep up with the errors.',
    "There's nothing more permanent than a temporary hack.",
    'Your code is broken, and now the whole stack is on fire.',
    'Why do programmers confuse Christmas and Halloween? Because Oct 31 == Dec 25.',
    'Code like it’s the 90s; document like it’s the 80s.',
    'Turning coffee into code, one bug at a time.',
    'The first rule of programming: if it works, don’t touch it.',
    'I love deadlines. I like the whooshing sound they make as they fly by.',
    'Keep calm and code on.',
    'Error 404: Funny quote not found.',
    'Computers make very fast, very accurate mistakes.',
    "Sometimes the best debugging tool is a good night's sleep.",
    "If at first you don't succeed, call it version 1.0 and ship it.",
    'Why did the functions stop calling each other? Because they had constant arguments.',
    'I write code like I play Jenga. With anxiety and the hope nothing falls apart.',
    "My code doesn't always compile, but when it does, I still get warnings.",
    'Backup your work every day, or prepare to relive the same nightmare.',
    'CSS: Where 1px to the left is the difference between perfect and insane.',
    "Debugging: Being the detective in a crime movie where you're also the murderer.",
    'The fastest algorithm is the one you don’t have to write.',
    'Keyboard not found. Press F1 to continue.',
    'It’s all fun and games until someone ends up in a infinite loop.',
    "How many programmers does it take to change a light bulb? None. That's a hardware problem.",
    'Commit code as if the person maintaining it is a professional hitman.',
    "Why do C# and Java developers keep breaking their keyboards? Because they can't C++.",
    'To truly understand recursion, you must first understand recursion.',
    'Walking on water and developing software to spec are easy if both are frozen.',
    "Programming isn't about what you know; it's about what you can Google.",
    "If at first you don't succeed, destroy all evidence that you tried.",
    'Version 1.0 is the version where everything breaks.',
    'Error: Keyboard not found. Press any key to continue.',
    'Debugging is like being the detective in a mystery movie where you are also the murderer.',
    "Git commit -m 'Fixed all the bugs, feels like a dream.'",
    "Unix is user-friendly. It's just selective about who its friends are.",
    'Programmers never die; they just get garbage collected.',
    'There is no Ctrl-Z in life, but there is in Vim.',
    'The computer is mightier than the pen, the sword, and usually, the programmer.',
    'Artificial Intelligence is no match for natural stupidity.',
    'In programming, it’s not a matter of IF there are bugs. It’s a matter of WHEN.',
    'Every great software has a great backstory of a frustrated developer.',
    'You should have been here yesterday when it was working.',
    'Programming without comments is like driving without turn signals.',
    'The best way to get a project done faster is to start sooner.',
    'Writing code is like writing a novel; if it’s too complicated, no one will read it.',
    'God is real, unless declared integer.',
    'Computers are like air conditioners: they stop working properly if you open Windows.',
    'I would like to change the world, but they won’t give me the source code.',
    "If a function is the answer, you're asking the wrong question.",
    'To err is human; to blame someone else shows management potential.',
    'Once you let a bug loose in the code, it can take up residency.',
    'The truth is out there. Use source control.',
    'Breaking news: New programming language is just as bad as the old one.',
    'Out of memory: It happens to the best of us.',
    "There's a fine line between a numerator and a denominator, and only a fraction of people understand that.",
    'SyntaxError: Unexpected character found in developer’s writing.',
    'When in doubt, panic and blame the compiler.',
    'Your debugger is your best friend and your worst enemy.',
    'New feature: Now with 100% more bugs!',
    'If there’s a will, there’s a segmentation fault.',
    'There’s no place like 127.0.0.1.',
    'Old programmers never die; they just can’t C as well.',
    "I make my code readable for myself because I know I'll be reading it 100 more times.",
    "All I ask is a chance to prove that money can't make me write better code.",
    'The hardest part of programming is figuring out what you’re really trying to say.',
    'I’m not a hoarder, I just have backups of all backups of backups.',
    'Computers do what you tell them to do, not what you want them to do.',
    'In programming, the expert can always find something wrong with their own code.',
    'Ever wondered why your computer seems slow? Try uninstalling Java.',
    'Being a good programmer is 3% talent and 97% not being distracted by the internet.',
    'Java is to JavaScript what car is to carpet.',
    'Give a man a program, and frustrate him for a day; teach a man to program, and frustrate him for life.',
    'I haven’t failed at coding. I’ve just found 10,000 ways that won’t work.',
    'I am not anti-social; I am just not user-friendly.',
    'Errors are red, my screen is blue, I think I deleted, system32.',
    'Your program is coded perfectly when you don’t understand it anymore.',
    'I run on coffee, chocolate, and leftover enthusiasm.',
    'There are two kinds of programming languages: the ones people complain about and the ones nobody uses.',
    'I’m not superstitious, but there’s no way I’m changing that code on Friday the 13th.',
    'Why do programmers prefer iOS development? They don’t; they just lost the debate.',
    'In case of fire: git commit, git push, leave building.',
    "Good code is its own best documentation. As you're about to add a comment, ask yourself, 'How can I improve the code so that this comment isn’t needed?'",
    'Remember, the client doesn’t know what they want until you show them what they don’t.',
    'The road to programming hell is paved with global variables.',
    'I have a software-related injury: RSI from too many Ctrl+Z.',
    'Some people, when confronted with a problem, think “I know, I’ll use regular expressions.” Now they have two problems.',
    'When code dies, it should leave a useful error message behind.',
    "Software Developer: A person who solves a problem you didn't know you had, in a way you don't understand.",
    "Friends don't let friends use tabs over spaces.",
    'Why do software developers get Halloween and Christmas confused? Because Oct 31 == Dec 25.',
    'Debugging is a learning process; you learn how many more mistakes you can make.',
    'A bug is an unintentional feature. A feature is an intentional bug.',
    "If you can't convince them, confuse them. That’s why we have so many new frameworks.",
    'A conference call is the best way for a dozen people to say “bye” 300 times.',
    'A slow programmer is still faster than the compiler.',
    'A well-written piece of code is a poem. A poorly written one is a horror story.',
    'Remember: Objects in mirror are closer than they appear. So are the deadlines.',
    'A programmer is a person who fixes a problem you didn’t know you had in a way you don’t understand.',
    'We never really grow up; we just learn how to act in public... and code in private.',
    'A bug in the code is worth two in the issue tracker.',
    'Why don’t coders trust each other? Because they always end up being nested loops.',
    'Nothing like a cup of coffee, some headphones, and a few hundred lines of someone else’s code to ruin a perfectly good day.',
    'My software never has bugs; it just develops random features.',
    'Programming is 90% figuring out why your fix broke everything else.',
    'The internet: where everyone is a coding expert... except you.',
    "Real programmers don't eat quiche; they eat memory leaks and spaghetti code for breakfast.",
    'I drink coffee because it’s illegal to code drunk.',
    'Some code just wants to watch the world burn. Mine prefers a slow, painful death.',
    'I’d fix that bug for you, but then what would I do all day?',
    'I write code like I write my will – with regret and in a panic.',
    'My code is like my ex: it’s needy, it’s broken, and I can’t stop going back to it.',
    'I drink coffee because it’s illegal to code drunk.',
    'I commented out your code because I didn’t want it to feel lonely.',
    'My code doesn’t have bugs; it has undiscovered features... like a Pandora’s box.',
    'To err is human; to blame it on Matt is even better.',
    "Programmer's rule: If you can't find the bug, blame the compiler.",
    'I’d tell you a joke about concurrency, but you might not get it in time.',
    'Writing clean code is like going to the gym. Most people just lie about doing it.',
    'I only write clean code in my nightmares.',
    "I’m a software developer, but my code is like my ex's Instagram: I never want to look back at it.",
    'The code worked on Friday. I don’t know what happened over the weekend.',
    'Sometimes I write ‘null’ in the code just to feel like I fit in.',
    'My code is like a ghost – it haunts me in the night.',
    "Debugging is like being in a toxic relationship – you know it's bad for you, but you keep going back.",
    'The best way to debug is to sacrifice a junior dev to the coding gods.',
    'My coding style is called ‘Trial and Error: Mostly Error.’',
    "I'd explain what my code does, but it's more fun to watch you suffer.",
    'If my code were a person, it would be that guy who shows up uninvited and makes everything awkward.',
    'Your code is like your diet – full of unnecessary junk.',
    'My code is like an unsolved mystery – even Sherlock couldn’t figure it out.',
    'Some people bring joy wherever they go; my code brings joy whenever it goes.',
    'My coding is like my dating life: full of rejections and rare successes.',
    'I program with the same philosophy as my personal life: if it’s broken, ignore it.',
    'I write code the same way some people write ransom notes: under pressure and with lots of threats.',
    'My code is a lot like my social skills – outdated and slightly offensive.',
    "My coding style is called 'YOLO-driven development.'",
    'My code is like a bad tattoo – it’s permanent and everyone who sees it regrets it.',
    'Every time I look at your code, I understand why some people believe in euthanasia.',
    'When in doubt, just blame the junior dev. They’re here to learn, right?',
    'A good senior dev always has a junior to take the fall.',
    'Remember, the first rule of programming: always have a junior to blame.',
    'Every bug is an opportunity... to make the junior fix it.',
    'Junior devs: because someone has to write the tests we’ll never read.',
    'Debugging tip: If it works, a junior dev probably didn’t write it.',
    "At this company, we run on coffee, code, and junior devs' tears.",
    'If you need to sacrifice a keyboard to fix the build, get Matt to do it. It’s good training.',
    'Remember, juniors: there’s no such thing as a stupid question, but there are plenty of stupid answers from seniors.',
    'The secret to a strong codebase? Keep Ollie and Matt away from production.',
    'If your code works, it’s a miracle. If it doesn’t, it’s Ollie’s fault.',
    'Remember, the code you break today will be the bug you fix tomorrow, Ollie.',
    'When Ed says ‘quick fix,’ prepare for an all-nighter.',
    "You know it's serious when Ed sighs heavily before looking at your pull request.",
    'The quickest way to get Ed to help is to mention you’re considering switching to Neovim.',
    "Ed's idea of mentoring junior devs is giving them tasks and then disappearing for a three-hour game of pool.",
    "Whenever something breaks, Jack says, 'That’s weird.' If Ed & Mark say it too, run.",
    'Ed can fix any bug… but first, they’ll make sure you know it was your fault.',
    'You can always count Jack to remind you of that one time you broke production.',
];
