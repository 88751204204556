import { bindable, computedFrom } from 'aurelia-framework';
import type { Plan } from '../../Account/AccountBillingService';
import './plan.scss';

export class PlanCustomElement {
    @bindable plan?: Plan;
    @bindable selectable: boolean;
    @bindable showDescription = true;

    // @computedFrom('plan')
    // get planBackground() {
    //     if (!this.plan) return;
    //     if (this.plan.name && this.plan.name.toLocaleLowerCase().indexOf('advanced') > -1) {
    //         return `background-color: #FFE53B; background-image: linear-gradient(205deg, #FFE53B 0%, #FF2525 74%);`;
    //     }
    //     return `background-color: #21D4FD; background-image: linear-gradient(341deg, #21D4FD 0%, #B721FF 100%)`;
    // }

    @computedFrom('plan')
    get planBackground() {
        if (!this.plan) return;
        switch (this.plan.planLevel) {
            case 'core':
                return `background: linear-gradient(341deg, rgb(33, 212, 253) 0%, rgb(183, 33, 255) 100%)`;
            case 'advanced':
                return `background: linear-gradient(205deg, rgb(255, 229, 59) 0%, rgb(255, 37, 37) 74%)`;

            case 'ultimate':
                return `background: linear-gradient(205deg, rgb(62, 87, 214) 0%, rgb(218, 29, 134) 74%)`;

            case 'infinite':
                return `background: linear-gradient(205deg, rgb(245, 81, 202) 0%, rgb(0, 129, 171) 74%)`;
            default:
                return `background: linear-gradient(341deg, rgb(33, 212, 253) 0%, rgb(183, 33, 255) 100%)`;
        }
    }
}
