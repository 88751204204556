import type {
    Franchisee,
    FranchiseeAreaData,
    StandardApiResponseWithData,
    UpdateFranchiseeColourRequestBody,
} from '@nexdynamic/squeegee-common';
import type { StateCreator } from 'zustand';
import { Api } from '../../../../Server/Api';
import { getFranchiseAreasSummaryData } from '../../helpers/getFranchiseAreas';
import { getInitialAreas } from '../../helpers/getInitialAreas';
import { getUpdatedFranchiseeAreaData } from '../../helpers/getUpdatedFranchiseeAreaData';
import type { SelectedFeatures } from '../../types/SelectedFeatures';
import type { EditAreaStoreSlice } from '../editAreaSlice/createEditAreaSlice';

export type CustomerIdWithJobIdItem = [customerId: string, jobId: string];

export type FranchiseMapStoreActions = {
    fetchAreasDataSummary: () => Promise<void>;
    setSelectedFeatures: (features: SelectedFeatures) => void;
    updateFranchisees: (franchisees: Array<Franchisee>) => void;
    setFranchiseeColorPreview: (params: { franchiseeId: string; color: string }) => Promise<void>;
    setFranchiseeColorSave: (params: { franchiseeId: string; color: string }) => Promise<void>;
    setAreas: (areas: FranchiseeAreaData[]) => void;
    regenerateArea: (franchiseeId: string) => Promise<void>;
    deselectFranchisee: (franchiseeId: string) => void;
    deselectMarker: (markerId: string) => void;
};
export type FranchiseMapStoreState = {
    loading: boolean;
    areas: Array<FranchiseeAreaData>;
    selectedAreaIds: Array<string> | null;
    selectedMarkerIds: Array<string>;
    isFranchiseOwner: boolean | null;
    franchiseeIdsUserHasCreatorOrAdminRolesIn: Array<string> | null;
};
export type FranchiseMapStoreSlice = FranchiseMapStoreState & FranchiseMapStoreActions;

const initialState: FranchiseMapStoreState = {
    loading: false,
    areas: getInitialAreas(),
    selectedAreaIds: null,
    selectedMarkerIds: [],
    isFranchiseOwner: null,
    franchiseeIdsUserHasCreatorOrAdminRolesIn: null,
};
export const createFranchiseMapSlice: StateCreator<FranchiseMapStoreSlice & EditAreaStoreSlice, [], [], FranchiseMapStoreSlice> = (
    set,
    get
) => ({
    ...initialState,

    fetchAreasDataSummary: async () => {
        set({ loading: true });
        const franchiseAreaSummaryData = await getFranchiseAreasSummaryData();
        if (!franchiseAreaSummaryData) return;

        const { franchiseeAreas: areas, isFranchiseOwner, franchiseeIdsUserHasCreatorOrAdminRolesIn } = franchiseAreaSummaryData;
        set({ areas, isFranchiseOwner, franchiseeIdsUserHasCreatorOrAdminRolesIn, loading: false });
    },

    setAreas: areas => set({ areas }),

    updateFranchisees: franchisses => {
        const updatedAreas = get().areas.map(area => ({ ...area }));
        updatedAreas.forEach(area => {
            const franchisee = franchisses.find(f => f._id === area.franchiseeId);
            if (franchisee && franchisee.areaGeoJson && franchisee.color) {
                const { areaGeoJson: updatedAreaGeoJson, markers: updatedMarkers } = getUpdatedFranchiseeAreaData(
                    area.markers,
                    franchisee.areaGeoJson
                );

                area.areaGeoJson = updatedAreaGeoJson;
                area.markers = updatedMarkers;
                area.color = franchisee.color;
            }
        });
        set({ areas: updatedAreas });
    },

    setSelectedFeatures: features => {
        set({
            selectedAreaIds: dedupeArray(features.franchiseeAreaIds),
            selectedMarkerIds: dedupeArray(features.markerIds),
        });
    },

    setFranchiseeColorPreview: async ({ franchiseeId, color }) => {
        const updatedAreas = get().areas.map(area => ({ ...area }));
        updatedAreas.forEach(area => {
            if (area.franchiseeId === franchiseeId) {
                area.color = color;
            }
        });
        set({ areas: updatedAreas });
    },

    setFranchiseeColorSave: async ({ franchiseeId, color }) => {
        const response = await Api.put<StandardApiResponseWithData<UpdateFranchiseeColourRequestBody>>(
            null,
            '/api/franchise/update-colour',
            {
                colour: color,
                franchiseeId,
            }
        );
        if (response) {
            console.log('Color updated server side');

            const updatedAreas = get().areas.map(area => ({ ...area }));
            updatedAreas.forEach(area => {
                if (area.franchiseeId === franchiseeId) {
                    area.color = color;
                }
            });
            set({ areas: updatedAreas });
        } else {
            // handle error setting colour here
        }
    },

    regenerateArea: async franchiseeId => {
        const response = await Api.post<FranchiseeAreaData>(null, `/api/franchise/reset-area/${franchiseeId}`);
        const updatedAreaData = response?.data;

        if (!updatedAreaData) {
            console.error('Failed to regenerate area');
            return;
        }

        const areas = get().areas;
        const updatedAreas: Array<FranchiseeAreaData> = [];
        areas.forEach(area => {
            if (area.franchiseeId === franchiseeId) {
                updatedAreas.push({ ...area, areaGeoJson: updatedAreaData.areaGeoJson });
            } else {
                updatedAreas.push({ ...area });
            }
        });
        set({ areas: updatedAreas });
    },

    deselectFranchisee: franchiseeId =>
        set(state => {
            const relatedMarkers = state.areas.find(area => area.franchiseeId === franchiseeId)?.markers;
            const relatedJobIds = new Array<string>();

            if (relatedMarkers) {
                Object.values(relatedMarkers).forEach(jobDict => {
                    Object.keys(jobDict).forEach(jobId => {
                        relatedJobIds.push(jobId);
                    });
                });
            }

            return {
                ...state,
                selectedAreaIds: state.selectedAreaIds?.filter(id => id !== franchiseeId),
                selectedMarkerIds: state.selectedMarkerIds.filter(id => !relatedJobIds.includes(id)),
                isPanelOpen: (state.selectedAreaIds?.filter(id => id !== franchiseeId) || []).length > 0,
            };
        }),

    deselectMarker: markerId => set(state => ({ ...state, selectedMarkerIds: state.selectedMarkerIds.filter(id => id !== markerId) })),

    reset: () => set(initialState),
});

export const selectAreas = (state: FranchiseMapStoreSlice) => state.areas;
export const selectSelectedAreaIds = (state: FranchiseMapStoreSlice) => state.selectedAreaIds;
export const selectSelectedCustomerJobLocationMarkerIds = (state: FranchiseMapStoreSlice) => state.selectedMarkerIds;

const dedupeArray = (array: Array<string>) => Array.from(new Set(array));
