import { type Campaign } from '@nexdynamic/squeegee-common';
import { useEffect, useState } from 'react';
import { Logger } from '../../../../../Logger';
import { Api } from '../../../../../Server/Api';

type DynamicAudienceMembersResponse =
    | { success: false; error: string }
    | { success: true; data: Array<string> }
    | { success: true; message: string; data: undefined };

export const useNewCustomerIds = (campaign?: Campaign | null) => {
    const [newCustomerIds, setNewCustomerIds] = useState<Array<string>>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const getNewCustomerIds = async () => {
            try {
                if (!campaign?.ongoing) return;

                setLoading(true);

                const response = await Api.get<DynamicAudienceMembersResponse>(null, `/api/send/get-new-audience-members/${campaign._id}`);
                if (!response?.data.success) return;

                if (!response.data.data) {
                    setNewCustomerIds([]);
                } else {
                    setNewCustomerIds(response.data.data);
                }
            } catch (error) {
                Logger.error('Failed to get dynamic audience members', error, 4);
            } finally {
                setLoading(false);
            }
        };

        getNewCustomerIds();
    }, [campaign]);

    return { newCustomerIds, loading };
};
