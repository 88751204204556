import { Button, ButtonGroup } from '@mui/material';
import type React from 'react';
import useTranslation from '../../hooks/useTranslation';
import { useBoundFranchiseMapStore } from '../stores/boundFranchiseMapStore';

export const EditTools: React.FC = () => {
    const { isEditing, cancelEdit, saveCandidateAreaGeoJson } = useBoundFranchiseMapStore();

    const { t } = useTranslation();

    if (!isEditing) return null;
    return (
        <ButtonGroup variant="contained">
            <Button onClick={cancelEdit}>{t('general.cancel')}</Button>
            <Button onClick={saveCandidateAreaGeoJson}>{t('general.save')}</Button>
        </ButtonGroup>
    );
};
