import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Radio,
} from '@mui/material';
import { useState } from 'react';
import useTranslation from '../../../../hooks/useTranslation';
import { useCreateCustomerStore } from '../stores/createCustomerStore';
import { createSelectAvailableFranchisees } from '../stores/selectors/createSelectAvailableFranchisees';
import { createSelectIntersectingFranchisees } from '../stores/selectors/createSelectIntersectingFranchisees';

export const MultipleFranchiseeWarningDialog = ({
    open,
    onClose,
    onSelect,
}: {
    open: boolean;
    onClose?: () => void;
    onSelect: (franchiseeId: string) => void;
}) => {
    const { t } = useTranslation();
    const availableFranchisees = useCreateCustomerStore(createSelectAvailableFranchisees);
    const intersectingFranchisees = useCreateCustomerStore(createSelectIntersectingFranchisees);

    const [selectedFranchiseeId, setSelectedFranchiseeId] = useState<string | null>(null);

    const handleSelect = () => {
        if (selectedFranchiseeId) {
            onSelect(selectedFranchiseeId);
        }
    };

    return (
        <Dialog open={open}>
            <DialogTitle>{t('franchise-map.multiple-areas-intersect-warning-title')}</DialogTitle>
            <DialogContent sx={{ py: 0 }}>
                <DialogContentText>{t('franchise-map.multiple-areas-intersect-warning-description')}</DialogContentText>
                <List>
                    {intersectingFranchisees.map(franchisee => {
                        const canCreateCustomerInFranchisee = availableFranchisees.find(
                            f => f.franchiseeId === franchisee.franchiseeId
                        )?.canCreateCustomerInFranchisee;
                        return (
                            <ListItem
                                key={franchisee.franchiseeId}
                                secondaryAction={
                                    <Radio
                                        checked={selectedFranchiseeId === franchisee.franchiseeId}
                                        disabled={!canCreateCustomerInFranchisee}
                                        disableRipple
                                        disableFocusRipple
                                    />
                                }
                                title={!canCreateCustomerInFranchisee ? t('franchise-map.create-customer-no-permissions') : ''}
                                disablePadding
                            >
                                <ListItemButton
                                    onClick={() => setSelectedFranchiseeId(franchisee.franchiseeId)}
                                    disabled={!canCreateCustomerInFranchisee}
                                >
                                    <ListItemText primary={franchisee.franchiseeName} />
                                </ListItemButton>
                            </ListItem>
                        );
                    })}
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose && onClose()}>Cancel</Button>
                <Button onClick={handleSelect}>Select</Button>
            </DialogActions>
        </Dialog>
    );
};
