import { Divider, List, Paper, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import useTranslation from '../../../hooks/useTranslation';
import { useBoundFranchiseMapStore } from '../../stores/boundFranchiseMapStore';
import { createSelectFranchiseeInspectPanelData } from '../../stores/franchiseMapSlice/selectors/createSelectFranchiseeInspectPanelData';
import { AreaEditPreviewDialog } from './components/AreaEditPreviewDialog';
import { FranchiseeItem } from './components/FranchiseeItem';
import { SelectedMarkerItem } from './components/SelectedMarkerItem';

export const FranchiseeInspectPanel = () => {
    const { t } = useTranslation();
    const { selectedFranchisees, selectedJobs } = useBoundFranchiseMapStore(createSelectFranchiseeInspectPanelData);
    const { cancelEdit, saveCandidateAreaGeoJson } = useBoundFranchiseMapStore();

    const [areaEditPreviewDialogOpen, setAreaEditPreviewDialogOpen] = useState(false);

    const handleAreaEditPreviewDialogSave = () => {
        saveCandidateAreaGeoJson();
        setAreaEditPreviewDialogOpen(false);
    };

    const handleAreaEditPreviewDialogClose = () => {
        cancelEdit();
        setAreaEditPreviewDialogOpen(false);
    };

    return (
        <Paper
            sx={{ p: 2, width: 300, maxHeight: 'calc(100vh - 20px)', overflowY: 'auto' }}
            hidden={selectedFranchisees.length === 0 && selectedJobs.length === 0}
        >
            <Stack spacing={3}>
                {selectedFranchisees.map(({ franchiseeId, franchiseeName, franchiseeOwnerEmail, color, areaGeoJson, markers }, index) => (
                    <Stack spacing={3} key={franchiseeId}>
                        <FranchiseeItem
                            franchiseeId={franchiseeId}
                            franchiseeName={franchiseeName}
                            franchiseeOwnerEmail={franchiseeOwnerEmail}
                            franchiseeTotalCustomers={Object.keys(markers).length}
                            areaGeoJson={areaGeoJson}
                            color={color}
                            openEditPreviewDialog={setAreaEditPreviewDialogOpen}
                        />

                        {selectedJobs.length > 0 && (
                            <List
                                disablePadding
                                subheader={
                                    <Typography variant="body2" fontWeight="bold" color="text.secondary">
                                        {t('franchise-map.selected-jobs')}
                                    </Typography>
                                }
                            >
                                {selectedJobs
                                    .filter(f => f.franchiseeId === franchiseeId)
                                    .map(selectedJob => (
                                        <SelectedMarkerItem
                                            selectedMarker={selectedJob}
                                            openEditPreviewDialog={setAreaEditPreviewDialogOpen}
                                            key={selectedJob.jobId}
                                        />
                                    ))}
                            </List>
                        )}

                        {index < selectedFranchisees.length - 1 && <Divider sx={{ mt: 2 }} />}
                    </Stack>
                ))}

                <AreaEditPreviewDialog
                    open={areaEditPreviewDialogOpen}
                    onClose={handleAreaEditPreviewDialogClose}
                    onSave={handleAreaEditPreviewDialogSave}
                />
            </Stack>
        </Paper>
    );
};
