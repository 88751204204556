import { createSelector } from 'reselect';
import type { SelectedJobsPanelData } from '../../../types/SelectedJobsPanelData';
import { selectSelectedCustomerJobLocationMarkerIds } from '../createFranchiseMapSlice';
import { createSelectJobIdToJobInfoDic } from './createSelectJobIdToJobInfoDic';

export const createSelectSelectedJobsData = createSelector(
    [selectSelectedCustomerJobLocationMarkerIds, createSelectJobIdToJobInfoDic],
    (selectedMarkerIds, jobIdToJobInfoDic) => {
        if (!selectedMarkerIds) return [];

        const selectedMarkerData = new Array<SelectedJobsPanelData>();

        selectedMarkerIds.forEach(id => {
            const jobInfo = jobIdToJobInfoDic[id];
            if (jobInfo) {
                selectedMarkerData.push(jobInfo);
            }
        });
        return selectedMarkerData;
    }
);
