import UnreadIcon from '@mui/icons-material/Markunread';
import MenuIcon from '@mui/icons-material/Menu';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import OutboxIcon from '@mui/icons-material/Outbox';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import SentIcon from '@mui/icons-material/Send';
import SmsIcon from '@mui/icons-material/Sms';
import {
    Badge,
    Box,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Toolbar,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ApplicationState } from '../../../ApplicationState';
import { SqueegeeCredits } from '../../components/SqueegeeCredits';
import Text from '../../components/Text';
import useTranslation from '../../hooks/useTranslation';
import { useInboxUnreadCount } from '../hooks/useInboxUnreadCount';
import { useOutbox } from '../hooks/useOutbox';
export const MAIL_MENU_HEIGHT = 56;

const MailMenu = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [showMenu, setShowMenu] = useState(false);
    const { count } = useInboxUnreadCount();
    const unreadCount = count;
    const outbox = useOutbox();

    const theme = useTheme();
    const isSmallerScreen = useMediaQuery(theme.breakpoints.down('md'));

    const location = useLocation();

    const handleClick = (path: string) => {
        if (isSmallerScreen) setShowMenu(false);
        navigate(path);
    };

    const hasScheduledMessages = ApplicationState.features.scheduledMessaging === true;
    const menu = (
        <List dense sx={{ width: '100%' }} component="nav">
            <ListItem>
                <ListItemButton selected={location.pathname.startsWith('/inbox/unread')} onClick={() => handleClick('/inbox/unread')}>
                    <ListItemIcon>
                        <Badge badgeContent={unreadCount} color="secondary">
                            <UnreadIcon />
                        </Badge>
                    </ListItemIcon>
                    <ListItemText primary={t('inbox.unread')} />
                </ListItemButton>
            </ListItem>

            <ListItem>
                <ListItemButton
                    selected={location.pathname === '/inbox' && location.pathname.lastIndexOf('/') !== 5}
                    onClick={() => handleClick('/inbox')}
                >
                    <ListItemIcon>
                        <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('inbox.received')} />
                </ListItemButton>
            </ListItem>

            <ListItem>
                <ListItemButton selected={location.pathname.startsWith('/inbox/sent')} onClick={() => handleClick('/inbox/sent')}>
                    <ListItemIcon>
                        <SentIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('inbox.sent')} />
                </ListItemButton>
            </ListItem>

            <ListItem>
                <ListItemButton selected={location.pathname.startsWith('/inbox/outbox')} onClick={() => handleClick('/inbox/outbox')}>
                    <ListItemIcon>
                        <Badge badgeContent={outbox.length} color="secondary">
                            <OutboxIcon />
                        </Badge>
                    </ListItemIcon>
                    <ListItemText primary={t('inbox.outbox')} />
                </ListItemButton>
            </ListItem>

            {(ApplicationState.stateFlags.devMode === true || hasScheduledMessages === true) && (
                <ListItem>
                    <ListItemButton
                        selected={location.pathname.startsWith('/inbox/scheduled')}
                        onClick={() => handleClick('/inbox/scheduled')}
                    >
                        <ListItemIcon>
                            <ScheduleSendIcon />
                        </ListItemIcon>
                        <ListItemText primary={t('inbox.scheduled')} />
                    </ListItemButton>
                </ListItem>
            )}
            {ApplicationState.stateFlags.devMode === true && (
                <ListItem>
                    <ListItemButton selected={location.pathname.startsWith('/inbox/draft')} onClick={() => handleClick('/inbox/draft')}>
                        <ListItemIcon>
                            <NewReleasesIcon />
                        </ListItemIcon>
                        <ListItemText primary={t('inbox.draft')} />
                    </ListItemButton>
                </ListItem>
            )}

            <Divider sx={{ margin: '4px' }} />
            <ListItem>
                <ListItemButton sx={{ pointerEvents: 'none' }}>
                    <ListItemIcon>
                        <SmsIcon />
                    </ListItemIcon>
                    <ListItemText primary={<SqueegeeCredits />} />
                </ListItemButton>
            </ListItem>
        </List>
    );

    if (isSmallerScreen) {
        return (
            <>
                <Toolbar sx={{ height: MAIL_MENU_HEIGHT }}>
                    <Box sx={{ flex: 1 }}></Box>
                    <IconButton onClick={() => setShowMenu(!showMenu)} size="large" edge="start" color="inherit">
                        <MenuIcon />
                    </IconButton>
                </Toolbar>
                <Drawer anchor="right" open={showMenu} onClose={() => setShowMenu(!showMenu)}>
                    <Toolbar sx={{ marginTop: 'var(--notch-inset-top)' }}>
                        <Box sx={{ textAlign: 'center', flex: 1 }}>
                            <Text variant="h6" text="inbox.inbox-title" />
                        </Box>
                    </Toolbar>
                    <Divider />
                    {menu}
                </Drawer>
            </>
        );
    }

    return (
        <Box sx={{ width: 200 }}>
            <Paper>{menu}</Paper>
        </Box>
    );
};

export default MailMenu;
