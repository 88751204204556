import type { AccountUserProfile, Directions, LngLat, TranslationKey, WayPoint } from '@nexdynamic/squeegee-common';
import { JobOccurrenceStatus } from '@nexdynamic/squeegee-common';
import type { Moment } from 'moment';
import moment from 'moment';
import { ApplicationState } from '../ApplicationState';
import { Prompt } from '../Dialogs/Prompt';
import { LoaderEvent } from '../Events/LoaderEvent';
import { Logger } from '../Logger';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import type { ScheduleByGroup } from '../Schedule/Components/ScheduleByGroup';
import type { ScheduleItem } from '../Schedule/Components/ScheduleItem';
import { DayOrderDataService } from '../Schedule/DayOrderDataService';
import { DirectionsApi } from '../Server/DirectionsApi';
import { UserService } from '../Users/UserService';
import { Utilities } from '../Utilities';
import { DayOrderData } from './Components/DayOrderData';
import { RouteScheduleItem } from './Components/RouteScheduleItem';
import type { OrderType } from './DayPilot';

export class RouteOptimisationService {
    public static getUserOptimizationStartTime(userEmail: string, date: string) {
        const currentUser = UserService.getUser(userEmail);
        const userProfile =
            currentUser?._id && ApplicationState.getSetting<AccountUserProfile, string>('global.account-user-profile', {}, currentUser._id);
        if (userProfile && userProfile.useStartTimeWhenOptimising && userProfile.startTime) {
            // if the user has a start time set, use that if its before the current time or if the date is not today
            const now = moment();
            const startTime = moment(userProfile.startTime, 'HH:mm:ss');

            if (now.isBefore(startTime) || !moment(date).isSame(now, 'day')) return moment(date).add(userProfile.startTime).format();
        }
    }

    public static async getRouteItemsForScheduleDataDay(
        day: string,
        user: string | undefined,
        scheduleDataDay: Array<ScheduleItem>,
        optimise = false,
        rankField?: OrderType | undefined,
        local = false,
        useBusinessAddress = false,
        startLocation?: LngLat,
        endLocation?: LngLat,
        startTime?: string
    ): Promise<{ routeItems: Array<RouteScheduleItem>; dayOrderData: DayOrderData }> {
        let dayOrderData = await DayOrderDataService.getDayOrderData(day, user);

        const businessAddress = ApplicationState.account && ApplicationState.account.businessAddress;
        const businessAddressLatLng =
            businessAddress && businessAddress.lngLat && businessAddress.lngLat.length === 2 ? businessAddress.lngLat : undefined;

        if (!dayOrderData) {
            dayOrderData = new DayOrderData(day, user);

            dayOrderData.occurrenceOrderData[DayOrderDataService.HOME_LEG_KEY] = {
                lngLat: businessAddressLatLng,
                includedInOptimisation: false,
                occurrenceDayOrder: 9999,
                occurrenceManualDayOrder: 9999,
            };
            optimise = rankField === 'optimal';
        }

        if (!businessAddress || !businessAddressLatLng) throw 'Please provide a valid business address.';

        if (rankField) dayOrderData.sortMethod = rankField;

        let routeItems = this.getRouteItems(scheduleDataDay, dayOrderData);

        if (routeItems.length) {
            const data: Directions | undefined = await this.refreshDriveTimesAndDistances(
                routeItems,
                optimise,
                undefined,
                dayOrderData.sortMethod,
                useBusinessAddress,
                startLocation,
                endLocation,
                startTime
            );

            routeItems = this.sortRouteItems(routeItems);
            if (data) {
                dayOrderData.totalDistanceMetres = data.totalDistanceMetres && data.totalDistanceMetres.value;
                dayOrderData.totalDurationSeconds = data.totalDurationSeconds && data.totalDurationSeconds.value;
            }
        }

        if (!local) DayOrderDataService.saveRouteItemsDayOrderData(routeItems, dayOrderData);

        return {
            routeItems,
            dayOrderData,
        };
    }

    private static sortRouteItems(routeItems: RouteScheduleItem[]) {
        const hasRankedItems = routeItems.find(ri => ri.rank !== undefined && ri.rank !== 9999);

        routeItems = hasRankedItems
            ? routeItems.sort((a, b) => {
                  if (!a.rank) a.rank = 0;
                  if (!b.rank) b.rank = 0;
                  return a.rank - b.rank;
              })
            : routeItems.sort((a, b) => {
                  if (!a.distance || !b.distance) return -1;
                  else return a.distance - b.distance;
              });

        return routeItems;
    }

    public static checkRouteIsOptimised(routeItems: Array<RouteScheduleItem>) {
        let isRouteOptimised = !routeItems.some(item => !item.isPartOfOptimisedRoute && item.isVerified);
        if (isRouteOptimised) {
            let prevRank: number | undefined = undefined;
            for (let index = 0; index < routeItems.length; index++) {
                const routeItem = routeItems[index];
                if (prevRank && routeItem.rank && routeItem.id !== DayOrderDataService.HOME_LEG_KEY && routeItem.rank - prevRank !== 1) {
                    isRouteOptimised = false;
                    break;
                }
                prevRank = routeItem.rank;
            }
        }

        if (!isRouteOptimised) {
            for (let index = 0; index < routeItems.length; index++) {
                const routeItem = routeItems[index];
                routeItem.clearDriveInformation();
            }
        }

        return isRouteOptimised;
    }

    public static async refreshDriveTimesAndDistances(
        routeScheduleItems: Array<RouteScheduleItem>,
        optimise?: boolean,
        startingRank?: number,
        rankField?: OrderType | undefined,
        useBusinessAddress = false,
        startLocation?: LngLat,
        endLocation?: LngLat,
        startTime?: string
    ) {
        const routeData = await this.getRouteData(
            routeScheduleItems,
            !optimise,
            rankField,
            useBusinessAddress,
            startLocation,
            endLocation,
            startTime
        );
        if (!routeData) return;
        if (routeData.errors?.length) {
            new LoaderEvent(false);
            const prompt = new Prompt('Route optimisation failed' as TranslationKey, routeData.errors.join(', ') as TranslationKey, {
                cancelLabel: '',
            });
            await prompt.show();
            return;
        }
        const failed = routeData.wayPoints.filter(x => x.failedContraints);
        if (failed.length) {
            new LoaderEvent(false);
            const prompt = new Prompt(
                'Route optimisation failed' as TranslationKey,
                'An optimised route compatible with one or more appointment times could not be generated' as TranslationKey,
                {
                    cancelLabel: '',
                }
            );
            await prompt.show();
        }

        this.applyDriveDataToScheduleItems(
            routeScheduleItems,
            routeData,
            optimise,
            startingRank,
            rankField,
            moment(startTime).isValid() ? moment(startTime) : undefined
        );

        return routeData;
    }

    /**
     *  gets a list of waypoints to send to api to return with fixed waypoint ordering and drive information
     *
     * @private
     * @returns {(Promise<Directions | undefined>)}
     * @memberof RouteOptimisationService
     */
    private static async getRouteData(
        routeScheduleItems: Array<RouteScheduleItem>,
        keepOrder = false,
        orderMethod?: OrderType,
        useBusinessAddress = false,
        startLocationOverride?: LngLat,
        endLocationOverride?: LngLat,
        startTimeOverride?: string
    ): Promise<Directions | undefined> {
        // const hasRankedItems = routeScheduleItems.find(ri => ri.rank !== undefined && ri.rank !== 9999);

        const wayPoints =
            orderMethod === 'planned'
                ? this.getWayPointsForRouteItemsSortedByPlanned(routeScheduleItems)
                : this.getWayPointsForRouteItemsSortedByRank(routeScheduleItems);

        // if we can't get current position then lets just make it their business address
        let currentPosition: { longitude: number; latitude: number } | undefined;
        const geoLocation = ApplicationState.position;
        if (useBusinessAddress || !geoLocation || !geoLocation.coords || !geoLocation.coords.latitude || !geoLocation.coords.longitude) {
            if (
                ApplicationState.account.businessAddress &&
                ApplicationState.account.businessAddress.isVerified &&
                ApplicationState.account.businessAddress.lngLat
            ) {
                currentPosition = {
                    longitude: ApplicationState.account.businessAddress.lngLat[0],
                    latitude: ApplicationState.account.businessAddress.lngLat[1],
                };
            }
        } else {
            currentPosition = {
                longitude: geoLocation.coords.longitude,
                latitude: geoLocation.coords.latitude,
            };
        }

        if (wayPoints && wayPoints.length) {
            let startPoint: LngLat = currentPosition ? [currentPosition.longitude, currentPosition.latitude] : wayPoints[0].lngLat;
            let endPoint: LngLat | undefined;
            if (startLocationOverride) startPoint = startLocationOverride;
            if (endLocationOverride) endPoint = endLocationOverride;

            const directions: Directions = { startPoint, endPoint, wayPoints, startTime: startTimeOverride };

            try {
                const newDirections =
                    keepOrder || wayPoints.length < 3
                        ? await DirectionsApi.getFixedDirections(directions)
                        : await DirectionsApi.getOptimisedDirections(directions);

                return newDirections;
            } catch (error) {
                new NotifyUserMessage('notifications.drive-times-update-error');
                Logger.info(error);
            }
        }
    }

    /**
     * uses api directions response to update scheduleitems and home leg info
     *
     * @private
     * @param {Directions} directions
     * @param {boolean} [updateDriveOptimisationRank=true]
     * @memberof RouteOptimisationService
     */
    private static applyDriveDataToScheduleItems(
        routeItems: Array<RouteScheduleItem>,
        directions: Directions,
        _optimisedDrive?: boolean,
        startingRank = 1,
        rankField?: OrderType | undefined,
        startTime?: Moment
    ) {
        if (!directions.wayPoints || !directions.wayPoints.length) return;

        let wayPointsIndex = 0;
        let previousScheduleItemJobDurationSeconds = startTime;
        while (wayPointsIndex < directions.wayPoints.length) {
            const wayPoint = directions.wayPoints[wayPointsIndex];
            const routeItem = routeItems.find(si => si.id === wayPoint.jobId || si.id === wayPoint.jobId);

            if (routeItem) {
                //TODO: Should we just update rank directly here and have a transform update routeOrderData on save?
                if (wayPoint.failedContraints) {
                    routeItem.icon = 'warning';
                    routeItem.subDescription = wayPoint.failedContraints.join(',');
                }
                routeItem.routeOrderData.lngLat = wayPoint.lngLat;
                routeItem.routeOrderData.occurrenceDayOrder = startingRank + wayPointsIndex;
                routeItem.routeOrderData.includedInOptimisation = true;
                routeItem.routeOrderData.occurrenceManualDayOrder = startingRank + wayPointsIndex;
                if (!routeItem.routeOrderData)
                    routeItem.routeOrderData = {
                        occurrenceDayOrder: startingRank + wayPointsIndex,
                        occurrenceManualDayOrder: startingRank + wayPointsIndex,
                        includedInOptimisation: false,
                    };
                routeItem.routeOrderData.driveDistanceMetresFromPrevLocation = wayPoint.distanceFromPrevDestinationMetres
                    ? wayPoint.distanceFromPrevDestinationMetres.value || 0
                    : 0;
                routeItem.routeOrderData.driveDurationSecondsFromPrevLocation = wayPoint.durationFromPrevDestinationSeconds
                    ? wayPoint.durationFromPrevDestinationSeconds.value || 0
                    : 0;

                previousScheduleItemJobDurationSeconds = routeItem.refreshRouteItem(previousScheduleItemJobDurationSeconds, rankField);
            }

            wayPointsIndex++;
        }
    }

    public static refreshRouteItemTimes(routeItems: Array<RouteScheduleItem>, rankField?: OrderType | undefined, startTime?: Moment) {
        let previousScheduleItemJobDurationSeconds = startTime;

        for (const routeItem of routeItems) {
            previousScheduleItemJobDurationSeconds = routeItem.refreshRouteItem(previousScheduleItemJobDurationSeconds, rankField);
        }
    }

    private static getWayPointsForRouteItemsSortedByRank(routeScheduleItems: Array<RouteScheduleItem>): Array<WayPoint> | undefined {
        const routeItems = routeScheduleItems
            .filter(x => x.id !== 'homeLeg')
            .sort((a, b) => {
                if (a && a.rank && b && b.rank) {
                    return a.rank - b.rank;
                } else if (a && a.rank) {
                    return 1;
                } else return -1;
            });

        const home = routeScheduleItems.find(x => x.id === 'homeLeg');

        if (home) routeItems.push(home);

        return RouteOptimisationService.getWayPointsForRouteItems(routeItems);
    }

    private static getWayPointsForRouteItemsSortedByPlanned(routeScheduleItems: Array<RouteScheduleItem>): Array<WayPoint> | undefined {
        const routeItems = routeScheduleItems
            .filter(x => x.id !== 'homeLeg')
            .sort((a, b) => {
                if (a && a.scheduleItem && b && b.scheduleItem) {
                    return a.scheduleItem.plannedOrder - b.scheduleItem.plannedOrder;
                } else if (a && a.scheduleItem) {
                    return 1;
                } else return -1;
            });

        const home = routeScheduleItems.find(x => x.id === 'homeLeg');

        if (home) routeItems.push(home);
        // check length of waypoint

        return RouteOptimisationService.getWayPointsForRouteItems(routeItems);
    }

    private static getWayPointsForRouteItems(routeItems: RouteScheduleItem[]) {
        const wayPoints: Array<WayPoint> = [];
        if (!routeItems.length) return wayPoints;

        const homeWayPointLngLat = routeItems[routeItems.length - 1].lngLat;

        const hasAdvancedOrAbove = ApplicationState.hasAdvancedOrAbove;

        for (let i = 0; i < routeItems.length; i++) {
            const routeItem = routeItems[i];
            if (routeItem.lngLat && routeItem.lngLat.length === 2) {
                if (i < routeItems.length - 1) {
                    if (Utilities.arraysAreEqual(routeItem.lngLat, homeWayPointLngLat)) {
                        routeItem.lngLat[0] = routeItem.lngLat[0] + 0.00001; // Hack to make any waypoints that are the same as home, slightly different.
                    }
                }

                wayPoints.push({
                    jobId: routeItem.id,
                    lngLat: routeItem.lngLat,
                    gpsDistanceMetres: routeItem.distance, // whaaa?
                    duration: (hasAdvancedOrAbove && routeItem.scheduleItem && routeItem.scheduleItem.durationInSeconds) || undefined,
                    time:
                        hasAdvancedOrAbove && routeItem.scheduleItem && routeItem.scheduleItem.occurrence.time
                            ? routeItem.scheduleItem.date + 'T' + routeItem.scheduleItem.occurrence.time
                            : undefined,
                });
            }
        }
        return wayPoints;
    }

    private static getRouteItems(scheduleItems: Array<ScheduleItem>, dayOrderData: DayOrderData) {
        const notDoneJobs = scheduleItems.filter(s => s.status === JobOccurrenceStatus.NotDone);

        let routeScheduleItems: Array<RouteScheduleItem> = [];

        for (let notDoneKeysIndex = 0; notDoneKeysIndex < notDoneJobs.length; notDoneKeysIndex++) {
            const scheduleItemInScheduleDataDay = notDoneJobs[notDoneKeysIndex];
            // if a match isn't found add the scheduleItem from ScheduleDataDay to the appropriate list
            const orderMetaData = dayOrderData.occurrenceOrderData[scheduleItemInScheduleDataDay.job._id];
            const routeItem = RouteScheduleItem.fromScheduleItem(
                orderMetaData || { occurrenceDayOrder: undefined, includedInOptimisation: false },
                scheduleItemInScheduleDataDay,
                'route-item' + notDoneKeysIndex.toString(),
                orderMetaData && orderMetaData.occurrenceDayOrder,
                dayOrderData.sortMethod
            );

            routeScheduleItems.push(routeItem);
        }

        routeScheduleItems = this.sortRouteItems(routeScheduleItems);

        let prevItemLength = undefined;
        for (let index = 0; index < routeScheduleItems.length; index++) {
            prevItemLength = routeScheduleItems[index].refreshRouteItem(prevItemLength, dayOrderData.sortMethod);
        }

        return routeScheduleItems;
    }

    public static getAllRouteItems(scheduleDataDay: ScheduleByGroup) {
        const notDones = scheduleDataDay[JobOccurrenceStatus.NotDone];
        const skipped = scheduleDataDay[JobOccurrenceStatus.Skipped];
        const dones = scheduleDataDay[JobOccurrenceStatus.Done];
        let routeScheduleItems: Array<RouteScheduleItem> = [];

        if (notDones !== undefined) {
            RouteOptimisationService.addRouteItemsFromScheduleItems(notDones, routeScheduleItems);
        }
        if (skipped !== undefined) {
            RouteOptimisationService.addRouteItemsFromScheduleItems(skipped, routeScheduleItems);
        }
        if (dones !== undefined) {
            RouteOptimisationService.addRouteItemsFromScheduleItems(dones, routeScheduleItems);
        }
        routeScheduleItems = this.sortRouteItems(routeScheduleItems);
        let prevItemLength = undefined;
        for (let index = 0; index < routeScheduleItems.length; index++) {
            prevItemLength = routeScheduleItems[index].refreshRouteItem(prevItemLength);
        }

        return routeScheduleItems;
    }

    private static addRouteItemsFromScheduleItems(
        scheduleItemsByKey: { [id: string]: ScheduleItem },
        routeScheduleItems: RouteScheduleItem[]
    ) {
        const scheduleItemKeys = Object.keys(scheduleItemsByKey);
        for (let notDoneKeysIndex = 0; notDoneKeysIndex < scheduleItemKeys.length; notDoneKeysIndex++) {
            const scheduleItemInScheduleDataDay = scheduleItemsByKey[scheduleItemKeys[notDoneKeysIndex]];

            const routeItem = RouteScheduleItem.fromScheduleItem(
                { occurrenceDayOrder: 0, includedInOptimisation: false },
                scheduleItemInScheduleDataDay,
                'route-item' + notDoneKeysIndex.toString(),
                undefined,
                'planned'
            );
            routeScheduleItems.push(routeItem);
        }
    }
}
