import { createSelector } from 'reselect';
import { createSelectSelectedAreasData } from './createSelectSelectedAreasData';
import { createSelectSelectedJobsData } from './createSelectSelectedJobsData';

export const createSelectFranchiseeInspectPanelData = createSelector(
    [createSelectSelectedAreasData, createSelectSelectedJobsData],
    (selectedFranchisees, selectedJobs) => {
        const data = {
            selectedFranchisees,
            selectedJobs,
        };

        return data;
    }
);
