import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import useTranslation from '../../../../hooks/useTranslation';

type Props = {
    open: boolean;
    onOk: () => void;
};
export const NoUserAccountWithPermissionsDialog = ({ onOk, open }: Props) => {
    const { t } = useTranslation();

    return (
        <Dialog open={open}>
            <DialogContent sx={{ pb: 0 }}>
                <DialogContentText>{t('franchise-map.invalid-permissions-warning')}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onOk()}>{t('general.ok')}</Button>
            </DialogActions>
        </Dialog>
    );
};
