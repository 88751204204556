import type { ButtonProps, DialogActionsProps, DialogContentProps, DialogProps, DialogTitleProps } from '@mui/material';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useIsSmallScreen } from '@nexdynamic/nex-ui-react';
import type { TranslationKey } from '@nexdynamic/squeegee-common';
import React, { useMemo } from 'react';
import { createRoot } from 'react-dom/client';
import { Logger } from '../Logger';
import { SqueegeeThemeProvider } from '../ReactUI/SqueegeeThemeProvider';
import useTranslation from '../ReactUI/hooks/useTranslation';
import type { DialogSettingsViewport } from './DialogSettings';

type DialogProviderProps = {
    children: React.ReactNode;
};

type DialogContextType = {
    dialogStack: React.ReactNode[];
    pushDialog: (dialog: React.ReactNode) => void;
    popDialog: () => void;
};

export const useDialog = () => {
    const context = React.useContext(DialogContext);
    if (!context) {
        throw new Error('useDialog must be used within a DialogProvider');
    }
    return context;
};

const DialogContext = React.createContext<DialogContextType | undefined>(undefined);

export const DialogProvider: React.FC<DialogProviderProps> = ({ children }) => {
    const [dialogStack, setDialogStack] = React.useState<React.ReactNode[]>([]);

    const pushDialog = (dialog: React.ReactNode) => {
        setDialogStack(prev => [...prev, dialog]);
    };

    const popDialog = () => {
        setDialogStack(prev => prev.slice(0, -1));
    };

    return (
        <DialogContext.Provider value={{ dialogStack, pushDialog, popDialog }}>
            {children}
            {dialogStack}
        </DialogContext.Provider>
    );
};

// for legacy code

export type Prompt = {
    show: () => Promise<boolean>;
    load: (promise?: Promise<any>) => Promise<boolean>;
    cancelled: boolean;
    close: () => void;
    updateHtmlContent: (newTextLocalisationKey: TranslationKey) => void;
    _settings: DialogSettingsViewport & { okCallback?: () => void };
};

type SlotProps = {
    dialog: Partial<DialogProps>;
    dialogTitle: DialogTitleProps;
    dialogContent: DialogContentProps;
    dialogActions: DialogActionsProps;
    okButton: ButtonProps;
    altButton: ButtonProps;
    cancelButton: ButtonProps;
};

export const prompt = (
    titleLocalisationKey: TranslationKey | undefined,
    textLocalisationKey: TranslationKey,
    settings?: DialogSettingsViewport & { okCallback?: () => void },
    slotProps?: Partial<SlotProps>
): Prompt => {
    const _settings = settings || {};
    const container = document.getElementById('legacy-mui-dialog-container');
    if (!container) {
        throw new Error('legacy-mui-dialog-container not found');
    }

    const root = createRoot(container);

    let showLoader = false;
    let cancelled = false;
    const show = async () => {
        const result = new Promise<boolean>(resolve => {
            root.render(
                <SqueegeeThemeProvider>
                    <PromptComponent
                        titleLocalisationKey={titleLocalisationKey}
                        textLocalisationKey={textLocalisationKey}
                        settings={{
                            ...settings,

                            okCallback: () => {
                                resolve(true);
                                root.unmount();
                            },
                        }}
                        slotProps={slotProps}
                        onClose={(backdrop?: true) => {
                            if (backdrop) cancelled = true;
                            resolve(false);

                            // WTF: wait for the dialog to close before unmounting
                            setTimeout(() => {
                                root.unmount();
                            }, 100);
                        }}
                        loading={showLoader}
                    />
                </SqueegeeThemeProvider>
            );
        });
        return result;
    };

    const load = async (promise?: Promise<any>) => {
        if (promise) {
            showLoader = true;
            promise
                .then(() => {
                    showLoader = false;
                    show();
                })
                .catch(error => {
                    Logger.error(`Error during load in Prompt`, { prompt: this, error });
                    root.unmount();
                });
        }
        return show();
    };

    const updateHtmlContent = (newTextLocalisationKey: TranslationKey) => {
        textLocalisationKey = newTextLocalisationKey;
    };

    return {
        show,
        load,
        close: () => root.unmount(),
        updateHtmlContent,
        _settings,
        get cancelled() {
            return cancelled;
        },
    };
};

const PromptComponent = ({
    titleLocalisationKey,
    textLocalisationKey,
    settings,
    slotProps,
    onClose,
    loading,
}: {
    titleLocalisationKey: TranslationKey | undefined;
    textLocalisationKey: TranslationKey;
    settings?: DialogSettingsViewport & { okCallback?: () => void };
    slotProps?: Partial<SlotProps>;
    onClose: (backdrop?: true) => void;
    loading?: boolean;
}) => {
    const { t } = useTranslation();
    const isSmallScreen = useIsSmallScreen();
    const { cancelLabel = 'general.cancel', altLabel, okLabel = 'general.ok' } = settings || {};

    const [open, setOpen] = React.useState<boolean>(slotProps?.dialog?.open || true);

    const cancel = () => {
        setOpen(false);
        onClose(true);
    };

    const alt = () => {
        setOpen(false);
        onClose();
    };

    const ok = () => {
        setOpen(false);
        if (settings?.okCallback) settings.okCallback();
        else onClose();
    };

    // WTF: https://m3.material.io/components/dialogs/guidelines#07aca156-a2ce-43aa-af73-fc5cc3a1ef0c
    // The best fix would be to not pass such long labels, but good luck enforcing that
    const isButtonLabelLengthUnsafe = useMemo(() => {
        // a mostly arbitrary threshold based on current font size and prompt width
        const upperThreshold = isSmallScreen ? 30 : 80;

        const labels = [cancelLabel, altLabel, okLabel];
        const labelLengthSum = labels.reduce((acc, label) => acc + (label?.length ?? 0), 0);

        return labelLengthSum > upperThreshold;
    }, [cancelLabel, altLabel, okLabel]);

    if (loading) {
        return (
            <Dialog open={true} maxWidth="xs">
                {titleLocalisationKey && <DialogTitle>{t(titleLocalisationKey)}</DialogTitle>}
                <DialogContent style={{ textAlign: 'center' }}>
                    <CircularProgress size={48} />
                </DialogContent>
            </Dialog>
        );
    }

    return (
        <Dialog
            onClose={cancel}
            open={open}
            maxWidth="xs"
            fullScreen={settings?.coverViewport || settings?.coverViewportNoAnimation}
            {...slotProps?.dialog}
        >
            {titleLocalisationKey && (
                <DialogTitle {...slotProps?.dialogTitle}>{t(titleLocalisationKey, settings?.localisationParams)}</DialogTitle>
            )}
            <DialogContent {...slotProps?.dialogContent}>
                <Typography>{t(textLocalisationKey, settings?.localisationParams)}</Typography>
            </DialogContent>
            <DialogActions
                {...slotProps?.dialogActions}
                sx={{
                    flexDirection: isButtonLabelLengthUnsafe ? 'column-reverse' : 'row',
                    alignItems: isButtonLabelLengthUnsafe ? 'flex-end' : 'center',
                    gap: isButtonLabelLengthUnsafe ? 0.5 : 'initial',
                }}
            >
                {cancelLabel && (
                    <Button onClick={cancel} {...slotProps?.cancelButton}>
                        {t(cancelLabel)}
                    </Button>
                )}
                {altLabel && (
                    <Button onClick={alt} {...slotProps?.altButton}>
                        {t(altLabel)}
                    </Button>
                )}
                {okLabel && (
                    <Button onClick={ok} {...slotProps?.okButton}>
                        {t(okLabel)}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};
