import type { FranchiseeAreaData, FranchiseeAreaDataSummary, Location } from '@nexdynamic/squeegee-common';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { Utilities } from '../../../../../Utilities';
import { createNewCustomerStub } from '../helpers/createNewCustomerStub';
import type { AddCustomerStep } from '../types/AddCustomerStep';
import type { NewCustomerStubIdAndFranchiseeDataEmail } from '../types/NewCustomerStubIdAndFranchiseeDataEmail';

type CreateCustomerStoreState = {
    isPanelOpen: boolean;
    step: AddCustomerStep;
    selectedLocation: Location | null;
    selectedFranchiseeId: string | null;
    customerName: string | null;
    franchiseeAreas: FranchiseeAreaData[]; // if passed in component will only use these franchisees rather than all available
    newCustomerStubId: string | null;
    selectedFranchiseeDataEmail: string | null;
    errorCreatingCustomer: boolean | null;
    isFranchiseOwner: boolean | null;
    franchiseeIdsUserHasCreatorOrAdminRolesIn: string[] | null;
};

type CreateCustomerStoreActions = {
    setSelectedLocation: (location: Location) => void;
    setSelectedFranchiseeId: (franchiseeId: string) => void;
    setCustomerName: (customerName: string) => void;
    createCustomer: () => void;
    // setSubsetOfFranchiseeIds: (franchiseeIds: string[] | undefined) => void;
    setNewCustomerStubIdAndFranchiseeDataEmail: (options: NewCustomerStubIdAndFranchiseeDataEmail) => void;
    setFranchiseeAreasDataSummary: (FranchiseeAreasSummaryData: FranchiseeAreaDataSummary) => void;
    resetState: () => void;
    setPanelIsOpen: (isOpen: boolean) => void;
};

type CreateCustomerStore = CreateCustomerStoreState & CreateCustomerStoreActions;
const getInitialState = (): CreateCustomerStoreState => {
    return {
        isPanelOpen: false,
        step: 'select-address',
        selectedLocation: null,
        selectedFranchiseeId: null,
        customerName: null,
        newCustomerStubId: null,
        selectedFranchiseeDataEmail: null,
        errorCreatingCustomer: null,
        franchiseeAreas: [],
        isFranchiseOwner: null,
        franchiseeIdsUserHasCreatorOrAdminRolesIn: null,
    };
};
export const useCreateCustomerStore = create(
    devtools(
        immer<CreateCustomerStore>((set, get) => ({
            // state
            ...getInitialState(),
            // actions,
            setPanelIsOpen: (isOpen: boolean) => {
                set({ isPanelOpen: isOpen });
            },
            setSelectedLocation: location => {
                set({ selectedLocation: location });
            },
            setFranchiseeAreasDataSummary: dataSummary => {
                const { franchiseeAreas, isFranchiseOwner, franchiseeIdsUserHasCreatorOrAdminRolesIn } = dataSummary;
                console.info('Setting franchisee areas', franchiseeAreas);
                set({ franchiseeAreas, isFranchiseOwner, franchiseeIdsUserHasCreatorOrAdminRolesIn });
            },
            // setSubsetOfFranchiseeIds: (franchiseeIds?: Array<string>) => {
            //     set({ subsetOfFranchiseeIds: franchiseeIds });
            // },
            resetState: () => {
                set(getInitialState());
            },
            setSelectedFranchiseeId: franchiseeId => {
                set({ selectedFranchiseeId: franchiseeId });
            },
            setCustomerName: customerName => {
                set({ customerName });
            },
            createCustomer: () => {
                const franchiseeId = get().selectedFranchiseeId;
                console.log('Creating customer...', get().customerName);
                const customerName = get().customerName;
                const location = get().selectedLocation;
                if (!franchiseeId || !customerName || !location) {
                    throw new Error('Incomplete data to create customer');
                }
                createNewCustomerStub({
                    franchiseeId,
                    customerName,
                    location,
                    setNewCustomerStubId: get().setNewCustomerStubIdAndFranchiseeDataEmail,
                });
            },
            setNewCustomerStubIdAndFranchiseeDataEmail: (options: NewCustomerStubIdAndFranchiseeDataEmail | null) => {
                if (!options) {
                    set({ newCustomerStubId: null, selectedFranchiseeDataEmail: null, errorCreatingCustomer: true });
                    return;
                }
                const { customerId, franchiseeDataEmail } = options;
                set({ newCustomerStubId: customerId, selectedFranchiseeDataEmail: franchiseeDataEmail, errorCreatingCustomer: false });

                Utilities.goToRootUrl({
                    applicationRouteWithLeadingSlash: `/customers/${customerId}`,
                    queryStringParams: { dataEmail: franchiseeDataEmail },
                });
            },
        }))
    )
);

// export const selectAvailableFranchisees = (state: CreateCustomerStoreState) => state.availableFranchisees;

export const selectFranchiseeAreas = (state: CreateCustomerStoreState) => state.franchiseeAreas;
export const selectSelectedLocation = (state: CreateCustomerStoreState) => state.selectedLocation;
export const selectIsFranchiseOwner = (state: CreateCustomerStoreState) => state.isFranchiseOwner;
export const selectFranchiseeIdsOwnerHasAccountIn = (state: CreateCustomerStoreState) => state.franchiseeIdsUserHasCreatorOrAdminRolesIn;
