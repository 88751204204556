import type { Location, StandardApiResponseWithData } from '@nexdynamic/squeegee-common';
import { Api } from '../../../../../Server/Api';
import type { NewCustomerStubIdAndFranchiseeDataEmail } from '../types/NewCustomerStubIdAndFranchiseeDataEmail';

export const createNewCustomerStub = async ({
    franchiseeId,
    customerName,
    location,
    setNewCustomerStubId,
}: {
    customerName: string;
    franchiseeId: string;
    location: Location;
    setNewCustomerStubId: (options: NewCustomerStubIdAndFranchiseeDataEmail | null) => void;
}) => {
    const { data: response } =
        (await Api.post<StandardApiResponseWithData<NewCustomerStubIdAndFranchiseeDataEmail>>(
            null,
            `/api/franchise/franchise-customers/${franchiseeId}/customer-stub`,
            {
                customerName,
                location,
                franchiseeId,
            }
        )) || {};

    if (!response?.success || !response.data) {
        throw new Error('Failed to create new customer stub');
        // [TODO] - pass an on error so component can display it
    }

    const { customerId, franchiseeDataEmail } = response.data;

    if (!customerId || !franchiseeDataEmail) {
        throw new Error('Missing customer id or franchisee data email');
    }

    setNewCustomerStubId({ customerId, franchiseeDataEmail });
};
