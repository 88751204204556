import type { LngLat } from '@nexdynamic/squeegee-portal-common';
import { createSelector } from 'reselect';
import { selectAreas } from '../createFranchiseMapSlice';
export const createSelectFranchiseeToMarkersOutsideAreaLookup = createSelector([selectAreas], areaLayers => {
    const franchiseeIdToJobMarkerPointsOutsideOfAreaLookup: { [franchiseeId: string]: Array<LngLat> } = {};
    areaLayers.forEach(area => {
        Object.entries(area.markers).forEach(customerKeyValue => {
            const marker = customerKeyValue[1];
            const { franchiseeId, lngLat, isInFranchiseeArea } = marker;
            if (!isInFranchiseeArea) {
                if (!franchiseeIdToJobMarkerPointsOutsideOfAreaLookup[franchiseeId])
                    franchiseeIdToJobMarkerPointsOutsideOfAreaLookup[franchiseeId] = [];
                franchiseeIdToJobMarkerPointsOutsideOfAreaLookup[franchiseeId].push(lngLat);
            }
        });
    });
    return franchiseeIdToJobMarkerPointsOutsideOfAreaLookup;
});
