import type { Customer } from '@nexdynamic/squeegee-common';
import { CustomerDialog } from '../../../Customers/Components/CustomerDialog';
import { Data } from '../../../Data/Data';
import { Utilities } from '../../../Utilities';

type Props = {
    customerId: string;
    franchiseeOwnerEmail?: string;
};

export const openCustomer = ({ customerId, franchiseeOwnerEmail }: Props) => {
    const localCustomer = Data.get<Customer>(customerId);

    // If there's a local customer, just open the customer dialog in the current view
    if (localCustomer) {
        new CustomerDialog(localCustomer).show();
        return;
    }

    if (!franchiseeOwnerEmail) throw new Error('franchiseeOwnerEmail is required to open a customer if the customer is not locally stored');

    // Otherwise, switch to the franchisee with the customerId query param
    Utilities.goToRootUrl({
        applicationRouteWithLeadingSlash: '/franchise-map',
        queryStringParams: { dataEmail: franchiseeOwnerEmail, customerId },
    });
};
