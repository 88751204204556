import CloseIcon from '@mui/icons-material/CloseRounded';
import ExternalIcon from '@mui/icons-material/OpenInNew';
import { Button, Card, CardActions, CardContent, CardHeader, FormControl, FormLabel, IconButton, Stack, Typography } from '@mui/material';
import type { CustomControlProps } from '@nexdynamic/nex-ui-react';
import type { Location } from '@nexdynamic/squeegee-common';
import type { Map } from 'maplibre-gl';
import type React from 'react';
import { useState } from 'react';
import { AddressLookupControl } from '../../../../Components/Map/controls/AddressLookup/AddressLookupControl';
import useTranslation from '../../../hooks/useTranslation';
import { CreateOrSelectCustomer } from './components/CreateOrSelectCustomer';
import { ExternalCustomerWarning } from './components/ExternalCustomerWarning';
import { SelectFranchisee } from './components/SelectFranchisee';
import { useCreateCustomerStore } from './stores/createCustomerStore';

// TO USE THIS CONTROL:
// import setFranchiseeAreas from the createCustomerStore in the parent component
// there is a helper function called getFranchiseAreas
// this will return the franchisee areas for the current user
// and can be used to power layers on the parent map
// but this control also needs this data to work
export const CreateCustomerControl: React.FC<CustomControlProps> = ({ map }) => {
    const { t } = useTranslation();
    const { isPanelOpen, setSelectedLocation, setPanelIsOpen, customerName, selectedFranchiseeId, createCustomer } =
        useCreateCustomerStore();

    const [externalWarningOpen, setExternalWarningOpen] = useState(false);

    const handleCreateCustomerControlClick = () => {
        setPanelIsOpen(true);
    };

    const handleLocationSelect = (_map: Map, location: Location) => {
        setSelectedLocation(location);
    };

    const handleClose = () => {
        setPanelIsOpen(false);
    };

    const handleFinaliseCustomer = () => {
        const show = localStorage.getItem('showExternalCustomerWarning');
        if (!show) createCustomer();
    };

    return (
        <>
            {!isPanelOpen ? (
                <Button variant="contained" color="primary" onClick={handleCreateCustomerControlClick}>
                    {t('franchise-map.create-customer')}
                </Button>
            ) : (
                <Card sx={{ minWidth: 300 }}>
                    <CardHeader
                        title={
                            <Typography variant="h6" fontWeight="normal">
                                {t('franchise-map.create-customer')}
                            </Typography>
                        }
                        action={
                            <IconButton size="small" onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        }
                        disableTypography
                        sx={{ pb: 0 }}
                    />
                    <CardContent>
                        <Stack spacing={2} width="100%">
                            <FormControl>
                                <FormLabel sx={{ mb: 1 }}>{t('franchise-map.create-customer-location')}</FormLabel>
                                <AddressLookupControl
                                    map={map}
                                    onSelect={handleLocationSelect}
                                    slotProps={{ paper: { sx: { minWidth: 0 }, elevation: 0 } }}
                                />
                            </FormControl>
                            <SelectFranchisee />
                            <CreateOrSelectCustomer />
                        </Stack>
                    </CardContent>
                    <CardActions sx={{ pt: 0 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            endIcon={<ExternalIcon />}
                            onClick={handleFinaliseCustomer}
                            disabled={!customerName || !selectedFranchiseeId || !location}
                            fullWidth
                        >
                            {t('franchise-map.create-customer')}
                        </Button>
                    </CardActions>
                </Card>
            )}
            <ExternalCustomerWarning open={externalWarningOpen} onClose={() => setExternalWarningOpen(false)} onContinue={createCustomer} />
        </>
    );
};
