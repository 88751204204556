import { Chip, ListItemText } from '@mui/material';
import { ElevatedListItemButton } from '@nexdynamic/nex-ui-react';
import type { Campaign, TranslationKey } from '@nexdynamic/squeegee-common';
import { CampaignStatus } from '@nexdynamic/squeegee-common';
import { useNavigate } from 'react-router';
import { t } from '../../../../../t';
import Text from '../../../../components/Text';
import { friendlyDate } from '../../../Format';

type CampaignListItemProps = {
    campaign: Campaign;
};
type CampaignMetadata = {
    date: number;
    description: TranslationKey;
    chipText: TranslationKey;
    color: 'success' | 'primary' | 'default' | 'secondary' | 'error' | 'info' | 'warning' | undefined;
};

const campaignMeta = (campaign: Campaign): CampaignMetadata | undefined => {
    switch (campaign.status) {
        case CampaignStatus.Published:
            return {
                date: campaign.publishedDate || new Date().getTime(),
                description: campaign.ongoing ? 'send.campaign-last-published' : 'general.published-at',
                chipText: (t('general.published') + (campaign.ongoing ? ` (${t('general.ongoing')})` : '')) as TranslationKey,
                color: 'success',
            };
        case CampaignStatus.Draft:
            return {
                date: new Date(campaign.createdDate).getTime(),
                description: 'general.editedAt',
                chipText: (t('general.draft') + (campaign.ongoing ? ` (${t('general.ongoing')})` : '')) as TranslationKey,
                color: 'primary',
            };
        default:
            return;
    }
};

const CampaignListItem = ({ campaign }: CampaignListItemProps) => {
    const navigate = useNavigate();

    if (!campaign) return null;

    const meta = campaignMeta(campaign);

    if (!meta) return null;

    const { color, chipText, description, date } = meta;

    const view = () => {
        navigate(`${campaign._id}`);
    };

    const formattedDate = date ? friendlyDate(date) : '';

    return (
        <ElevatedListItemButton sx={{ minHeight: 55 }} dense role="listitem" onClick={view}>
            <ListItemText
                primary={campaign.name}
                secondary={<Text noWrap component="span" variant="body2" text={description} params={{ date: formattedDate }} />}
            />
            <Chip variant="outlined" color={color} label={<Text variant="body2" text={chipText} />} />
        </ElevatedListItemButton>
    );
};

export default CampaignListItem;
