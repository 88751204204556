import type { TranslationKey } from '@nexdynamic/squeegee-common';
import { AlphanumericCharColourDictionary, formatTimestampDurationAsTime, JobOccurrenceStatus } from '@nexdynamic/squeegee-common';
import { bindable, bindingMode } from 'aurelia-framework';
import { ApplicationState } from '../../ApplicationState';
import { JobSummaryDialog } from '../../Customers/Components/JobSummaryDialog';
import { DialogAnimation } from '../../Dialogs/DialogAnimation';
import { ActionBarEvent } from '../../Events/ActionBarEvent';
import { LoaderEvent } from '../../Events/LoaderEvent';
import { Logger } from '../../Logger';
import { NotifyUserMessage } from '../../Notifications/NotifyUserMessage';
import { Utilities } from '../../Utilities';
import { ScheduleService } from '../ScheduleService';
import { ScheduleDetailsDialog } from './ScheduleDetailsDialog';
import type { ScheduleItem } from './ScheduleItem';
import './ScheduleItemList.scss';

//WTF maintains the current loaded count of every place the ScheduleItemListCustomElement is used

type ViewId = string;
const loadMoreState: Record<ViewId, number> = {};

export class ScheduleItemListCustomElement {
    @bindable({ defaultBindingMode: bindingMode.oneTime }) viewId: ViewId;
    @bindable({ defaultBindingMode: bindingMode.oneTime }) status: JobOccurrenceStatus;
    @bindable({ defaultBindingMode: bindingMode.oneTime }) chipColour: string;
    @bindable({ defaultBindingMode: bindingMode.oneTime }) chipTextColour: string;
    @bindable({ defaultBindingMode: bindingMode.oneTime }) groupText: string;
    @bindable({ defaultBindingMode: bindingMode.oneTime }) sortable = false;
    @bindable({ defaultBindingMode: bindingMode.oneTime }) disableMultiSelect = false;
    @bindable({ defaultBindingMode: bindingMode.oneTime }) suppressTime = false;
    @bindable({ defaultBindingMode: bindingMode.oneTime }) disableActionBarOnSelect: boolean;
    @bindable() allScheduleItems: Array<ScheduleItem>;
    @bindable() scheduleItems: Array<ScheduleItem>;
    @bindable() selectedItemsData: { selectedItems: Array<ScheduleItem> } = { selectedItems: [] };
    @bindable() searchText: string;
    @bindable() showGroupPrice = true;
    @bindable() displayIndexInAvatar: boolean;
    public viewOptions = ApplicationState.viewOptions;

    @bindable() showDistanceFromMe: boolean;
    @bindable() onDragSortComplete?: (
        toIndex: number,
        items: Array<ScheduleItem>,
        itemsDragged: Array<ScheduleItem>,
        target?: string
    ) => void;
    @bindable() onResetDragSort?: () => void;

    protected ready: boolean;
    protected showDistanceMarkersBetweenJobs = !ApplicationState.hideDistanceMarkers;
    private _itemsAreDirty: boolean;
    public dragInProgress = false;
    public currencySymbol = ApplicationState.currencySymbol();
    public selectableMode: boolean;
    public totalPrice: string;
    public totalTime: string;
    public totalActualTime: string;
    public searchOptions: Array<keyof ScheduleItem> = ['customerName', 'addressDescription'];
    private static _colours = new AlphanumericCharColourDictionary();
    protected filteredItems: Array<ScheduleItem> = [];

    protected filteredItemsLoaded: Array<ScheduleItem>;

    protected applicationState = ApplicationState;

    protected loadMore() {
        this.loadedItemsCount = this.filteredItemsLoaded.length + 50;
        this.filteredItemsLoaded = this.filteredItems.slice(0, this.loadedItemsCount);
    }

    protected paymentTitle(scheduleItem: ScheduleItem) {
        return (
            'Last paid by ' +
            (scheduleItem.lastPaymentMethod || '')
                .replace(/.*?\./, '')
                .replace(/-/g, ' ')
                .replace(/\b([a-z])/g, function (_, initial) {
                    return initial.toUpperCase();
                })
        );
    }

    get loadedItemsCount() {
        return loadMoreState[this.viewId];
    }

    set loadedItemsCount(newValue: number) {
        if (!this.viewId)
            Logger.warn('No view id was set on ScheduleItemListCustomElement this will result in load more not working correctly');

        loadMoreState[this.viewId] = newValue;
    }

    protected scheduleItemsChanged() {
        new ActionBarEvent(false);
        this._itemsAreDirty = true;
        if (this.dragInProgress) return;
        this.refreshFilteredItems();
    }

    public async resetPlannedOrders() {
        if (this.onResetDragSort) this.onResetDragSort();
        this.scheduleItemsChanged();
    }

    public refreshFilteredItems() {
        this._lastToggled = undefined;
        this._lastToggledStatus = true;

        if (!this.scheduleItems) {
            this.filteredItems = [];
        } else {
            this.filteredItems =
                this.status !== undefined ? this.scheduleItems.filter(item => item.status === this.status) : this.scheduleItems;
            this.setPriceAndTimeOnItems();
        }

        this.filteredItemsLoaded = this.filteredItems.slice(0, this.loadedItemsCount > 50 ? this.loadedItemsCount + 50 : 50);
        this.loadedItemsCount = this.filteredItemsLoaded.length;

        this.ready = true;
        this._itemsAreDirty = false;
    }

    public async viewScheduleItem(scheduleItem: ScheduleItem, event: MouseEvent) {
        if (scheduleItem.disabled) return event && event.stopPropagation();

        if (this.selectableMode) {
            event && event.stopPropagation();
            return this.selectItem(scheduleItem, event);
        }
        try {
            if (scheduleItem.status === JobOccurrenceStatus.NotScheduled) {
                const dialog = new JobSummaryDialog(scheduleItem.customer, scheduleItem.job);
                await dialog.show(DialogAnimation.SLIDE);
                return;
            }
            const dialog = new ScheduleDetailsDialog(scheduleItem);
            await dialog.show(DialogAnimation.SLIDE);
        } catch (error) {
            Logger.error('Error in viewScheduleItem() on ScheduleItemListCustomElement', { scheduleItem, error });
        }

        if (event) event.stopPropagation();
    }

    protected async executePrimary(target: ScheduleItem): Promise<ScheduleItem | null> {
        if (!target.scheduleItemActions.primaryAction) {
            return Promise.reject(null);
        }
        const itemIndex = this.filteredItems.indexOf(target);
        try {
            this.filteredItems.splice(itemIndex, 1);
            return await target.scheduleItemActions.primaryAction.handler();
        } catch (error) {
            this.filteredItems.splice(itemIndex, 0, target);
            return Promise.reject(null);
        }
    }

    protected async executeSecondary(target: ScheduleItem): Promise<ScheduleItem | null> {
        if (!target.scheduleItemActions.secondaryAction) return Promise.reject(null);
        return await target.scheduleItemActions.secondaryAction.handler();
    }

    private _actionBarDebounce: any;
    protected setSelectableMode() {
        const selectedItems = this.allScheduleItems.filter(x => x.selected);
        this.selectedItemsData.selectedItems = selectedItems;
        this.selectableMode = !!selectedItems.length;
        if (this.disableActionBarOnSelect) return;

        clearTimeout(this._actionBarDebounce);

        if (this.selectableMode === true) {
            this._actionBarDebounce = setTimeout(() => {
                ActionBarEvent.fromSelectedScheduleItems(
                    this.unselectAllItems,
                    ScheduleService.getBulkActionsForScheduleItem(selectedItems, this.scheduleItems, this.bulkActionWrapper),
                    selectedItems
                );
            }, 100);
        } else {
            this._actionBarDebounce = setTimeout(() => {
                this.unselectAllItems();
            }, 100);
        }
    }

    /**
     * updateDayOrder
     * Called by the sortable attribute to save the new repositioned item
     */
    public updateDayOrder = (oldIndex: number | Array<number>, newIndex: number | Array<number>) => {
        if (oldIndex === newIndex) return this.refreshFilteredItems();
        this.dragInProgress = false;

        const items = this.filteredItems.slice();

        if (this.onDragSortComplete) {
            const fromIndex = Array.isArray(oldIndex) ? oldIndex.sort((x, y) => x - y)[0] : oldIndex;
            const toIndex = Array.isArray(newIndex) ? newIndex[0] : newIndex;

            const selectedItems = items.filter(item => item.selected);
            const itemsInDrag = selectedItems.length ? selectedItems : [items[fromIndex]];

            this.onDragSortComplete(toIndex, items, itemsInDrag);
        }

        if (this._itemsAreDirty) this.refreshFilteredItems();
    };

    public dragStarted = async (_oldId: string, _newId: string) => {
        this.dragInProgress = true;
    };

    protected selectAllItems = () => {
        if (this.filteredItems.length === 0) return;

        const checked = !this.filteredItems[0].selected;
        for (const s of this.filteredItems) s.selected = checked;
        this.setSelectableMode();
    };

    private _lastToggled?: ScheduleItem;
    private _lastToggledStatus = true;
    protected selectItem(scheduleItem: ScheduleItem, event: MouseEvent | null, updatedSelectedMode = true) {
        if (this.disableMultiSelect === true || scheduleItem.disabled) return;

        const shift = (event && event.shiftKey) || false;
        if (shift) {
            const lastToggledIndex = !this._lastToggled ? -1 : this.filteredItems.indexOf(this._lastToggled);
            const thisItemIndex = this.filteredItems.indexOf(scheduleItem);
            if (lastToggledIndex > -1 && thisItemIndex > -1) {
                delete this._lastToggled;
                const from = lastToggledIndex < thisItemIndex ? lastToggledIndex : thisItemIndex;
                const to = lastToggledIndex < thisItemIndex ? thisItemIndex + 1 : lastToggledIndex;
                if (from !== to) {
                    for (const s of this.filteredItems.slice(from, to)) s.selected = this._lastToggledStatus;
                }
            }
        } else {
            this._lastToggled = scheduleItem;
            this._lastToggledStatus = !scheduleItem.selected;
            scheduleItem.selected = !scheduleItem.selected;
        }

        if (updatedSelectedMode) this.setSelectableMode();
        if (event) event.stopPropagation();
    }

    protected unselectAllItems = () => {
        for (let i = 0; i < this.allScheduleItems.length; i++) {
            const scheduleItem = this.allScheduleItems[i];
            scheduleItem.selected = false;
            scheduleItem.selectable = false;
            scheduleItem.disabled = false;
        }
        this.setPriceAndTimeOnItems();
        this.selectableMode = false;
        new ActionBarEvent(false);
    };

    private bulkActionWrapper = async (
        action: (scheduleItems: Array<ScheduleItem>, unselectMethod: () => void, allScheduleItems?: Array<ScheduleItem>) => Promise<any>,
        message: TranslationKey,
        allScheduleItems?: Array<ScheduleItem>
    ) => {
        const selectedItems = this.allScheduleItems.filter((scheduleItem: ScheduleItem) => {
            return scheduleItem.selected === true;
        });
        if (selectedItems.length) {
            try {
                await new Promise<void>(resolve => requestAnimationFrame(() => resolve()));

                const happened = await action(selectedItems, this.unselectAllItems, allScheduleItems);

                await new Promise<void>(resolve => requestAnimationFrame(() => resolve()));

                if (happened && this.scheduleItems.length > 1) {
                    requestAnimationFrame(() => {
                        new NotifyUserMessage(<TranslationKey>(selectedItems.length.toString() + ' ' + message), {
                            count: selectedItems.length.toString(),
                        });
                    });
                }
            } catch (error) {
                new NotifyUserMessage('notifications.oh-no');
                Logger.error(`Error during bulk action wrapper in schedule item list custom element`, { action, error });
            } finally {
                new LoaderEvent(false);
            }
        }
    };

    protected hideTotal: boolean;

    public setPriceAndTimeOnItems() {
        let totalPrice = 0;
        let totalTime = 0;
        let totalActualTime = 0;
        let hideTotal = false;
        for (let i = 0; i < this.filteredItems.length; i++) {
            const item = this.filteredItems[i];
            if (item.occurrence.price === undefined) hideTotal = true;
            else totalPrice += item.occurrence.price || 0;
            const from =
                this.filteredItems[i + 1] &&
                this.filteredItems[i + 1].occurrence.location &&
                this.filteredItems[i + 1].occurrence.location?.lngLat;
            if (from) item.refreshDistance(from);

            totalActualTime += item.actualDuration || 0;

            totalTime += this.durationToMinutes(item.occurrence.duration);
        }

        this.totalPrice = totalPrice.toFixed(2).toString();
        if (!totalTime) this.totalTime = '';
        else {
            this.totalTime = formatTimestampDurationAsTime(totalTime);
            const hours = Math.floor(totalTime / 60);
            const minutes = totalTime % 60;
            this.totalTime = '| ' + hours.toString() + 'h ' + (minutes ? minutes.toString() + 'm' : '') + ' ';
        }
        this.totalActualTime = `(${formatTimestampDurationAsTime(totalActualTime)})`;

        this.hideTotal = hideTotal;
    }

    private durationToMinutes(duration?: string) {
        if (!duration) return 0;
        try {
            const durationParts = duration.split(':');
            const hours = Number(durationParts[0]);
            const minutes = Number(durationParts[1]);
            if (!isNaN(hours) && !isNaN(minutes)) return minutes + hours * 60;
        } catch (error) {
            return 0;
        }
        return 0;
    }

    attached() {
        // WTF: This works but feel free to fix it properly if you dare...
        setTimeout(() => this.setPriceAndTimeOnItems(), 250);
    }

    protected multiUserEnabled = ApplicationState.multiUserEnabled;
    protected getAvatarTextAndColour(scheduleItem: ScheduleItem): { text: string; colour: string } {
        if (!ApplicationState.multiUserEnabled) return { text: '', colour: '' };

        const avatarText = (this.filteredItems.indexOf(scheduleItem) + 1).toString();
        let avatarColour = scheduleItem.roundName
            ? ScheduleItemListCustomElement._colours[scheduleItem.roundName.substring(0, 1).toUpperCase()]
            : ScheduleItemListCustomElement._colours[avatarText.substring(0, 1).toUpperCase()];

        if (scheduleItem.job.rounds && scheduleItem.job.rounds.length > 0 && scheduleItem.job.rounds[0].color) {
            avatarColour = scheduleItem.job.rounds[0].color;
        }

        return { text: avatarText, colour: avatarColour || ScheduleItemListCustomElement._colours.default };
    }

    protected getRoundColour(scheduleItem: ScheduleItem) {
        return scheduleItem.job.rounds && scheduleItem.job.rounds.length > 0 && scheduleItem.job.rounds[0].color
            ? scheduleItem.job.rounds[0].color
            : Utilities.textToColour(scheduleItem.roundName);
    }

    protected getAvatarColour(scheduleItem: ScheduleItem) {
        if (scheduleItem.job.rounds && scheduleItem.job.rounds.length > 0 && scheduleItem.job.rounds[0].color) {
            return scheduleItem.job.rounds[0].color;
        }

        return `#${Utilities.hexFromString(scheduleItem.assignedTo)}`;
    }

    protected getAssigneeBackground(scheduleItem: ScheduleItem) {
        const avatar = scheduleItem.assignees?.[0]?.avatar;
        if (avatar) {
            return `border-color: ${this.getAvatarColour(scheduleItem)}; background-image:url(${avatar})`;
        }
        return `border-color: ${this.getAvatarColour(scheduleItem)}; color: ${this.getAvatarColour(scheduleItem)}`;
    }
    protected getAssigneeText(scheduleItem: ScheduleItem) {
        const assignee = scheduleItem.assignees?.[0];
        if (!assignee) return;
        const avatar = assignee.avatar;
        if (avatar) {
            return;
        }

        if (assignee.name && assignee.name.length > 1) {
            return assignee.name.slice(0, 2);
        }

        return assignee.name;
    }
}
