import type { FranchiseeAreaGeoJsonFeatureCollection } from '@nexdynamic/squeegee-common';
import { createSelector } from 'reselect';
import { selectAreas } from '../createFranchiseMapSlice';

export const createSelectFranchiseeIdToAreaGeoJsonDic = createSelector([selectAreas], areas => {
    const franchiseeIdToGeoJsonDic: { [id: string]: FranchiseeAreaGeoJsonFeatureCollection } = {};
    areas?.forEach(area => {
        franchiseeIdToGeoJsonDic[area.franchiseeId] = { ...area.areaGeoJson };
    });
    return franchiseeIdToGeoJsonDic;
});
