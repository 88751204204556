import { Box } from '@mui/material';
import { getPagingQueryVariables, ListPagination, MultiSelectProvider } from '@nexdynamic/nex-ui-react';
import type { Campaign } from '@nexdynamic/squeegee-common';
import { useState } from 'react';
import { ApplicationState } from '../../../../../ApplicationState';
import Text from '../../../../components/Text';
import useTranslation from '../../../../hooks/useTranslation';
import ContactList from '../../contacts/components/ContactList';
import ContactsCount from '../../contacts/components/ContactsCount';
import { useExcludeListActions } from '../../contacts/components/hooks/useExcludeListActions';
import { getExcludedCustomers } from '../CampaignService';

export type CampaignExcludeListProps = {
    campaign: Campaign;
};
const CONTACTS_PER_PAGE = 5;

export const CampaignExcludeList = ({ campaign }: CampaignExcludeListProps) => {
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState<string | undefined>(undefined);
    const paging = getPagingQueryVariables(page, CONTACTS_PER_PAGE);
    const { t } = useTranslation();
    const excludedCustomers = getExcludedCustomers(campaign._id, paging, search);
    const actions = useExcludeListActions(campaign);

    const onChange = (newPage: number) => {
        setPage(newPage);
    };

    const pagingProps = { count: campaign.excludeList?.length || 0, take: CONTACTS_PER_PAGE, page, onChange };

    if (!ApplicationState.features.ongoingCampaigns && !campaign.ongoing && !campaign.excludeList?.length) return null;

    return (
        <Box sx={{ border: 1, borderColor: 'divider', borderRadius: 1, p: 2, mt: 2 }}>
            <Box sx={{ display: 'flex' }}>
                <Box sx={{ flex: 1 }}>
                    <Text variant="h6" text="send.exclude-list" sx={{ mb: 1 }} />
                </Box>
                <Box>
                    <ContactsCount count={campaign.excludeList?.length || 0} />
                </Box>
            </Box>
            <MultiSelectProvider selectableItems={actions ? excludedCustomers : undefined} actions={actions ? [actions.remove] : undefined}>
                <ContactList onSearch={setSearch} contacts={excludedCustomers} emptyMessage={t('campaign.no-contacts-in-exclude-list')} />
                <Box sx={{ display: 'flex', justifyContent: 'center', py: 2 }}>
                    <ListPagination {...pagingProps} />
                </Box>
            </MultiSelectProvider>
        </Box>
    );
};
