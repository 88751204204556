import { Box, Breadcrumbs, Grid } from '@mui/material';
import { GradientButton, PageContainer, SearchQueryField, useSearchQueryParams } from '@nexdynamic/nex-ui-react';
import { useNavigate } from 'react-router';
import Text from '../../../../components/Text';
import useTranslation from '../../../../hooks/useTranslation';
import TemplateGrid from '../components/TemplateGrid';
import useTemplates from '../hooks/useTemplates';

export const TemplatesView = () => {
    const navigate = useNavigate();
    const templates = useTemplates();

    const {
        searchQuery: [search],
    } = useSearchQueryParams();

    const filteredTemplates = templates?.filter(template => {
        if (!search) return true;
        return template.name.toLowerCase().includes(search.toLowerCase());
    });

    const { t } = useTranslation();
    const create = () => {
        navigate('create');
    };

    return (
        <PageContainer>
            <Grid item xs={12}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Breadcrumbs sx={{ flex: 1 }}>
                        <Text variant="subtitle1" color="text.primary" text="templates.title" />
                    </Breadcrumbs>
                    <Box>
                        <GradientButton text={t('templates.createAction')} onClick={create} color="secondary"></GradientButton>
                    </Box>
                </Box>
            </Grid>

            <Grid item xs={12}>
                <SearchQueryField placeholder={t('general.search-placeholder')} label={t('general.search')} />
            </Grid>
            <Grid item xs={12}>
                <TemplateGrid templates={filteredTemplates} />
            </Grid>
        </PageContainer>
    );
};

export default TemplatesView;
