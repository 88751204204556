import type { TranslationKey } from '@nexdynamic/squeegee-common';
import { ProductLevel } from '@nexdynamic/squeegee-common';
import { PLATFORM } from 'aurelia-framework';
import { ApplicationState } from './ApplicationState';
import type { Badge } from './Components/Badge/badge';
import { HasAdvertsEvent } from './Events/HasAdvertsEvent';
import type { IRoute } from './IRoute';
import { RethinkDbAuthClient } from './Server/RethinkDbAuthClient';
import { isDevMode } from './isDevMode';
export class RouteUtil {
    public static getRoutes(): Array<IRoute> {
        const routes: Array<IRoute> = [
            {
                route: '',
                name: '',
                title: '',
                moduleId: PLATFORM.moduleName('Views/DefaultChildView'),
                nav: false,
                redirect: ApplicationState.defaultRoute,
                settings: {},
            },
            {
                route: 'email-settings',
                name: 'email-settings',
                moduleId: PLATFORM.moduleName('ReactUI/email-settings/EmailSettingsModule'),
                title: 'smtp.settings-title',
                settings: {
                    navIcon: 'email',
                    viewCssClass: 'email-settings-view',
                    roles: ['Owner', 'Admin'],
                },
            },
            {
                route: 'tracker/*path',
                href: '/tracker/home',
                name: 'Time Tracking',
                moduleId: PLATFORM.moduleName('ReactUI/time-tracker/TrackerModule'),
                title: 'route-config.time-tracking-title',
                settings: {
                    navIcon: 'view_week',
                    viewCssClass: 'tracker-view',
                    showInNav: 'configure',
                    roles: ['Owner', 'Admin'],
                    enabled: () => ApplicationState.features.timeTracker,
                },
            },
            {
                route: 'customers/:customerId?/:tab?',
                name: 'customers',
                moduleId: PLATFORM.moduleName('Customers/Customers'),
                title: 'route-config.customers-title',
                settings: {
                    minimumSubscription: ProductLevel.Core,
                    navIcon: 'people',
                    viewCssClass: 'customers-route',
                    showInNav: 'customers',
                    hasHeaderContent: true,
                    roles: ['Owner', 'Admin', 'Creator'],
                    canBeLandingPage: true,
                },
            },
            {
                route: 'owing',
                name: 'owing',
                title: 'route-config.customers-owing-title',
                moduleId: PLATFORM.moduleName('Customers/Views/CustomerList'),
                settings: {
                    minimumSubscription: ProductLevel.Core,
                    navIcon: 'account_balance',
                    viewCssClass: 'customers-route',
                    showInNav: 'customers',
                    hasHeaderContent: true,
                    enabled: () =>
                        ApplicationState.isInAnyRole(['Admin', 'Owner', 'Creator']) || !!ApplicationState.account.showOwingToWorkers,
                    roles: ['Owner', 'Admin', 'Creator', 'Planner', 'Worker'],
                },
            },
            {
                route: 'pending',
                name: 'pending',
                title: 'route-config.customers-pending-title',
                moduleId: PLATFORM.moduleName('ReactUI/pending-payments/PendingModule'),
                settings: {
                    minimumSubscription: ProductLevel.Core,
                    navIcon: 'hourglass_top',
                    viewCssClass: 'pending-route',
                    showInNav: 'customers',
                    hasHeaderContent: true,
                    enabled: () =>
                        ApplicationState.isInAnyRole(['Admin', 'Owner', 'Creator']) || !!ApplicationState.account.showOwingToWorkers,
                    roles: ['Owner', 'Admin', 'Creator', 'Planner', 'Worker'],
                },
            },
            {
                route: 'canvassing',
                name: 'canvassing',
                title: 'route-config.customers-canvassing-title',
                moduleId: PLATFORM.moduleName('Customers/Views/CustomerList'),
                settings: {
                    navIcon: 'person_add',
                    viewCssClass: 'customers-route',
                    showInNav: 'customers',
                    hasHeaderContent: true,
                    minimumSubscription: ProductLevel.Core,
                    roles: ['Canvasser'],
                },
            },
            {
                route: 'pricing/*path',
                href: '/pricing/changes',
                name: 'Pricing',
                moduleId: PLATFORM.moduleName('ReactUI/pricing/PricingModule'),
                title: 'Pricing' as TranslationKey,
                settings: {
                    navIcon: 'price_change',
                    viewCssClass: 'pricing-view',
                    showInNav: 'customers',
                    roles: ['Owner', 'Admin'],
                    enabled: () => ApplicationState.features.pricing,
                    replace: true,
                },
            },
            {
                route: 'send/*path',
                href: '/send/campaigns',
                moduleId: PLATFORM.moduleName('ReactUI/send/SendModule'),
                title: 'Send' as TranslationKey,
                name: 'Send',
                settings: {
                    navIcon: 'send',
                    viewCssClass: 'send-view',
                    showInNav: 'send',
                    roles: ['Owner', 'Admin'],
                    enabled: () => ApplicationState.features.send,
                    tabs: [
                        {
                            id: 'send-campaigns',
                            title: 'Campaigns' as TranslationKey,
                            routeFragment: '/send/campaigns',
                            roles: ['Owner', 'Admin'],
                        },
                        {
                            id: 'send-templates',
                            title: 'Templates' as TranslationKey,
                            routeFragment: '/send/templates',
                            roles: ['Owner', 'Admin'],
                        },
                        {
                            id: 'send-audiences',
                            title: 'Audiences' as TranslationKey,
                            routeFragment: '/send/audiences',
                            roles: ['Owner', 'Admin'],
                        },
                    ],
                },
            },
            {
                route: 'block-editor/:id',
                href: 'block-editor',
                name: 'block-editor',
                moduleId: PLATFORM.moduleName('ReactUI/editor/EditorModule'),
                title: 'send.editor-title',
                enabled: () => ApplicationState.features.blockEditor,
                settings: {
                    viewCssClass: 'block-editor-view',
                    roles: ['Owner', 'Admin'],
                },
            },
            {
                route: 'inbox',
                href: '/inbox',
                name: 'Inbox',
                moduleId: PLATFORM.moduleName('ReactUI/inbox/InboxModule'),
                title: 'inbox.inbox-title',
                settings: {
                    navIcon: 'inbox',
                    viewCssClass: 'inbox-view',
                    showInNav: 'send',
                    roles: ['Owner', 'Admin', 'Creator'],
                    badge: {
                        listenFor: ['notifications'],
                        value: () => ApplicationState.unreadNotificationsCount(),
                    },
                },
            },
            {
                route: 'availability/*path',
                href: '/availability/dashboard',
                name: 'Availability',
                moduleId: PLATFORM.moduleName('ReactUI/availability/AvailabilityModule'),
                title: 'Staff Availability' as TranslationKey,
                settings: {
                    navIcon: 'schedule',
                    viewCssClass: 'availability-view',
                    showInNav: 'configure',
                    roles: ['Owner', 'Admin'],
                    enabled: () => ApplicationState.features.availability,
                    replace: true,
                },
            },
            {
                route: 'balance/*path',
                href: '/balance/dashboard',
                name: 'Balance',
                moduleId: PLATFORM.moduleName('ReactUI/balance/BalanceModule'),
                title: 'balance.balance-title',
                nav: false,

                settings: {
                    enabled: () => ApplicationState.features.balance,
                    navIcon: 'balance',
                    viewCssClass: 'balance-view',
                    minimumSubscription: ProductLevel.Ultimate,
                    roles: ['Owner', 'Admin'],
                },
            },
            {
                route: 'balance/dashboard',
                href: '/balance/dashboard',
                name: 'Dashboard',
                moduleId: PLATFORM.moduleName('ReactUI/balance/BalanceModule'),
                title: 'route-config.dashboard',
                settings: {
                    enabled: () => ApplicationState.features.balance,
                    viewCssClass: 'balance-view',
                    showInNav: 'balance',
                    roles: ['Owner', 'Admin'],
                },
            },
            {
                route: 'balance/accounts',
                href: '/balance/accounts',
                name: 'Accounts',
                moduleId: PLATFORM.moduleName('ReactUI/balance/BalanceModule'),
                title: 'route-config.accounts',
                settings: {
                    enabled: () => ApplicationState.features.balance,
                    viewCssClass: 'balance-view',
                    showInNav: 'balance',
                    roles: ['Owner', 'Admin'],
                },
            },
            {
                route: 'balance/reports',
                href: '/balance/reports',
                name: 'Reports',
                moduleId: PLATFORM.moduleName('ReactUI/balance/BalanceModule'),
                title: 'route-config.reports',
                settings: {
                    enabled: () => ApplicationState.features.balance,
                    viewCssClass: 'balance-view',
                    showInNav: 'balance',
                    roles: ['Owner', 'Admin'],
                },
            },
            {
                route: 'balance/transactions',
                href: '/balance/transactions',
                name: 'Transactions',
                moduleId: PLATFORM.moduleName('ReactUI/balance/BalanceModule'),
                title: 'route-config.transactions',
                settings: {
                    enabled: () => ApplicationState.features.balance,
                    viewCssClass: 'balance-view',
                    showInNav: 'balance',
                    roles: ['Owner', 'Admin'],
                },
            },
            {
                route: 'balance/settings',
                href: '/balance/settings',
                name: 'Settings',
                moduleId: PLATFORM.moduleName('ReactUI/balance/BalanceModule'),
                title: 'general.settings',
                settings: {
                    enabled: () => ApplicationState.features.balance,
                    viewCssClass: 'balance-view',
                    showInNav: 'balance',
                    roles: ['Owner', 'Admin'],
                },
            },
            {
                route: 'balance/vat-returns',
                href: '/balance/vat-returns',
                name: 'VAT Returns',
                moduleId: PLATFORM.moduleName('ReactUI/balance/BalanceModule'),
                title: 'route-config.vat-returns',
                settings: {
                    enabled: () => ApplicationState.features.balance,
                    viewCssClass: 'balance-view',
                    showInNav: 'balance',
                    roles: ['Owner', 'Admin'],
                },
            },
            {
                route: 'suppliers/*path',
                href: '/suppliers/list',
                name: 'Suppliers',
                moduleId: PLATFORM.moduleName('ReactUI/suppliers/SuppliersModule'),
                title: 'suppliers.title',
                settings: {
                    enabled: () => ApplicationState.features.balance,
                    viewCssClass: 'suppliers-view',
                    showInNav: 'balance',
                    roles: ['Owner', 'Admin'],
                },
            },
            {
                route: 'marketplace/*path',
                href: '/marketplace/home',
                name: 'Marketplace',
                moduleId: PLATFORM.moduleName('ReactUI/Marketplace/MarketplaceModule'),
                title: 'settings.marketplace-title',
                settings: {
                    enabled: () => {
                        const buyer = ApplicationState.features.marketplaceBuyer;
                        const seller = ApplicationState.features.marketplaceSeller;
                        return buyer || seller;
                    },
                    navIcon: 'store',
                    viewCssClass: 'marketplace-view',
                    showInNav: 'configure',
                    roles: ['Owner', 'Admin'],
                    badge: {
                        showBadge: true,
                        subscription: (badge: Badge) => {
                            const subscriptionHandler = HasAdvertsEvent.subscribe((event: HasAdvertsEvent) => {
                                badge.showBadge = event.hasAdverts;
                            });
                            return () => subscriptionHandler.dispose();
                        },
                    },
                },
            },
            {
                route: 'audit/*path',
                href: '/audit/home',
                name: 'Audit',
                moduleId: PLATFORM.moduleName('ReactUI/audit/AuditModule'),
                title: 'settings.audit-title',
                settings: {
                    enabled: () =>
                        ApplicationState.features.audit ||
                        ApplicationState.stateFlags.devMode ||
                        !!RethinkDbAuthClient.session?.supportUser,
                    navIcon: 'policy',
                    viewCssClass: 'audit-view',
                    showInNav: 'configure',
                    roles: ['Owner', 'Admin'],
                },
            },

            {
                route: 'about',
                name: 'about',
                moduleId: PLATFORM.moduleName('About/About'),
                title: 'route-config.about-title',
                settings: {
                    navIcon: 'info_outline',
                },
            },
            {
                route: 'account/subscription/:trialEnding',
                href: 'account/subscription',
                name: 'account-subscription',
                title: 'route-config.account-subscription-title',
                moduleId: PLATFORM.moduleName('Account/Views/Subscription'),
                nav: true,
                settings: {
                    roles: ['Owner'],
                },
            },
            {
                route: 'gettingstarted',
                name: 'gettingstarted',
                moduleId: PLATFORM.moduleName('Launch/GettingStarted'),
                title: '',
                nav: false,
                settings: {},
            },
            {
                route: 'quickstart',
                name: 'quickstart',
                moduleId: PLATFORM.moduleName('Launch/QuickStart'),
                title: 'route-config.quickstart-title',
                nav: false,
                settings: {
                    roles: ['Owner', 'Admin'],
                },
            },
            {
                route: 'verify-email',
                name: 'verify-email',
                moduleId: PLATFORM.moduleName('Launch/VerifyEmail'),
                title: 'route-config.verify-email-title',
                nav: false,
                settings: {
                    roles: ['Owner', 'Admin'],
                },
            },
            {
                route: 'directory-edit',
                name: 'portal-directory',
                moduleId: PLATFORM.moduleName('ReactUI/portal/PortalDirectory/PortalDirectoryModule'),
                title: 'route-config.directory-info-title',
                settings: {
                    roles: ['Owner', 'Admin'],
                    minimumSubscription: ProductLevel.Advanced,
                },
            },
            {
                route: 'portal-directory',
                name: 'portal-directory',
                moduleId: PLATFORM.moduleName('ReactUI/portal/PortalDirectory/PortalDirectoryModule'),
                title: 'route-config.directory-info-title',
                settings: {
                    showInNav: 'social',
                    navIcon: 'public',
                    viewCssClass: 'directory-entry-view',
                    roles: ['Owner', 'Admin'],
                    minimumSubscription: ProductLevel.Advanced,
                },
            },
            {
                route: 'account',
                name: 'account',
                moduleId: PLATFORM.moduleName('Account/Account'),
                title: 'route-config.account-details-title',
                settings: {
                    hasHeaderContent: true,
                    navIcon: 'account_box',
                    viewCssClass: 'account-details-view',
                    roles: ['Owner'],
                    enabled: () => ApplicationState.canShowAccountManagement,
                    canBeLandingPage: true,
                },
            },
            {
                route: 'subscription',
                name: 'subscription',
                moduleId: PLATFORM.moduleName('Account/SubscriptionManagement'),
                title: 'global.subscription',
                settings: {
                    navIcon: 'account_box',
                    viewCssClass: 'account-details-view',
                    roles: ['Owner'],
                    enabled: () => ApplicationState.canShowAccountManagement,
                },
            },
            {
                route: 'dashboard',
                name: 'dashboard',
                moduleId: PLATFORM.moduleName('DayPilot/Views/Overview'),
                title: 'route-config.customers-overview-title',
                settings: {
                    navIcon: 'dashboard',
                    showInNav: 'work',
                    hasHeaderContent: true,
                    minimumSubscription: ProductLevel.Core,
                    roles: ['Owner', 'Admin', 'Planner'],
                    canBeLandingPage: true,
                },
            },
            {
                route: 'visual-planner',
                name: 'visual-planner',
                moduleId: PLATFORM.moduleName('VisualPlanning/View/VisualPlanner'),
                title: 'route-config.customers-visual-planner-title',
                settings: {
                    navIcon: 'map',
                    showInNav: 'work',
                    hasHeaderContent: true,
                    minimumSubscription: ProductLevel.Ultimate,
                    roles: ['Owner', 'Admin', 'Planner'],
                    enabled: () => ApplicationState.features.visualPlanner,
                    canBeLandingPage: true,
                },
            },
            {
                route: 'audit',
                name: 'audit',
                moduleId: PLATFORM.moduleName('Users/Security'),
                title: 'route-config.audit-title',
                settings: {
                    navIcon: 'security',
                    viewCssClass: 'users-view',
                    hasHeaderContent: true,
                    showInNav: 'configure',
                    roles: ['Owner', 'Admin'],
                    enabled: () => ApplicationState.hasUltimateOrAbove || isDevMode(),
                },
            },
            {
                route: 'analytics/*path',
                href: '/analytics/home',
                name: 'Analytics',
                moduleId: PLATFORM.moduleName('ReactUI/analytics/AnalyticsModule'),
                title: 'Device Analytics' as TranslationKey,
                settings: {
                    navIcon: 'analytics',
                    viewCssClass: 'analytics-view',
                    showInNav: 'configure',
                    roles: ['Owner', 'Admin'],
                    enabled: () => ApplicationState.stateFlags.devMode,
                },
            },
            {
                route: 'users',
                name: 'users',
                moduleId: PLATFORM.moduleName('Users/Users'),
                title: 'route-config.users-title',
                settings: {
                    navIcon: 'person',
                    viewCssClass: 'users-view',
                    hasHeaderContent: true,
                    showInNav: 'configure',
                    roles: ['Owner', 'Admin'],
                },
            },
            {
                route: 'teams',
                name: 'teams',
                moduleId: PLATFORM.moduleName('Users/Teams'),
                title: 'route-config.teams-title',
                settings: {
                    navIcon: 'people',
                    viewCssClass: 'teams-view',
                    hasHeaderContent: true,
                    showInNav: 'configure',
                    roles: ['Owner', 'Admin'],
                    enabled: () => ApplicationState.multiUserEnabled,
                },
            },
            {
                route: 'alerts/:alertId?',
                name: 'alerts',
                moduleId: PLATFORM.moduleName('Alerts/Alerts'),
                title: 'general.alerts',
                settings: {
                    navIcon: 'err_outline',
                    viewCssClass: 'alerts-view',
                    canBeLandingPage: true,
                },
            },
            {
                route: 'franchise',
                name: 'franchise',
                moduleId: PLATFORM.moduleName('Franchise/Franchising'),
                title: 'route-config.franchise-tools',
                settings: {
                    navIcon: 'build',
                    viewCssClass: 'franchise-view',
                    showInNav: 'franchise',
                    minimumSubscription: ProductLevel.Advanced,
                    roles: ['Owner', 'Admin'],
                    enabled: () => ApplicationState.isFranchiseOwnerAccount(),
                    tabs: [
                        {
                            id: 'franchisee-customers-view',
                            title: 'route-config.franchisee-customers-title',
                            routeFragment: '/franchise/customers',
                            minimumSubscription: ProductLevel.Advanced,
                        },
                        {
                            id: 'franchisee-view',
                            title: 'route-config.franchisees-title',
                            routeFragment: '/franchise/franchisees',
                            minimumSubscription: ProductLevel.Advanced,
                        },
                        {
                            id: 'franchise-view',
                            title: 'route-config.franchise-title',
                            routeFragment: '/franchise/options',
                            minimumSubscription: ProductLevel.Advanced,
                        },
                        {
                            id: 'franchise-download-reports',
                            title: 'route-config.franchisees-download-reports',
                            routeFragment: '/franchise/download-reports',
                            minimumSubscription: ProductLevel.Advanced,
                        },
                    ],
                },
            },
            {
                route: 'franchise-map',
                name: 'franchise-map',
                moduleId: PLATFORM.moduleName('ReactUI/franchise-map/FranchiseMapModule'),
                title: 'route-config.franchise-areas',
                settings: {
                    enabled: () => ApplicationState.isFranchiseOwnerAccount() || ApplicationState.isFranchiseeAccount(),
                    showInNav: 'franchise',
                    navIcon: 'border_inner',
                    viewCssClass: 'franchise-map-view',
                    roles: ['Owner', 'Admin', 'Creator'],
                },
            },
            {
                route: 'rounds',
                name: 'rounds',
                moduleId: PLATFORM.moduleName('Rounds/Rounds'),
                title: 'general.rounds',
                settings: {
                    navIcon: 'group_work',
                    viewCssClass: 'rounds-view',
                    showInNav: 'configure',
                    hasHeaderContent: true,
                    roles: ['Owner', 'Admin'],
                    minimumSubscription: ProductLevel.Core,
                    tabs: [
                        {
                            id: 'rounds',
                            title: 'general.active',
                            routeFragment: '/rounds',
                        },
                        {
                            id: 'rounds-pending',
                            title: 'general.quote',
                            routeFragment: '/rounds/pending',
                            minimumSubscription: ProductLevel.Advanced,
                        },
                    ],
                },
            },
            {
                route: 'services',
                name: 'services',
                moduleId: PLATFORM.moduleName('Services/Services'),
                title: 'route-config.services-title',
                settings: {
                    roles: ['Owner', 'Admin'],
                    navIcon: 'note_add',
                    hasHeaderContent: true,
                    viewCssClass: 'services-view',
                    showInNav: 'configure',
                },
            },
            {
                route: 'services-advanced',
                name: 'services-advanced',
                moduleId: PLATFORM.moduleName('ServicesAdvanced/Services'),
                title: 'route-config.services-advanced-title',
                settings: {
                    navIcon: 'note_add',
                    viewCssClass: 'services-view',
                    showInNav: 'configure',
                    minimumSubscription: ProductLevel.Advanced,
                    enabled: () =>
                        ApplicationState.stateFlags.devMode ||
                        ApplicationState.getSetting('global.directory.enable-advanced-services-for-user', false),
                    roles: ['Owner', 'Admin'],
                },
            },
            {
                route: 'services-advanced/:id/',
                name: 'service-item',
                title: 'route-config.services-title',
                moduleId: PLATFORM.moduleName('ServicesAdvanced/ServiceItem'),
                settings: {
                    minimumSubscription: ProductLevel.Advanced,
                    roles: ['Owner', 'Admin', 'Creator'],
                    viewCssClass: 'services-item-view',
                },
            },
            {
                route: 'mywork',
                name: 'mywork',
                moduleId: PLATFORM.moduleName('Schedule/Views/MyWorkView'),
                nav: false,
                title: 'route-config.schedule-my-work-title',
                settings: {
                    minimumSubscription: ProductLevel.Core,
                    showInNav: 'work',
                    navIcon: 'format_list_bulleted',
                    hasHeaderContent: true,
                    enabled: () => ApplicationState.multiUserEnabled && ApplicationState.isInAnyRole('Worker'),
                    canBeLandingPage: true,
                },
            },
            {
                route: 'mywork/:id',
                name: 'mywork',
                moduleId: PLATFORM.moduleName('Schedule/Views/MyWorkView'),
                nav: false,
                title: 'route-config.schedule-my-work-title',
                settings: {
                    minimumSubscription: ProductLevel.Core,
                    hasHeaderContent: true,
                },
            },
            {
                route: 'day-pilot',
                name: 'day-pilot',
                moduleId: PLATFORM.moduleName('DayPilot/DayPilot'),
                title: 'route-config.day-pilot-title',
                settings: {
                    minimumSubscription: ProductLevel.Core,
                    navIcon: 'navigation',
                    showInNav: 'work',
                    viewCssClass: 'day-pilot',
                    hasHeaderContent: true,
                    tabs: [
                        {
                            id: 'day-pilot-not-done',
                            title: 'route-config.day-pilot-not-done-title',
                            routeFragment: '/day-pilot/not-done',
                        },
                        {
                            id: 'day-pilot-completed',
                            title: 'route-config.day-pilot-completed-title',
                            routeFragment: '/day-pilot/completed',
                        },
                    ],
                    roles: ['Owner', 'Worker'],
                    canBeLandingPage: true,
                },
            },
            {
                route: 'schedule/:date?/:scheduleItemId?',
                name: 'schedule',
                moduleId: PLATFORM.moduleName('Schedule/Views/ScheduleView'),
                nav: false,
                title: 'route-config.schedule-work-title',
                settings: {
                    minimumSubscription: ProductLevel.Core,
                    hasHeaderContent: true,
                    showInNav: 'work',
                    navIcon: 'assessment',
                    roles: ['Owner', 'Admin', 'Planner'],
                    canBeLandingPage: true,
                },
            },
            // {
            //     route: 'schedule/:id',
            //     name: 'schedule',
            //     moduleId: PLATFORM.moduleName('Schedule/Views/ScheduleView'),
            //     nav: false,
            //     title: 'route-config.schedule-work-title',
            //     settings: {
            //         minimumSubscription: ProductLevel.Core,
            //         hasHeaderContent: true,
            //         roles: ['Owner', 'Admin', 'Planner'],
            //     },
            // },
            {
                route: 'multi-planner',
                name: 'multi-planner',
                moduleId: PLATFORM.moduleName('Schedule/Views/MultiDayView'),
                nav: false,
                title: 'route-config.multi-planner',
                settings: {
                    minimumSubscription: ProductLevel.Core,
                    hasHeaderContent: true,
                    showInNav: 'work',
                    navIcon: 'view_column',
                    roles: ['Owner', 'Admin', 'Planner'],
                    canBeLandingPage: true,
                },
            },
            {
                route: 'alljobs',
                name: 'alljobs',
                moduleId: PLATFORM.moduleName('Schedule/Views/AllJobsView'),
                nav: false,
                title: 'route-config.job-list',
                settings: {
                    minimumSubscription: ProductLevel.Core,
                    showInNav: 'work',
                    navIcon: 'list',
                    hasHeaderContent: true,
                    roles: ['Owner', 'Admin', 'Planner'],
                },
            },
            {
                route: 'overdue',
                name: 'overdue',
                moduleId: PLATFORM.moduleName('Schedule/Views/OverdueView'),
                nav: false,
                title: 'route-config.schedule-overdue-title',
                settings: {
                    minimumSubscription: ProductLevel.Core,
                    hasHeaderContent: true,
                    showInNav: 'work',
                    navIcon: 'schedule',
                    roles: ['Owner', 'Admin', 'Planner'],
                },
            },
            {
                route: 'quotes',
                name: 'quotes',
                moduleId: PLATFORM.moduleName('Quotes/Quotes'),
                title: 'route-config.quotes-title',
                settings: {
                    showInNav: 'billing',
                    navIcon: 'request_quote',
                    viewCssClass: 'quotes-route',
                    minimumSubscription: ProductLevel.Core,
                    hasHeaderContent: true,
                    roles: ['Owner', 'Admin', 'Creator'],
                },
            },
            {
                route: 'invoices/:transactionIdOrInvoiceNumber?',
                name: 'invoices',
                moduleId: PLATFORM.moduleName('Invoices/Invoices'),
                title: 'route-config.invoices-title',
                settings: {
                    minimumSubscription: ProductLevel.Core,
                    showInNav: 'billing',
                    navIcon: 'description',
                    viewCssClass: 'invoices-route',
                    hasHeaderContent: true,
                    roles: ['Owner', 'Admin'],
                },
            },
            {
                route: 'expenses',
                name: 'expenses',
                moduleId: PLATFORM.moduleName('Expenses/Expenses'),
                title: 'route-config.expenses-title',
                settings: {
                    minimumSubscription: ProductLevel.Core,
                    showInNav: 'finance',
                    navIcon: 'receipt',
                    viewCssClass: 'expenses-route',
                    hasHeaderContent: true,
                    roles: ['Owner', 'Admin'],
                },
            },
            {
                route: 'expense-categories',
                href: '/expense-categories',
                name: 'Expense Categories',
                moduleId: PLATFORM.moduleName('ReactUI/categories/ExpenseCategoriesModule'),
                title: 'route-config.expense-categories-title',
                settings: {
                    navIcon: 'label',
                    viewCssClass: 'expense-categoires-view',
                    showInNav: 'finance',
                    roles: ['Owner', 'Admin'],
                },
            },
            {
                route: 'chart-of-accounts',
                name: 'chart-of-accounts',
                moduleId: PLATFORM.moduleName('ChartOfAccounts/ChartOfAccounts'),
                title: 'route-config.chart-of-accounts',
                settings: {
                    showInNav: 'finance',
                    navIcon: 'list',
                    viewCssClass: 'invoices-route',
                    hasHeaderContent: true,
                    minimumSubscription: ProductLevel.Advanced,
                    roles: ['Owner', 'Admin'],
                    tabs: [
                        {
                            id: 'chart-of-accounts-default',
                            title: 'route-config.chart-of-accounts',
                            routeFragment: '/chart-of-accounts/default',
                            roles: ['Owner', 'Admin'],
                            minimumSubscription: ProductLevel.Advanced,
                        },
                        {
                            id: 'balance-sheet',
                            title: 'route-config.balance-sheet',
                            routeFragment: '/chart-of-accounts/balance-sheet',
                            roles: ['Owner', 'Admin'],
                            minimumSubscription: ProductLevel.Ultimate,
                        },
                        {
                            id: 'vat-return',
                            title: 'vat.return-title',
                            routeFragment: '/chart-of-accounts/vat-return',
                            roles: ['Owner', 'Admin'],
                            minimumSubscription: ProductLevel.Ultimate,
                        },
                    ],
                    canBeLandingPage: true,
                },
            },
            {
                route: 'payments',
                name: 'payments',
                moduleId: PLATFORM.moduleName('Payments/Payments'),
                title: 'route-config.payments-title',
                settings: {
                    minimumSubscription: ProductLevel.Core,
                    showInNav: 'finance',
                    navIcon: 'payment',
                    viewCssClass: 'invoices-route',
                    hasHeaderContent: true,
                    roles: ['Owner', 'Admin'],
                },
            },
            {
                route: 'insights',
                name: 'insights',
                moduleId: PLATFORM.moduleName('Insights/Insights'),
                title: 'route-config.insights-title',
                settings: {
                    minimumSubscription: ProductLevel.Core,
                    navIcon: 'insert_chart',
                    viewCssClass: 'insights-route',
                    hasHeaderContent: true,
                    showInNav: 'finance',
                    tabs: [
                        {
                            id: 'reports-insights-view',
                            title: 'route-config.insights-reports',
                            routeFragment: '/insights/reports',
                            roles: ['Owner', 'Admin', 'Reporting'],
                        },
                        {
                            id: 'customers-insights-view',
                            title: 'route-config.insights-customers',
                            routeFragment: '/insights/customers',
                            roles: ['Owner', 'Admin'],
                        },
                        {
                            id: 'jobs-insights-view',
                            title: 'route-config.insights-jobs',
                            routeFragment: '/insights/jobs',
                            roles: ['Owner', 'Admin'],
                        },
                        {
                            id: 'financials-insights-view',
                            title: 'route-config.insights-financials',
                            routeFragment: '/insights/financials',
                            roles: ['Owner', 'Admin'],
                        },
                    ],
                    roles: ['Owner', 'Admin', 'Reporting'],
                    canBeLandingPage: true,
                },
            },
            {
                route: 'attachments',
                name: 'attachments',
                moduleId: PLATFORM.moduleName('Attachments/Attachments'),
                title: 'general.attachments',
                settings: {
                    navIcon: 'attach_file',
                    viewCssClass: 'attachment-view',
                    minimumSubscription: ProductLevel.Advanced,
                    showInNav: 'configure',
                    hasHeaderContent: true,
                    roles: ['Owner', 'Admin'],
                },
            },
            {
                route: 'connected-service/:provider',
                name: 'oauth-connected-item',
                title: 'general.connected-services',
                moduleId: PLATFORM.moduleName('ConnectedServices/ConnectedServices'),
                settings: {
                    minimumSubscription: ProductLevel.Advanced,
                    roles: ['Owner', 'Admin', 'Creator'],
                    viewCssClass: 'services-item-view',
                },
            },
            {
                route: 'import-transactions/*path',
                name: 'import-transactions',
                title: 'route-config.import-transactions-title',
                moduleId: PLATFORM.moduleName('Imports/ImportTransactionsModule'),
                settings: {
                    minimumSubscription: ProductLevel.Advanced,
                    roles: ['Owner', 'Admin'],
                },
            },
        ];

        const landingPage = ApplicationState.landingPage;
        if (landingPage) {
            const landingPageRoute = routes.find(route => route.route === landingPage);
            if (landingPageRoute) {
                const isAuthorisedForLandingPage = ApplicationState.canNavigateTo(landingPageRoute);
                if (!isAuthorisedForLandingPage) {
                    ApplicationState.landingPage = null;
                } else {
                    const route = routes.find(x => x.route === landingPage);
                    if (route) {
                        routes[0].redirect = landingPage;
                        return routes;
                    }
                }
            }
        }

        if (ApplicationState.lastRoute && routes.find(x => `/${x.route}` === ApplicationState.lastRoute)) {
            routes[0].redirect = ApplicationState.lastRoute;
        }

        return routes;
    }
}
