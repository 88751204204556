import type { IFabAction, PaymentAccount, Transaction, TranslationKey } from '@nexdynamic/squeegee-common';
import type { Subscription } from 'aurelia-event-aggregator';
import { computedFrom } from 'aurelia-framework';
import { ApplicationState } from '../../ApplicationState';
import { AccountsLedgerService } from '../../ChartOfAccounts/AccountsLedgerService';
import { ConnectedServicesService } from '../../ConnectedServices/ConnectedServicesService';
import { CustomDialog } from '../../Dialogs/CustomDialog';
import { DataRefreshedEvent } from '../../Events/DataRefreshedEvent';
import type { IMenuBarAction } from '../../Menus/IMenuBarAction';
import { isDevMode } from '../../isDevMode';

interface IProviderDetails {
    name: string;
    id: string;
}

const isNotMatchedOrIgnored = (transaction: Transaction) => {
    if (transaction.provider && transaction.externalIds && transaction.externalIds[transaction.provider] === 'ignored') return false;
    if (!transaction.provider) return false;

    return Boolean(!transaction.externalIds || !transaction.externalIds?.[transaction.provider]);
};

export class PaymentAccountDialog extends CustomDialog<boolean> {
    private _dataChangedEvent: Subscription;

    protected dataIsBeingEdited = false;
    protected model = { paymentAccount: <PaymentAccount>{}, balance: 0, unreconciledTrans: 0 };

    protected isDevMode = isDevMode();

    protected unmatchedCount = 0;

    protected showReconcileAction = false;

    constructor(paymentAccount: PaymentAccount, balance?: number, openReconcile = false) {
        super('paymentAccountDialog', '../ChartOfAccounts/Components/PaymentAccountDialog.html', '', {
            okLabel: '',
            cancelLabel: '',
            cssClass: 'payment-account-dialog',
            isSecondaryView: true,
        });
        this.model.paymentAccount = paymentAccount;
        if (balance) this.model.balance = balance;

        const unreconciled = AccountsLedgerService.getUnreconciledTrans(this.model.paymentAccount);
        this.unmatchedCount = unreconciled.filter(isNotMatchedOrIgnored).length;
        // For now only show reconcile if any unreconciled transactions are from csv-import
        this.showReconcileAction = unreconciled.some(tran => tran.provider === 'csv-import');

        if (openReconcile) setTimeout(() => this.reconcile(), 200);
    }

    public async init() {
        this.updateFab();

        this.model.balance = await AccountsLedgerService.getAccountLedgerBalance(this.model.paymentAccount).balance;
        this._dataChangedEvent = DataRefreshedEvent.subscribe(async (event: DataRefreshedEvent) => {
            if (this.dataIsBeingEdited) return;
            if (!event.updatedObjects[this.model.paymentAccount._id]) return;
            this.model.balance = await AccountsLedgerService.getAccountLedgerBalance(this.model.paymentAccount).balance;
            this.model.paymentAccount = event.updatedObjects[this.model.paymentAccount._id] as PaymentAccount;
        });
    }

    @computedFrom('model.paymentAccount')
    public get providers(): Array<IProviderDetails> {
        if (!this.model?.paymentAccount?.externalIds) return [];
        if (!Object.keys(this.model?.paymentAccount?.externalIds).length) return [];

        return Object.keys(this.model?.paymentAccount?.externalIds).map((x: string) => {
            const parts = x.split('.');
            return { name: ApplicationState.localise(('providers.' + parts[0]) as TranslationKey), id: x };
        });
    }

    public dispose() {
        super.dispose();
        this._dataChangedEvent && this._dataChangedEvent.dispose();
    }

    protected contextMenuActions: Array<IMenuBarAction>;

    private updateFab() {
        this.contextMenuActions = [];

        this.contextMenuActions.push({
            tooltip: 'general.delete',
            actionType: 'action-delete',
            handler: this.delete,
            roles: ['Owner', 'Admin'],
        });
        this.contextMenuActions.push({
            tooltip: 'general.edit',
            actionType: 'action-edit',
            handler: this.edit,
            roles: ['Owner', 'Admin'],
        });
        this.contextMenuActions.push({
            tooltip: 'payment.account-edit-opening-balance',
            actionType: 'action-edit',
            handler: this.editOpeningBalance,
            roles: ['Owner', 'Admin'],
        });
        this.contextMenuActions.push({
            tooltip: 'payment.account-set-account-type',
            actionType: 'action-edit',
            handler: this.setType,
            roles: ['Owner', 'Admin'],
        });
        this.contextMenuActions.push({
            tooltip: 'payment.account-move-transactions',
            actionType: 'action-move-round',
            handler: this.move,
            roles: ['Owner', 'Admin'],
        });

        const actions: Array<IFabAction> = [];

        if (!this.model.paymentAccount.dontAllowPayments) {
            actions.push(
                {
                    tooltip: 'payment-accounts.import',
                    actionType: 'action-import',
                    handler: () => this.customImport(),
                    roles: ['Owner', 'Admin'],
                },
                {
                    tooltip: 'payment-accounts.create-payment',
                    actionType: 'action-credit',
                    handler: this.createPayment,
                    roles: ['Owner', 'Admin'],
                },
                {
                    tooltip: 'payment-accounts.create-refund',
                    actionType: 'action-invoice',
                    handler: this.createRefund,
                    roles: ['Owner', 'Admin'],
                }
            );
        }
        if (!this.model.paymentAccount.dontAllowTransfers) {
            actions.push(
                {
                    tooltip: 'payment-accounts.transfer-in',
                    actionType: 'action-credit',
                    handler: this.transferIn,
                    roles: ['Owner', 'Admin'],
                },
                {
                    tooltip: 'payment-accounts.transfer-out',
                    actionType: 'action-invoice',
                    handler: this.transferOut,
                    roles: ['Owner', 'Admin'],
                }
            );
        }
        if (!this.model.paymentAccount.dontAllowExpenses) {
            actions.push({
                tooltip: 'payment-accounts.create-expense',
                actionType: 'action-new-expense',
                handler: this.createExpense,
                roles: ['Owner', 'Admin'],
            });
        }

        this.createFab(actions);
    }

    private delete = async () => {
        if (!(await AccountsLedgerService.deleteAccount(this.model.paymentAccount))) return;
        this.cancel();
    };

    private edit = async () => {
        AccountsLedgerService.editAccountName(this.model.paymentAccount);
    };
    private editOpeningBalance = async () => {
        AccountsLedgerService.editOpeningBalance(this.model.paymentAccount);
    };

    private move = async () => {
        AccountsLedgerService.moveTransactions(this.model.paymentAccount);
    };

    private setType = async () => {
        AccountsLedgerService.setType(this.model.paymentAccount);
    };

    private createPayment = async () => {
        AccountsLedgerService.createPayment(this.model.paymentAccount);
    };

    private createRefund = async () => {
        AccountsLedgerService.createRefund(this.model.paymentAccount);
    };

    private transferIn = async () => {
        AccountsLedgerService.transferIn(this.model.paymentAccount);
    };

    private transferOut = async () => {
        AccountsLedgerService.transferOut(this.model.paymentAccount);
    };

    private createExpense = async () => {
        AccountsLedgerService.createExpense(this.model.paymentAccount);
    };

    public async reconcile() {
        this.dataIsBeingEdited = true;

        await AccountsLedgerService.reconcile(this.model.paymentAccount);
        this.dataIsBeingEdited = false;
    }

    protected showDuplicates = false;

    public async findDuplicates() {
        this.dataIsBeingEdited = true;
        this.showDuplicates = !this.showDuplicates;
        this.dataIsBeingEdited = false;
    }

    public async sync(provider: string) {
        this.dataIsBeingEdited = true;
        await ConnectedServicesService.importPayments(provider, this.model.paymentAccount);
        this.dataIsBeingEdited = false;
    }

    private async customImport() {
        await ApplicationState.betaWarning();
        this.cancel();
        if (!this.model.paymentAccount._id) return;
        ApplicationState.navigateToRouteFragment(`import-transactions/${this.model.paymentAccount._id}`);
    }
}
