import { Box, LinearProgress } from '@mui/material';
import type { MapGeoJsonFeature } from '@nexdynamic/nex-ui-react';
import { MapV2, ReactControl } from '@nexdynamic/nex-ui-react';
import type {
    Franchisee,
    FranchiseeAreaGeoJsonFeature,
    FranchiseeAreaGeoJsonFeatureCollection,
    FranchiseeMarkerGeoJsonFeature,
} from '@nexdynamic/squeegee-common';
import * as turf from '@turf/turf';
import type { MapLibreEvent } from 'maplibre-gl';
import { useEffect, useRef } from 'react';
import type { ViewState } from 'react-map-gl/maplibre';
import { type MapRef } from 'react-map-gl/maplibre';
import { ApplicationState } from '../../ApplicationState';
import { AddressLookupControl } from '../../Components/Map/controls/AddressLookup/AddressLookupControl';
import useStoredObjects from '../hooks/useStoredObjects';
import { CreateCustomerControl } from './controls/CreateCustomerControl/CreateCustomerControl';
import { useCreateCustomerStore } from './controls/CreateCustomerControl/stores/createCustomerStore';
import { EditTools } from './controls/EditTools';
import { FranchiseeInspectPanel } from './controls/FranchiseeInspectPanel/FranchiseeInspectPanel';
import { openCustomer } from './helpers/openCustomer';
import { useBoundFranchiseMapStore } from './stores/boundFranchiseMapStore';
import { createSelectAreaLayers } from './stores/franchiseMapSlice/selectors/createSelectAreaLayers';
import { createSelectInitialViewState } from './stores/franchiseMapSlice/selectors/createSelectInitialViewState';

// Make turf available in console
(window as any).turf = turf;

export const FranchiseMapMain = () => {
    const mapRef = useRef<MapRef>(null);
    const franchisees = useStoredObjects<Franchisee>('franchisee');
    const { layers, layerGroupsDic } = useBoundFranchiseMapStore(createSelectAreaLayers);

    const {
        loading,
        areas,
        fetchAreasDataSummary,
        updateFranchisees,
        setSelectedFeatures,
        isEditing,
        candidateFeatureCollection,
        createPolygonFeature,
        updatePolygonFeature,
        deletePolygonFeature,
        franchiseeIdsUserHasCreatorOrAdminRolesIn,
        isFranchiseOwner,
    } = useBoundFranchiseMapStore();

    const initialView = useBoundFranchiseMapStore(createSelectInitialViewState);

    const { setFranchiseeAreasDataSummary } = useCreateCustomerStore();

    useEffect(() => {
        fetchAreasDataSummary();

        const urlParams = new URLSearchParams(window.location.search);
        const customerId = urlParams.get('customerId');

        if (customerId) openCustomer({ customerId });
    }, []);

    useEffect(() => {
        updateFranchisees(franchisees);
    }, [franchisees]);

    useEffect(() => {
        if (!areas.length) return;
        setFranchiseeAreasDataSummary({
            franchiseeAreas: areas,
            isFranchiseOwner: isFranchiseOwner ?? false,
            franchiseeIdsUserHasCreatorOrAdminRolesIn: franchiseeIdsUserHasCreatorOrAdminRolesIn ?? [],
        });
    }, [areas]);

    const onFeatureClick = (
        features: Array<MapGeoJsonFeature<FranchiseeAreaGeoJsonFeatureCollection | FranchiseeMarkerGeoJsonFeature>>
    ) => {
        if (isEditing) return; // We're editing a feature, don't select other features
        const points = features.filter(f => f.layer.id.includes('unclustered'));
        const markerIds: Array<string> = points.map(point => point.properties.markerId);
        const franchiseeAreaIds = features
            .filter(
                f =>
                    f.layer.id.includes('polygon') &&
                    !!f.properties.franchiseeId &&
                    (points.length === 0 || points.some(p => p.properties.franchiseeId === f.properties.franchiseeId))
            )
            .map(feature => feature.properties.franchiseeId) as string[];

        if (points.length) {
            const franchiseeAreasInPoints = Array.from(new Set(points.map(point => point.properties.franchiseeId)));
            const franchiseeAreaIdsToAdd = franchiseeAreasInPoints.filter(id => !franchiseeAreaIds.includes(id));
            if (franchiseeAreaIdsToAdd) {
                franchiseeAreaIdsToAdd.forEach(id => franchiseeAreaIds.push(id));
            }
        }

        setSelectedFeatures({ franchiseeAreaIds, markerIds });
    };

    const onDrawCreate = (event: { features: Array<FranchiseeAreaGeoJsonFeature> }) => {
        createPolygonFeature(event.features[0]);
    };

    const onDrawUpdate = (event: { features: Array<FranchiseeAreaGeoJsonFeature> }) => {
        updatePolygonFeature(event.features);
    };

    const onDrawDelete = (event: { features: Array<FranchiseeAreaGeoJsonFeature> }) => {
        deletePolygonFeature(event.features);
    };

    const onMapLoad = (event: MapLibreEvent) => ((window as any).map = event.target);

    const onMapMoveEnd = (event: { viewState: ViewState }) => {
        localStorage.setItem('franchiseMapViewState', JSON.stringify(event.viewState));
    };

    return (
        <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
            <LinearProgress hidden={!loading} color="info" sx={{ position: 'absolute', width: '100%' }} />
            <MapV2
                ref={mapRef}
                initialViewState={initialView}
                defaultControls={{ navigation: 'top-right', geolocate: 'top-right' }}
                customControls={[
                    { control: new ReactControl(<AddressLookupControl />), position: 'top-left' },
                    { control: new ReactControl(<CreateCustomerControl />), position: 'top-left' },
                    { control: new ReactControl(<FranchiseeInspectPanel />), position: 'top-left' },
                    { control: new ReactControl(<EditTools />), position: 'top-left' },
                ]}
                layers={layers}
                layerGroups={layerGroupsDic}
                events={{ onFeatureClick, onDrawCreate, onDrawUpdate, onDrawDelete, onMapLoad, onMapMoveEnd }}
                editMode={isEditing}
                editInitialFeatures={candidateFeatureCollection?.features}
                mapStyle="https://api.maptiler.com/maps/streets-v2/style.json?key=cCG7BvNZxuToQVuJ9R6c"
                userIdAndMapFeatureKey={`${ApplicationState.account.email}-franchise-map`}
            />
        </Box>
    );
};
