import type { FranchiseeAreaData } from '@nexdynamic/squeegee-common';
import { createSelector } from 'reselect';
import { selectAreas, selectSelectedAreaIds } from '../createFranchiseMapSlice';

// export type SelectedAreaData = {
//     franchiseeId: string;
//     franchiseeName: string;
//     color: string;
//     areaGeoJson: FranchiseeAreaGeoJsonFeature;
// };

export const createSelectSelectedAreasData = createSelector([selectAreas, selectSelectedAreaIds], (areas, selectedAreaIds) => {
    if (!selectedAreaIds) return [];

    const selectedAreas = new Array<FranchiseeAreaData>();

    for (const selectedAreaId of selectedAreaIds) {
        const selectedArea = areas.find(area => area.franchiseeId === selectedAreaId);

        if (!selectedArea?.areaGeoJson) continue;

        if (selectedArea) {
            selectedAreas.push(selectedArea);
        }
    }

    return selectedAreas;
});
