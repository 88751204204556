// TODO: Can this be optimised to reduce the build time?
import type { AppRouter } from '@nexdynamic/squeegee-server/src/routes/V2/TrpcRouter';
import { createTRPCProxyClient, httpBatchLink } from '@trpc/client';
import { RethinkDbAuthClient } from '../../Server/RethinkDbAuthClient';
import { getHeaders } from '../../Server/getHeaders';

export const generateTrpc = (endpoint: string) => {
    const url = `${endpoint}/api/v2`;
    const headers = getHeaders(RethinkDbAuthClient.session);
    const batchLink = httpBatchLink({ url, headers, maxURLLength: 2083 });
    const links = [batchLink];
    const options = { links } as any;
    return createTRPCProxyClient<AppRouter>(options);
};
