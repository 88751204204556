import type { LayerConfig } from '@nexdynamic/nex-ui-react';
import { createSelector } from 'reselect';
import { selectCandidateFeatureCollection, selectCurrentEditingFranchiseeId, selectCurrentFeatureCollection } from '../createEditAreaSlice';

type EditModeLayerData = {
    candidateLayer: LayerConfig | null;
    currentLayer: LayerConfig | null;
    currentFranchiseeId: string | null;
};
export const createSelectMapLayersForEditMode = createSelector(
    [selectCandidateFeatureCollection, selectCurrentFeatureCollection, selectCurrentEditingFranchiseeId],
    (candidateAreaGeoJson, currentAreaGeoJson, currentEditingFranchiseeId) => {
        const layerData: EditModeLayerData = {
            candidateLayer: null,
            currentFranchiseeId: currentEditingFranchiseeId,
            currentLayer: null,
        };
        if (!currentEditingFranchiseeId || !currentAreaGeoJson || !candidateAreaGeoJson) return layerData;

        layerData.candidateLayer = {
            id: `${currentEditingFranchiseeId}-candidate-polygon-layer`,
            style: { color: '#0f8', opacity: 0.3 },
            data: candidateAreaGeoJson,
            type: 'polygon',
        };

        layerData.currentLayer = {
            id: `${currentEditingFranchiseeId}-current-polygon-layer`,
            style: { color: '#888' },
            data: currentAreaGeoJson,
            type: 'polygon',
            disabled: true,
        };

        return layerData;
    }
);
