import { createSelector } from 'reselect';
import { getFranchiseeAreaLayers } from '../../../helpers/getFranchiseeAreaLayers';
import { selectCandidateFeatureCollection, selectIsEditing } from '../../editAreaSlice/createEditAreaSlice';
import { selectAreas, selectSelectedAreaIds, selectSelectedCustomerJobLocationMarkerIds } from '../createFranchiseMapSlice';

export const createSelectAreaLayers = createSelector(
    [selectAreas, selectCandidateFeatureCollection, selectSelectedAreaIds, selectSelectedCustomerJobLocationMarkerIds, selectIsEditing],
    (areas, candidateArea, selectedAreaIds, selectedMarkerIds, isEditing) => {
        return getFranchiseeAreaLayers({
            franchiseeAreas: areas,
            candidateArea,
            selectedAreaIds: selectedAreaIds ?? undefined,
            selectedMarkerIds,
            layersAreDisabled: isEditing,
        });
    }
);
