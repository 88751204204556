import { Breadcrumbs, Grid, Link, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { PageContainer } from '@nexdynamic/nex-ui-react';
import type { Audience } from '@nexdynamic/squeegee-common';
import { copyObject } from '@nexdynamic/squeegee-common';
import { useNavigate, useParams } from 'react-router';
import Text from '../../../../components/Text';
import useStoredObject from '../../../../hooks/useStoredObject';
import type { AudienceFormFields } from '../component/AudienceForm';
import AudienceForm from '../component/AudienceForm';
import useUpdateAudience from '../hooks/useUpdateAudience';

const EditAudience = () => {
    const navigate = useNavigate();
    const { id = '' } = useParams();
    const audience = useStoredObject<Audience>(id);
    const { updateAudience } = useUpdateAudience(id);

    const onSubmit = async (fields: AudienceFormFields) => {
        await updateAudience(fields);
        navigate(-1);
    };

    const View = () => {
        if (audience) {
            const copy = copyObject(audience);
            const audienceFormFields: AudienceFormFields = {
                name: copy.name,
                description: copy.description || '',
                filterGroups: copy.filterGroups ? copyObject(copy.filterGroups) : [],
            };
            return <AudienceForm fields={audienceFormFields} onSubmit={onSubmit} />;
        }
        return (
            <Box>
                <Text text="audiences.audience-not-found" />
            </Box>
        );
    };

    return (
        <PageContainer>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Link
                                        component="button"
                                        underline="none"
                                        sx={{ cursor: 'pointer' }}
                                        onClick={() => navigate('/send/audiences')}
                                    >
                                        <Text variant="subtitle1" text="audiences.title" />
                                    </Link>
                                    <Link
                                        sx={{ cursor: 'pointer' }}
                                        underline="none"
                                        onClick={() => navigate(`/send/audiences/${audience?._id}`)}
                                    >
                                        <Typography variant="subtitle1">{audience?.name}</Typography>
                                    </Link>
                                    <Text variant="subtitle1" color="text.primary" text="general.edit" />
                                </Breadcrumbs>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <View />
                    </Grid>
                </Grid>
            </Grid>
        </PageContainer>
    );
};

export default EditAudience;
