import { bindable } from 'aurelia-framework';
import moment from 'moment';
import { ApplicationState } from '../../ApplicationState';

const accountStatEventTypes = [
    'subscription-selected',
    'users-count-selected',
    'subscription-period-selected',
    'subscription-submitted',
] as const;
type AccountStatEventTypes = (typeof accountStatEventTypes)[number];
type AccountStatEvent = {
    timestamp: number;
    type: AccountStatEventTypes;
    value: unknown;
};

export type AccountStatistics = Array<AccountStatEvent>;

export class ClickTracker {
    @bindable() accountStatEventType?: AccountStatEventTypes;
    @bindable() targetValue?: unknown;
    @bindable() disableTracking = false;

    accountStatEventTypeChanged(newValue: AccountStatEventTypes) {
        if (!accountStatEventTypes.includes(newValue))
            throw console.error(`account stat event type invalid. '${this.accountStatEventType}' is not an event type.`);
    }

    recordStatEvent(statEvent: AccountStatEvent) {
        const currentStatEvents = ApplicationState.getSetting<AccountStatistics>('user-account.stats', []).filter(
            x => x.timestamp > moment().subtract(1, 'month').startOf('day').valueOf()
        );

        ApplicationState.setSetting<AccountStatistics>('user-account.stats', [...currentStatEvents, statEvent]);
    }

    handleClick() {
        if (this.disableTracking) return;
        if (!this.accountStatEventType) throw new Error('No accountStatEventType passed');
        if (!this.targetValue) throw new Error('No targetValue passed');

        const update: AccountStatEvent = {
            timestamp: new Date().valueOf(),
            type: this.accountStatEventType,
            value: this.targetValue,
        };

        this.recordStatEvent(update);
    }
}
