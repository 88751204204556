import { createSelector } from 'reselect';
import { ApplicationState } from '../../../../../ApplicationState';

export const createSelectInitialViewState = createSelector(() => {
    const persistedViewStateData = localStorage.getItem('franchiseMapViewState');
    const persistedViewState = persistedViewStateData ? JSON.parse(persistedViewStateData) : null;

    if (persistedViewState) return persistedViewState;

    const { lngLat } = ApplicationState.account.businessAddress;
    if (!lngLat) {
        return {
            longitude: -0.13,
            latitude: 51.51,
        };
    }

    return {
        longitude: lngLat[0],
        latitude: lngLat[1],
        zoom: 10,
    };
});
