import { List } from '@mui/material';
import { EmptyList, SearchQueryField, useSearchQueryParams } from '@nexdynamic/nex-ui-react';
import type { Campaign } from '@nexdynamic/squeegee-common';
import { sortByUpdatedDateDesc } from '@nexdynamic/squeegee-common';
import useStoredObjects from '../../../../hooks/useStoredObjects';
import useTranslation from '../../../../hooks/useTranslation';
import CampaignIcon from './CampaignIcon';
import CampaignListItem from './CampaignListItem';

const CampaignList = () => {
    const { t } = useTranslation();

    const campaigns = useStoredObjects<Campaign>('campaigns', { sort: sortByUpdatedDateDesc });
    const {
        searchQuery: [search],
    } = useSearchQueryParams();

    const items = campaigns
        ?.filter(campaign => {
            if (!search) return true;
            return campaign.name.toLowerCase().includes(search.toLowerCase());
        })
        .map(campaign => {
            return <CampaignListItem key={campaign._id} campaign={campaign} />;
        });

    return (
        <>
            <SearchQueryField placeholder={t('general.search-placeholder')} label={t('general.search')}></SearchQueryField>
            <List data-testid="CampaignsList">
                {items?.length ? items : <EmptyList text={t('campaigns.empty')} icon={<CampaignIcon />} />}
            </List>
        </>
    );
};

export default CampaignList;
