import { Box, Breadcrumbs, Grid, Link, Typography } from '@mui/material';
import { PageContainer } from '@nexdynamic/nex-ui-react';
import { useContext } from 'react';
import { useNavigate, useParams } from 'react-router';
import Text from '../../../../components/Text';
import { CampaignProvider, CampaignProviderContext } from '../CampaignProvider';
import CampaignDashboard from '../components/CampaignDashboard';

export const CampaignComponent = () => {
    const { id = '' } = useParams();

    if (!id) return null;

    return (
        <CampaignProvider id={id}>
            <CampaignTitleAndDashboard />
        </CampaignProvider>
    );
};

export const CampaignTitleAndDashboard = () => {
    const { campaign } = useContext(CampaignProviderContext);
    const navigate = useNavigate();

    return (
        <PageContainer>
            <Grid item xs={12}>
                <Box sx={{ display: 'flex' }}>
                    <Box sx={{ flex: 1 }}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link
                                underline="none"
                                component="button"
                                sx={{ cursor: 'pointer' }}
                                onClick={() => navigate('/send/campaigns')}
                            >
                                <Text variant="subtitle1" text="campaigns.title" />
                            </Link>
                            <Typography variant="subtitle1" color="text.primary">
                                {campaign?.name}
                            </Typography>
                        </Breadcrumbs>
                    </Box>
                </Box>
            </Grid>

            <Grid item xs={12}>
                {campaign ? (
                    <CampaignDashboard campaign={campaign} />
                ) : (
                    <Box>
                        <Text text="campaigns.campaign-not-found" />
                    </Box>
                )}
            </Grid>
        </PageContainer>
    );
};
