import { Button } from '@mui/material';
import { type NexAction } from '@nexdynamic/nex-ui-react';
import { type Campaign, type Customer, type TranslationKey } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../../../../../../ApplicationState';
import useTranslation from '../../../../../hooks/useTranslation';
import { addToExcludelist, removeFromExcludeList } from '../../../campaigns/CampaignService';

type ExcludeListActions = (campaign: Campaign) =>
    | {
          add: NexAction<(items: Array<Customer>) => Promise<void>>;
          remove: NexAction<(items: Array<Customer>) => Promise<void>>;
      }
    | undefined;

export const useExcludeListActions: ExcludeListActions = (campaign: Campaign) => {
    const { t } = useTranslation();
    if (!campaign.ongoing && !ApplicationState.features.ongoingCampaigns) return undefined;

    const actionButton = (text: TranslationKey) => {
        return (
            <Button variant="outlined" size="small">
                {t(text)}
            </Button>
        );
    };

    return {
        add: {
            run: async (selectedItems: Array<Customer>) => {
                await addToExcludelist(selectedItems, campaign);
                return;
            },
            disabled: false,
            icon: actionButton('general.exclude'),
            name: 'Add to Exclude List',
        },
        remove: {
            run: async (selectedItems: Array<Customer>) => {
                await removeFromExcludeList(selectedItems, campaign);
                return;
            },
            disabled: false,
            icon: actionButton('general.include'),
            name: 'Remove from Exclude List',
        },
    };
};
