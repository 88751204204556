import { del, get, set } from 'idb-keyval';
import { create } from 'zustand';
import type { StateStorage } from 'zustand/middleware';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { ApplicationState } from '../../../ApplicationState';
import { createEditAreaSlice, type EditAreaStoreSlice } from './editAreaSlice/createEditAreaSlice';
import { createFranchiseMapSlice, type FranchiseMapStoreSlice } from './franchiseMapSlice/createFranchiseMapSlice';

const storage: StateStorage = {
    getItem: async (name: string): Promise<string | null> => {
        return (await get(name)) || null;
    },
    setItem: async (name: string, value: string): Promise<void> => {
        await set(name, value);
    },
    removeItem: async (name: string): Promise<void> => {
        await del(name);
    },
};

export type BoundFranchiseMapStore = FranchiseMapStoreSlice & EditAreaStoreSlice;

// This is a temporary place for this, in future we will have a top-level
// store that will be responsible for managing all stores across the app
export const useBoundFranchiseMapStore = create(
    devtools(
        immer(
            persist<BoundFranchiseMapStore>(
                (...a) => ({
                    ...createFranchiseMapSlice(...a),
                    ...createEditAreaSlice(...a),
                }),
                {
                    name: `franchise-map-store-${ApplicationState.account.email}`,
                    storage: createJSONStorage(() => storage),
                    version: 2,
                    onRehydrateStorage: () => {
                        console.log('[BoundFranchiseMapStore] hydration starts');

                        return (state, error) => {
                            if (error) {
                                console.warn('[BoundFranchiseMapStore] an error occurred during hydration', error);
                            } else {
                                console.log('[BoundFranchiseMapStore] hydration finished');
                                console.log('[BoundFranchiseMapStore] state:', state);
                            }
                        };
                    },
                }
            )
        )
    )
);
