import { Box, Grid, Typography } from '@mui/material';
import type { Campaign, Template } from '@nexdynamic/squeegee-common';
import { CampaignStatus } from '@nexdynamic/squeegee-common';
import { useState } from 'react';
import { t } from '../../../../../t';
import Text from '../../../../components/Text';
import useStoredObject from '../../../../hooks/useStoredObject';
import AudienceContacts from '../../audiences/component/AudienceContacts';
import TemplateGrid from '../../templates/components/TemplateGrid';
import CampaignActions from './CampaignActions';
import CampaignContactList from './CampaignContactList';
import CampaignDeliveryReports from './CampaignDeliveryReports';
import { CampaignExcludeList } from './CampaignExcludeList';
import { NewMembersContactList } from './NewMembersContactList';

type CampaignDashboardProps = {
    campaign: Campaign;
};

const CampaignDashboard = ({ campaign }: CampaignDashboardProps) => {
    const [sent, setSent] = useState<boolean>(false);

    const template = useStoredObject<Template>(campaign.templateId);

    const showNewMembersList = campaign.ongoing && !sent;

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                    <Box sx={{ flex: 1 }}>
                        <Typography m={0} variant="h4" fontWeight="bold">
                            {campaign.name}
                        </Typography>
                    </Box>
                    <Box>
                        <CampaignActions sent={sent} campaignId={campaign._id} sentToNew={() => setSent(true)} />
                    </Box>
                </Box>
            </Grid>

            {showNewMembersList && (
                <Grid item xs={12}>
                    <NewMembersContactList campaign={campaign} />
                </Grid>
            )}

            <Grid item xs={12}>
                <CampaignDeliveryReports campaign={campaign} />
            </Grid>

            <Grid item xs={12}>
                <Text variant="h6" text="templates.title" sx={{ mb: 2 }} />
                <TemplateGrid templates={template ? [template] : []} />
            </Grid>

            {campaign.status === CampaignStatus.Draft && !campaign.ongoing && (
                <Grid item xs={12}>
                    <Text variant="h6" text="audiences.title" sx={{ mb: 1 }} />
                    <AudienceContacts audienceContainer={campaign} />
                </Grid>
            )}

            {(campaign.status === CampaignStatus.Published || campaign.ongoing) && (
                <Grid item xs={12}>
                    <Text variant="h6" text={t('general.contacts')} sx={{ mb: 1 }} />
                    <CampaignContactList campaign={campaign} />
                </Grid>
            )}

            {campaign.ongoing && (
                <Grid item xs={12}>
                    <CampaignExcludeList campaign={campaign} />
                </Grid>
            )}
        </Grid>
    );
};

export default CampaignDashboard;
