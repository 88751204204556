import type { FranchiseeAreaDataSummary } from '@nexdynamic/squeegee-common';
import { enqueueSnackbar } from 'notistack';
import { Api } from '../../../Server/Api';

export const getFranchiseAreasSummaryData = async () => {
    const response = await Api.get<FranchiseeAreaDataSummary>(null, '/api/franchise/areas');

    if (response?.status === 200) {
        return response.data;
    } else {
        enqueueSnackbar('Failed to fetch franchisee areas', { variant: 'error' });
        console.error('Failed to fetch franchisee areas', response?.data);
    }
};
