import type { Campaign, CampaignCreateInput, CampaignDeliveryType } from '@nexdynamic/squeegee-common';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import useTranslation from '../../../../hooks/useTranslation';
import { campaignCreate } from '../CampaignService';

export class CampaignCreateFields {
    name = '';
    audienceIds: Array<string> = [];
    selectedDeliveryMethods: Array<CampaignDeliveryType> = [];
    templateId? = '';
    description = '';
    ongoing = false;
}

const toCampaignCreateInput = (fields: CampaignCreateFields): CampaignCreateInput => {
    return {
        name: fields.name,
        audienceIds: fields.audienceIds,
        deliveryMethods: fields.selectedDeliveryMethods,
        templateId: fields.templateId,
        description: fields.description,
        ongoing: fields.ongoing,
    };
};

type UseCampaignCreate = () => {
    running: boolean;
    campaignCreate: (fields: CampaignCreateFields) => Promise<Campaign | undefined | null>;
};

const useCampaignCreate: UseCampaignCreate = () => {
    const [running, setRunning] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    return {
        running,
        async campaignCreate(fields) {
            try {
                setRunning(true);
                const campaign = await campaignCreate(toCampaignCreateInput(fields));
                enqueueSnackbar(t('campaigns.created', { name: fields.name }), { variant: 'success' });
                return campaign;
            } catch (error) {
                enqueueSnackbar(t('campaigns.create-failed'), { variant: 'error' });
            } finally {
                setRunning(false);
            }
        },
    };
};

export default useCampaignCreate;
