import SearchIcon from '@mui/icons-material/Search';
import { Box, Fade, Grid, InputAdornment, List, Pagination, TextField, Typography } from '@mui/material';
import { EmptyList } from '@nexdynamic/nex-ui-react';
import type { Customer, TranslationKey } from '@nexdynamic/squeegee-common';
import { useMemo, useState } from 'react';
import useTranslation from '../../../../hooks/useTranslation';
import ContactListItem from './ContactListItem';
import ContactsIcon from './ContactsIcon';

type ContactListProps = {
    contacts?: Array<Customer>;
    emptyMessage?: TranslationKey;
    onSearch?: (search: string) => void;
};

const ContactList = ({ contacts = [], emptyMessage = 'contacts.empty', onSearch }: ContactListProps) => {
    const [search, setSearch] = useState<string>();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const itemsPerPage = 10;

    const { t } = useTranslation();

    const handleSearch = (searchTerm: string) => {
        setSearch(searchTerm);
        onSearch && onSearch(searchTerm);
        setCurrentPage(1); // Reset to first page on search
    };

    const handlePageChange = (_event: React.ChangeEvent<unknown>, page: number) => {
        setCurrentPage(page);
    };

    const totalPages = Math.ceil(contacts.length / itemsPerPage);

    const getView = () => {
        if (contacts.length === 0 && !search?.length) {
            return <EmptyList text={t(emptyMessage)} icon={<ContactsIcon />} />;
        }

        if (contacts.length === 0 && search?.length) {
            return <EmptyList text={t('contacts.no-results')} icon={<ContactsIcon />} />;
        }

        const startIndex = (currentPage - 1) * itemsPerPage;
        const paginatedContacts = contacts.slice(startIndex, startIndex + itemsPerPage);

        return (
            <List dense={true}>
                {paginatedContacts.map(contact => {
                    return <ContactListItem contact={contact} key={contact._id} />;
                })}
            </List>
        );
    };

    const view = useMemo(getView, [contacts, search, currentPage]);

    return (
        <Grid container spacing={2}>
            {typeof onSearch !== 'undefined' && (
                <Grid item xs={12}>
                    <Box sx={{ mt: 1 }}>
                        <TextField
                            onChange={event => handleSearch(event.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            fullWidth
                            size="small"
                            label={t('general.search')}
                            placeholder={t('general.search-placeholder')}
                            variant="outlined"
                        />
                    </Box>
                </Grid>
            )}
            <Grid item xs={12}>
                <Fade in={true} timeout={120}>
                    <div>
                        {view}
                        {totalPages > 1 && (
                            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                                <Pagination
                                    count={totalPages}
                                    page={currentPage}
                                    onChange={handlePageChange}
                                    color="primary"
                                    siblingCount={1}
                                    boundaryCount={1}
                                    shape="rounded"
                                    showFirstButton
                                    showLastButton
                                />
                            </Box>
                        )}
                        {totalPages > 1 && (
                            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
                                <Typography variant="body2" color="textSecondary">
                                    page {currentPage} of {totalPages}
                                </Typography>
                            </Box>
                        )}
                    </div>
                </Fade>
            </Grid>
        </Grid>
    );
};

export default ContactList;
