import { createSelector } from 'reselect';
import { createSelectAvailableFranchisees } from './createSelectAvailableFranchisees';
import { createSelectIntersectingFranchisees } from './createSelectIntersectingFranchisees';

export const createSelectCreateCustomerData = createSelector(
    [createSelectAvailableFranchisees, createSelectIntersectingFranchisees],
    (availableFranchisees, intersectingFranchisees) => {
        return {
            availableFranchisees,
            intersectingFranchisees,
        };
    }
);
