import PlaceIcon from '@mui/icons-material/Place';
import SearchIcon from '@mui/icons-material/SearchRounded';
import type { AutocompleteProps, PaperProps } from '@mui/material';
import { Autocomplete, InputAdornment, ListItem, ListItemIcon, ListItemText, Paper, TextField } from '@mui/material';
import type { CustomControlProps } from '@nexdynamic/nex-ui-react';
import { getPaint, useDebounce } from '@nexdynamic/nex-ui-react';
import type { Location } from '@nexdynamic/squeegee-common';
import type { GeoJSONSource, Map } from 'maplibre-gl';
import { enqueueSnackbar } from 'notistack';
import type React from 'react';
import { useEffect, useState } from 'react';
import useTranslation from '../../../../ReactUI/hooks/useTranslation';
import { LocationApi } from '../../../../Server/LocationApi';

type Props = {
    onSelect?: (map: Map, location: Location | null) => void;
    slotProps?: Partial<{
        paper: PaperProps;
        autocomplete: AutocompleteProps<Location, false, false, true>;
    }>;
};

export const AddressLookupControl: React.FC<CustomControlProps<Props>> = ({ onSelect, map, slotProps }) => {
    const { t } = useTranslation();
    const [options, setOptions] = useState<Array<Location>>([]);
    const [selectedOption, setSelectedOption] = useState<Location | null>(null);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    const debouncedSearchTerm = useDebounce(searchTerm, 250);

    useEffect(() => {
        if (!debouncedSearchTerm) {
            setOptions([]);
            return;
        }

        const getOptions = async () => {
            setLoading(true);
            const addresses = await LocationApi.getAddresses(debouncedSearchTerm);
            setOptions(addresses);
            setLoading(false);
        };

        getOptions();
    }, [debouncedSearchTerm]);

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        if (value) setLoading(true);
        else setLoading(false);
        setSearchTerm(value);
    };

    const handleSelect = async (_event: React.ChangeEvent<HTMLInputElement>, option: Location) => {
        console.log(option);
        if (!map) return;

        if (!option) {
            setSelectedOption(null);
            return;
        }

        if (!option?.addressId) {
            enqueueSnackbar(t('address-picker.error-getting-details'), { variant: 'error' });
            setSelectedOption(null);
            return;
        }

        if (!option.lngLat) {
            const location = await LocationApi.getDetailsForGetAddressId(option?.addressId);
            if (!location || !location.lngLat) {
                enqueueSnackbar(t('address-picker.error-getting-details'), { variant: 'error' });
                return;
            }
            option = location;
        }

        setSelectedOption(option);

        map.flyTo({ center: option.lngLat, zoom: 18, essential: true });

        onSelect?.(map, option || null);
    };

    useEffect(() => {
        if (!map) return;

        const existingSelectedAddressLayer = map.getLayer('selected-address');
        const existingSelectedAddressSource = map.getSource<GeoJSONSource>('selected-address');

        if (selectedOption?.lngLat) {
            if (existingSelectedAddressLayer) map.removeLayer('selected-address');
            if (existingSelectedAddressSource) map.removeSource('selected-address');

            map.addSource('selected-address', {
                type: 'geojson',
                data: {
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: selectedOption.lngLat,
                    },
                    properties: {
                        title: selectedOption.addressDescription,
                    },
                },
            });

            map.addLayer({
                id: 'selected-address',
                type: 'circle',
                source: 'selected-address',
                paint: getPaint({ style: { color: '#ff0000', opacity: 1 } }),
            });
        } else {
            if (existingSelectedAddressLayer) map.removeLayer('selected-address');
            if (existingSelectedAddressSource) map.removeSource('selected-address');
        }
    }, [selectedOption]);

    return (
        <Paper sx={{ minWidth: 300, ...slotProps?.paper?.sx }} {...slotProps?.paper}>
            <Autocomplete
                options={options}
                getOptionLabel={option => {
                    if (typeof option === 'string') return option;
                    return option.addressDescription || '';
                }}
                filterOptions={x => x}
                renderInput={params => (
                    <TextField
                        {...params}
                        placeholder="Search for an address..."
                        onChange={handleSearch}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {params.InputProps.endAdornment ? (
                                        params.InputProps.endAdornment
                                    ) : (
                                        <InputAdornment position="end">
                                            <SearchIcon />
                                        </InputAdornment>
                                    )}
                                </>
                            ),
                        }}
                    />
                )}
                renderOption={(props, option) => {
                    return (
                        <ListItem {...props}>
                            <ListItemIcon>
                                <PlaceIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={typeof option === 'string' ? option : option.addressDescription?.split(',')[0]}
                                secondary={typeof option === 'string' ? option : option.addressDescription?.split(',').slice(1).join(',')}
                            />
                        </ListItem>
                    );
                }}
                loading={loading}
                onChange={handleSelect}
                size="small"
                freeSolo
                {...slotProps?.autocomplete}
            />
        </Paper>
    );
};
