import { activationStrategy } from 'aurelia-router';
import { ImportTransactionsApp } from './ImportTransactionsView';

export class ImportTransactionsModule {
    determineActivationStrategy() {
        return activationStrategy.replace;
    }

    module: ReturnType<typeof ImportTransactionsApp>;

    attached() {
        const container = document.getElementById('import-transactions-root');
        if (!container) throw new Error('module root element not found');
        this.module = ImportTransactionsApp(container);
    }

    detached() {
        this.module?.unmount();
    }
}
