import { Box } from '@mui/material';
import { ListPagination, getPagingQueryVariables } from '@nexdynamic/nex-ui-react';
import type { Campaign } from '@nexdynamic/squeegee-common';
import { useState } from 'react';
import ContactList from '../../contacts/components/ContactList';
import { campaignGetContacts } from '../CampaignService';

type CampaignContactListProps = {
    campaign: Campaign;
};

const CONTACTS_PER_PAGE = 15;

const CampaignContactList = ({ campaign }: CampaignContactListProps) => {
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState<string>('');

    const paging = getPagingQueryVariables(page, CONTACTS_PER_PAGE);

    const contacts = campaignGetContacts(campaign._id, paging, search);

    const onChange = (newPage: number) => {
        setPage(newPage);
    };

    const pagingProps = { count: campaign.contacts?.length || 0, take: CONTACTS_PER_PAGE, page, onChange };

    return (
        <>
            <ContactList onSearch={setSearch} contacts={contacts} />
            <Box sx={{ display: 'flex', justifyContent: 'center', py: 2 }}>
                <ListPagination {...pagingProps} />
            </Box>
        </>
    );
};

export default CampaignContactList;
