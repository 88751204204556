import { activationStrategy } from 'aurelia-router';
import { FranchiseMap } from './FranchiseMap';
export class FranchiseMapModule {
    determineActivationStrategy() {
        return activationStrategy.replace;
    }

    franchiseMap: ReturnType<typeof FranchiseMap>;

    attached() {
        const container = document.getElementById('franchise-map-root');
        if (!container) throw new Error('franchise-map-root element not found');
        this.franchiseMap = FranchiseMap(container);
    }

    detached() {
        this.franchiseMap?.unmount();
    }
}
