import type { SettingIds, TranslationKey } from '@nexdynamic/squeegee-common';
import type { Subscription } from 'aurelia-event-aggregator';
import { bindable, computedFrom } from 'aurelia-framework';
import { ApplicationState } from '../../ApplicationState';
import { prompt } from '../../Dialogs/ReactDialogProvider';
import { StateFlagsChangedEvent } from '../../Events/StateFlagsChangedEvent';
import { t } from '../../t';

export class ToggleSettingCustomElement {
    @bindable settingId: SettingIds;
    @bindable default = false;
    @bindable toggled?: (enabled: boolean) => void | boolean | Promise<void | boolean>;
    @bindable type: 'standard' | 'new' | 'beta' | 'private-beta';
    protected userToggleable: boolean;
    protected tag?: string;
    protected tagTitle: string;
    name: string;

    description: string;
    private _enabled: boolean;
    private _devModeSubscription: Subscription;
    attached() {
        this.userToggleable = this.getUserToggleable();
        this.tag = !this.type || this.type === 'standard' ? '' : this.type;
        this.tagTitle = this.getTagTitle();
        this._enabled = ApplicationState.getSetting<boolean>(this.settingId, this.default);
        this.name = ApplicationState.localise(`settings.${this.settingId.replace(/\./g, '-')}` as TranslationKey);
        this.description = this._getDesc(this._enabled);

        this._devModeSubscription = StateFlagsChangedEvent.subscribe(() => (this.userToggleable = this.getUserToggleable()));
    }

    private getUserToggleable() {
        return ApplicationState.stateFlags.devMode || !this.type || this.type === 'standard' || this.type === 'new' || this.type === 'beta';
    }

    protected checkWhetherToEnable = async (isToggle: boolean) => {
        if (isToggle && this._enabled) return true;

        let title: TranslationKey;
        let message: TranslationKey;
        switch (this.tag) {
            case 'new':
                title = 'toggle-setting.new-tag-prompt';
                message = 'toggle-setting.new-tag-title';
                break;
            case 'beta':
                title = 'toggle-setting.beta-tag-prompt';
                message = 'toggle-setting.beta-tag-title';
                break;
            case 'private-beta':
                title = 'toggle-setting.private-beta-tag-prompt';
                message = 'toggle-setting.private-beta-tag-title';
                break;
            default:
                return true;
        }

        const check = prompt(title, message, {
            okLabel: isToggle ? 'toggle-setting.enable' : 'general.ok',
            cancelLabel: isToggle ? 'general.cancel' : '',
            allowClickOff: true,
        });

        const answer = await check.show();

        return !check.cancelled && answer;
    };

    private getTagTitle() {
        switch (this.type) {
            case 'new':
                return t('toggle-setting.new-tag-title');
            case 'beta':
                return t('toggle-setting.beta-tag-title');
            case 'private-beta':
                return t('toggle-setting.private-beta-tag-title');
            default:
                return '';
        }
    }

    @computedFrom('_enabled')
    protected get toggle() {
        return this._enabled;
    }

    protected set toggle(enabled: boolean) {
        this.checkWhetherToEnable(true).then(shouldEnable => {
            if (!shouldEnable) return;
            this._toggle(enabled);
        });
    }

    protected triggerToggle(event: Event) {
        event.stopPropagation();
        this.toggle = !this._enabled;
    }

    protected async _toggle(enabled: boolean) {
        this._enabled = enabled;
        this.description = this._getDesc(enabled);
        ApplicationState.setSetting(this.settingId, enabled);
        const toggled = await this.toggled?.(enabled);
        const undo = toggled === false;
        if (!undo) return;

        this._enabled = !enabled;
        this.description = this._getDesc(this._enabled);
        ApplicationState.setSetting(this.settingId, this._enabled);
    }

    private _getDesc(checked: boolean) {
        return ApplicationState.localise(
            `settings.${this.settingId.replace(/\./g, '-')}-description-${checked ? 'on' : 'off'}` as TranslationKey
        );
    }

    detached() {
        this._devModeSubscription?.dispose();
    }
}
