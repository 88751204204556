import type { AccountUser, Customer, Job, JobGroup, JobOccurrence, StoredObject, Tag, Transaction } from '@nexdynamic/squeegee-common';
import {
    CommonApplicationState,
    Location,
    SyncMode,
    TagType,
    getRandomAddress,
    getRandomName,
    notNullUndefinedEmptyOrZero,
    wait,
} from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../ApplicationState';
import { LoaderEvent } from '../Events/LoaderEvent';
import { Logger } from '../Logger';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import { Utilities } from '../Utilities';
import { Data } from './Data';

export class BackupImporter {
    public static async restore(
        backup: Array<StoredObject>,
        clearLocalStorage = true,
        ignoreOwner = false,
        deleteExistingData = true,
        anonymise = false
    ) {
        if (!BackupImporter.backupIsValid(backup, ignoreOwner))
            throw new Error(
                'The backup file is not valid, please ensure that you are restoring valid data and that the file is not corrupt.'
            );

        backup = backup.filter(x => !x._deleted);

        Data.fullSyncEnabled = false;
        try {
            new LoaderEvent(true);
            deleteExistingData && (await Data.deleteAllData(false));
            clearLocalStorage && (await Data.clearLocalStorage());

            if (anonymise) await BackupImporter.anonymise(backup);

            new LoaderEvent(false);

            if (ignoreOwner) {
                for (const item of backup) {
                    item.ownerEmail = ApplicationState.dataEmail;
                }
            }
            const valid = backup.filter(
                storedObject =>
                    storedObject._id !== CommonApplicationState.APPLICATION_STATE_ID &&
                    (!storedObject.ownerEmail || storedObject.ownerEmail === ApplicationState.dataEmail)
            );

            let stored = 0;
            while (valid.length) {
                await Data.put(valid.splice(0, 200));
                stored += 200;
                new LoaderEvent(true, undefined, 'loader.items-stored', undefined, { stored: stored.toString() });
                await wait(1);
            }
        } catch (error) {
            Logger.error('Failed to restore backup');
            new NotifyUserMessage('error.failed-to-complete-import-contact-support');
        } finally {
            Data.fullSyncEnabled = true;
            new LoaderEvent(false);
        }
        Data.fullSync(SyncMode.Partial);
    }
    private static async anonymise(backup: StoredObject[]) {
        const tags = backup.filter(r => r.resourceType === 'tags') as Array<Tag>;
        for (const tag of tags) {
            switch (tag.type) {
                case TagType.ROUND:
                    tag.description = getRandomAddress();
                    tag.longDescription = '';
                    break;
                case TagType.SERVICE:
                    break;
                case TagType.EXPENSE_CATEGORY:
                    tag.description = 'Category ' + Utilities.randomInteger(100, 1000);
                    tag.longDescription = '';
                    break;
                default:
                    tag.description = 'Some Tag ' + Utilities.randomInteger(100, 1000);
                    tag.longDescription = '';
                    break;
            }
        }
        new LoaderEvent(true, undefined, 'loader.processed-tags');
        await wait(50);

        const jobs: { [id: string]: Job } = {};
        const customers: { [id: string]: Customer } = {};

        let count = backup.length;
        while (count--) {
            const item = backup[count];
            if ((backup.length - count) % 100 === 0) {
                new LoaderEvent(true, undefined, 'loader.backup-length-objects-anonymised', undefined, {
                    count: (backup.length - count).toString(),
                });
                await wait(1);
            }
            switch (item.resourceType) {
                case 'alerts':
                case 'eventsnapshots':
                case 'applicationstate':
                case 'opennames':
                case 'accountuser':
                    {
                        const user = item as AccountUser;
                        user.email = `${Utilities.randomInteger(100000, 999999).toString()}@anonsqueegeeuser.com`;
                        user.name = getRandomName();
                    }
                    break;
                case 'team':
                case 'log':
                case 'settings':
                case 'signatures':
                case 'franchisee':
                case 'contact':
                case 'occurrencesbyday':
                case 'notifications':
                    backup.splice(count, 1);
                    break;
                case 'customers': {
                    const customer = item as Customer;
                    customers[customer._id] = customer;
                    const customerAddress = (customer.address && customer.address.addressDescription) || '';
                    customer.email = '';
                    customer.telephoneNumber = '';
                    customer.telephoneNumberOther = '';
                    customer.address && (customer.address.addressDescription = getRandomAddress());
                    customer.name = getRandomName();
                    customer.paymentProviderMetaData = {};
                    customer.notes = '';
                    customer.invoiceNotes = '';
                    customer._externalId = '';
                    customer.__auth = undefined;
                    customer.automaticPaymentMethod = undefined;

                    for (const jobId in customer.jobs || {}) {
                        const job = customer.jobs[jobId];
                        jobs[jobId] = job;
                        job._externalId = '';

                        job.description = '';
                        job.invoiceNotes = '';
                        if (job.location && job.location.addressDescription === customerAddress) {
                            job.location.addressDescription = (customer.address && customer.address.addressDescription) || '';
                        } else {
                            job.location && (job.location.addressDescription = getRandomAddress());
                        }

                        for (const round of job.rounds || []) {
                            job.rounds[job.rounds.indexOf(round)] = tags.find(tag => tag._id === round._id) as JobGroup;
                        }
                        job.rounds = (job.rounds || []).filter(r => !!r);
                        for (const service of job.services || []) {
                            job.services[job.services.indexOf(service)] = tags.find(tag => tag._id === service._id) as Tag;
                        }
                        job.services = (job.services || []).filter(s => !!s);
                    }
                    await wait(1);
                    break;
                }
            }
        }
        await wait(50);

        const occurrences = backup.filter(o => o.resourceType === 'joboccurrences') as Array<JobOccurrence>;

        for (const occurrence of occurrences) {
            if (occurrences.indexOf(occurrence) % 100 === 0) {
                new LoaderEvent(true, undefined, 'loader.number-of-jobs-job-occurrences-processed', undefined, {
                    numberOfJobs: (occurrences.indexOf(occurrence) + 1).toString(),
                });
                await wait(1);
            }
            occurrence._externalId = '';

            occurrence.description = '';
            occurrence.location = (jobs[occurrence.jobId] && jobs[occurrence.jobId].location) || new Location();

            if (occurrence.rounds) {
                for (const round of occurrence.rounds?.filter(notNullUndefinedEmptyOrZero) || []) {
                    occurrence.rounds[occurrence.rounds.indexOf(round)] = tags.find(tag => tag._id === round._id) as JobGroup;
                }
                occurrence.rounds = (occurrence.rounds || []).filter(notNullUndefinedEmptyOrZero);
            }

            for (const service of occurrence.services || []) {
                occurrence.services[occurrence.services.indexOf(service)] = tags.find(tag => tag._id === service._id) as Tag;
            }
            occurrence.services = (occurrence.services || []).filter(s => !!s);
        }

        new LoaderEvent(true, undefined, 'loader.job-occurrences-all-processed');
        await wait(50);

        const transactions = backup.filter(t => t.resourceType === 'transactions') as Array<Transaction>;

        for (const transaction of transactions) {
            if (transactions.indexOf(transaction) % 100 === 0) {
                new LoaderEvent(true, undefined, 'loader.transactions-processed', undefined, {
                    transactions: (transactions.indexOf(transaction) + 1).toString(),
                });
                await wait(1);
            }
            transaction.description = transaction.transactionSubType || '';
            transaction.paymentDetails && (transaction.paymentDetails.mandateId = '');
            if (transaction.invoice) {
                transaction.invoice.billTo =
                    (transaction.customerId &&
                        customers[transaction.customerId] &&
                        customers[transaction.customerId].address &&
                        customers[transaction.customerId].address.addressDescription) ||
                    '';
                transaction.invoice.notes = '';
                transaction.invoice.paymentReference = '';
            }
        }

        new LoaderEvent(true, undefined, 'loader.transactions-all-processed');
        await wait(50);
    }

    private static backupIsValid(backup: Array<StoredObject>, ignoreOwner = false) {
        const invalid = backup.filter(storedObject => {
            return (
                storedObject._id !== CommonApplicationState.APPLICATION_STATE_ID &&
                !ignoreOwner &&
                storedObject.ownerEmail &&
                storedObject.ownerEmail !== ApplicationState.dataEmail
            );
        });
        if (invalid.length)
            Logger.error('User attempted to restore objects that did not belong to them.', {
                dataEmail: ApplicationState.dataEmail,
                invalidIds: invalid.map(x => x._id),
            });
        return invalid.length === 0;
    }
}
