import { Box, Button, TextField, Typography } from '@mui/material';
import { DialogPaper, DialogRoot } from '@nexdynamic/nex-ui-react';
import type { UtmParams } from '@nexdynamic/squeegee-portal-common';
import React from 'react';
import type {
    AureliaReactComponentDialogComponent,
    AureliaReactComponentDialogComponentStandardProps,
} from '../../../Dialogs/AureliaReactComponentDialogComponent';
import useTranslation from '../../hooks/useTranslation';

export type UtmParamsDialogOptions = {
    utmParams?: UtmParams;
};

type Props = AureliaReactComponentDialogComponentStandardProps<UtmParams> & UtmParamsDialogOptions;

const UtmParamsDialog: AureliaReactComponentDialogComponent<UtmParamsDialogOptions> = ({ utmParams, okCallback }: Props) => {
    const [utmId, setUtmId] = React.useState(utmParams?.utm_id || '');
    const [utmSource, setUtmSource] = React.useState(utmParams?.utm_source || '');
    const [utmMedium, setUtmMedium] = React.useState(utmParams?.utm_medium || '');
    const [utmCampaign, setUtmCampaign] = React.useState(utmParams?.utm_campaign || '');
    const [utmTerm, setUtmTerm] = React.useState(utmParams?.utm_term || '');
    const [utmContent, setUtmContent] = React.useState(utmParams?.utm_content || '');

    const handleSave = () => {
        const updatedUtmParams: UtmParams = {
            utm_id: utmId,
            utm_source: utmSource,
            utm_medium: utmMedium,
            utm_campaign: utmCampaign,
            utm_term: utmTerm,
            utm_content: utmContent,
        };
        okCallback(updatedUtmParams);
    };

    const { t } = useTranslation();

    return (
        <DialogRoot>
            <DialogPaper sx={{ p: 2 }}>
                <Typography variant="h6" gutterBottom>
                    {t('customer-dialog.utm-params-title')}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                    Here you can view and edit the UTM parameters associated with this customer. These parameters help track the
                    effectiveness of marketing campaigns.
                </Typography>

                <Box sx={{ mt: 2 }}>
                    <Typography variant="subtitle1" gutterBottom>
                        {t('customer-dialog.utm-params-id-title')}
                    </Typography>
                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="utm_id"
                        value={utmId}
                        onChange={e => setUtmId(e.target.value)}
                        helperText={t('customer-dialog.utm-params-id-description')}
                    />
                </Box>

                <Box sx={{ mt: 2 }}>
                    <Typography variant="subtitle1" gutterBottom>
                        {t('customer-dialog.utm-params-source-title')}
                    </Typography>
                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="utm_source"
                        value={utmSource}
                        onChange={e => setUtmSource(e.target.value)}
                        helperText={t('customer-dialog.utm-params-source-description')}
                    />
                </Box>

                <Box sx={{ mt: 2 }}>
                    <Typography variant="subtitle1" gutterBottom>
                        {t('customer-dialog.utm-params-medium-title')}
                    </Typography>
                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="utm_medium"
                        value={utmMedium}
                        onChange={e => setUtmMedium(e.target.value)}
                        helperText={t('customer-dialog.utm-params-medium-description')}
                    />
                </Box>

                <Box sx={{ mt: 2 }}>
                    <Typography variant="subtitle1" gutterBottom>
                        {t('customer-dialog.utm-params-campaign-title')}
                    </Typography>
                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="utm_campaign"
                        value={utmCampaign}
                        onChange={e => setUtmCampaign(e.target.value)}
                        helperText={t('customer-dialog.utm-params-campaign-description')}
                    />
                </Box>

                <Box sx={{ mt: 2 }}>
                    <Typography variant="subtitle1" gutterBottom>
                        {t('customer-dialog.utm-params-term-title')}
                    </Typography>
                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="utm_term"
                        value={utmTerm}
                        onChange={e => setUtmTerm(e.target.value)}
                        helperText={t('customer-dialog.utm-params-term-description')}
                    />
                </Box>

                <Box sx={{ mt: 2 }}>
                    <Typography variant="subtitle1" gutterBottom>
                        {t('customer-dialog.utm-params-content-title')}
                    </Typography>
                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="utm_content"
                        value={utmContent}
                        onChange={e => setUtmContent(e.target.value)}
                        helperText={t('customer-dialog.utm-params-content-description')}
                    />
                </Box>

                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSave}
                        disabled={
                            utmSource === (utmParams?.utm_source || '') &&
                            utmMedium === (utmParams?.utm_medium || '') &&
                            utmCampaign === (utmParams?.utm_campaign || '') &&
                            utmTerm === (utmParams?.utm_term || '') &&
                            utmContent === (utmParams?.utm_content || '')
                        }
                    >
                        {t('general.save')}
                    </Button>
                </Box>
            </DialogPaper>
        </DialogRoot>
    );
};

export default UtmParamsDialog;
