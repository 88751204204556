import MagicIcon from '@mui/icons-material/AutoAwesome';
import { Alert, Box, Button, ListItemText, Stack, Typography } from '@mui/material';
import { useMap } from '@nexdynamic/nex-ui-react';
import type { FranchiseeAreaGeoJsonFeatureCollection, LngLat } from '@nexdynamic/squeegee-common';
import { area, bbox, convertArea } from '@turf/turf';
import type React from 'react';
import { useState } from 'react';
import { ApplicationState } from '../../../../../ApplicationState';
import { prompt } from '../../../../../Dialogs/ReactDialogProvider';
import { Utilities } from '../../../../../Utilities';
import useStateFlags from '../../../../hooks/useStateFlags';
import useTranslation from '../../../../hooks/useTranslation';
import ColorPicker from '../../../../portal/ThemeEditor/components/ColorPicker';
import { useBoundFranchiseMapStore } from '../../../stores/boundFranchiseMapStore';
import { createSelectFranchiseeToMarkersOutsideAreaLookup } from '../../../stores/franchiseMapSlice/selectors/createSelectFranchiseeToJobsOutsideAreaLookup';
import { FranchiseeItemActionMenu } from './FranchiseeItemActionMenu';

export type FranchiseeItemProps = {
    franchiseeId: string;
    franchiseeName: string;
    franchiseeOwnerEmail: string;
    franchiseeTotalCustomers: number;
    color: string;
    areaGeoJson: FranchiseeAreaGeoJsonFeatureCollection;
    openEditPreviewDialog: (open: boolean) => void;
};

export const FranchiseeItem: React.FC<FranchiseeItemProps> = ({
    franchiseeId,
    franchiseeName,
    franchiseeOwnerEmail,
    franchiseeTotalCustomers,
    color,
    areaGeoJson,
    openEditPreviewDialog,
}) => {
    const { devMode } = useStateFlags();
    const { t } = useTranslation();

    // as we've refactored franchisee areaGeoJson into a feature collection
    // we need to access the first feature to get the properties object
    const { total: totalJobs, outsideArea: jobsOutsideArea } = areaGeoJson.features[0].properties.jobCountData ?? {
        total: 0,
        outsideArea: 0,
    };
    const franchiseeIdToJobOutSideAreaLngLats = useBoundFranchiseMapStore(createSelectFranchiseeToMarkersOutsideAreaLookup);
    const {
        getExpandedArea,
        editFranchiseeArea,
        setFranchiseeColorPreview,
        setFranchiseeColorSave,
        deselectFranchisee,
        regenerateArea,
        franchiseeIdsUserHasCreatorOrAdminRolesIn,
        isFranchiseOwner,
    } = useBoundFranchiseMapStore();

    const map = useMap();

    const areaInSquareMiles = convertArea(area(areaGeoJson), 'meters', ApplicationState.distanceUnits).toLocaleString(undefined, {
        maximumFractionDigits: 2,
        notation: 'compact',
    });

    // const [colorToSave, setColorToSave] = useState<string | null>(null);
    const [colorPickerAnchorPos, setColorPickerAnchorPos] = useState<{ top: number; left: number } | undefined>(undefined);

    const handleColorPickerClick = (event: React.MouseEvent<HTMLElement>) => {
        setColorPickerAnchorPos({ top: event.clientY, left: event.clientX });
    };

    const handleSwitchToFranchisee = () => {
        Utilities.goToRootUrl({
            applicationRouteWithLeadingSlash: `/franchise-map`,
            queryStringParams: { dataEmail: franchiseeOwnerEmail },
        });
    };

    const handleExtendFranchiseeArea = (franchiseeId: string, points: Array<LngLat>) => {
        getExpandedArea({ currentAreaPolygonFeature: areaGeoJson, franchiseeId, pointsToInclude: points });
        openEditPreviewDialog(true);
    };

    const handleCommitColor = async () => {
        await setFranchiseeColorSave({ franchiseeId, color });
        setColorPickerAnchorPos(undefined);
    };

    const handleColorChange = (newColor: string) => {
        if (newColor && color !== newColor) setFranchiseeColorPreview({ franchiseeId, color: newColor });
    };

    const handleZoomTo = () => {
        const bounds = bbox(areaGeoJson);
        if (map && bounds) {
            map.fitBounds(
                [
                    [bounds[0], bounds[1]],
                    [bounds[2], bounds[3]],
                ],
                { padding: 100 }
            );
        }
    };

    const handleRegenerateArea = async (franchiseeId: string) => {
        const alert = prompt('franchise-map.regenerate-area-warning-title', 'franchise-map.regenerate-area-warning-description', {
            okLabel: 'franchise-map.regenerate',
        });

        await alert.show();

        if (alert.cancelled) return;

        regenerateArea(franchiseeId);
    };

    const handleDeselect = () => {
        deselectFranchisee(franchiseeId);
    };

    const switchToFranchiseeDisabled =
        (!isFranchiseOwner ||
            !(franchiseeIdsUserHasCreatorOrAdminRolesIn && franchiseeIdsUserHasCreatorOrAdminRolesIn.includes(franchiseeId))) ??
        true;

    return (
        <Stack spacing={2} width="100%" key={franchiseeId}>
            <Stack direction="row" spacing={1}>
                <Box
                    sx={{
                        'width': 12,
                        'flex': '0 0 auto',
                        'borderRadius': '8px',
                        'backgroundColor': color,
                        ':hover': {
                            cursor: 'pointer',
                            width: 24,
                        },
                        'transition': 'all 0.2s',
                    }}
                    onClick={handleColorPickerClick}
                />

                <ListItemText
                    primary={franchiseeName}
                    secondary={franchiseeOwnerEmail}
                    primaryTypographyProps={{ variant: 'h6', fontWeight: 'normal' }}
                />

                <FranchiseeItemActionMenu
                    onSwitchToFranchisee={handleSwitchToFranchisee}
                    onEditArea={() => editFranchiseeArea(franchiseeId, areaGeoJson)}
                    onChangeColor={handleColorPickerClick}
                    onZoomTo={handleZoomTo}
                    onRegenerateArea={() => handleRegenerateArea(franchiseeId)}
                    onDeselect={handleDeselect}
                    switchToFranchiseeDisabled={switchToFranchiseeDisabled}
                />
            </Stack>
            {devMode && <Typography variant="caption">({franchiseeId})</Typography>}

            <Stack direction="row" spacing={1} justifyContent="space-around">
                <Box>
                    <Typography variant="body2" color="text.secondary">
                        {t('franchise-map.stat-jobs')}
                    </Typography>

                    <Typography variant="h5">{totalJobs?.toString().padStart(2, '0')}</Typography>
                </Box>

                <Box>
                    <Typography variant="body2" color="text.secondary">
                        {t('franchise-map.stat-customers')}
                    </Typography>

                    <Typography variant="h5">{franchiseeTotalCustomers.toString().padStart(2, '0')}</Typography>
                </Box>

                <Box>
                    <Typography variant="body2" color="text.secondary">
                        {t('franchise-map.stat-area', { unit: ApplicationState.distanceUnits === 'miles' ? 'mi' : 'km' })}
                    </Typography>

                    <Typography variant="h5">{areaInSquareMiles}</Typography>
                </Box>
            </Stack>

            {jobsOutsideArea ? (
                <Stack spacing={2}>
                    <Alert variant="filled" severity="warning">
                        There are {jobsOutsideArea} jobs are outside of the selected franchisee area
                    </Alert>

                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handleExtendFranchiseeArea(franchiseeId, franchiseeIdToJobOutSideAreaLngLats[franchiseeId] ?? [])}
                        endIcon={<MagicIcon />}
                    >
                        {t('franchise-map.actions-extend-area')}
                    </Button>
                </Stack>
            ) : null}

            <ColorPicker
                colorKey="franchiseeColor"
                defaultColor={color}
                anchorPos={colorPickerAnchorPos}
                updatePalette={handleColorChange}
                onCloseColorPicker={handleCommitColor}
            />
        </Stack>
    );
};
