import type { LayerConfig, LayerGroup, PolygonLayerConfig } from '@nexdynamic/nex-ui-react';
import type { Dic } from '@nexdynamic/nex-ui-react/src/components/mapsV2/types/Dic';
import type {
    FranchiseeAreaData,
    FranchiseeAreaGeoJsonFeatureCollection,
    FranchiseeMarkerGeoJsonFeature,
} from '@nexdynamic/squeegee-common';

type Props = {
    franchiseeAreas: Array<FranchiseeAreaData>;
    candidateArea: FranchiseeAreaGeoJsonFeatureCollection | null;
    selectedAreaIds?: string[];
    selectedMarkerIds?: string[];
    layersAreDisabled?: boolean;
};
export const getFranchiseeAreaLayers = ({
    franchiseeAreas,
    candidateArea,
    selectedAreaIds = [],
    selectedMarkerIds = [],
    layersAreDisabled,
}: Props) => {
    const layers = new Array<LayerConfig>();
    const layerGroupsDic: Dic<LayerGroup> = {};
    for (const area of franchiseeAreas) {
        const isThisFranchiseeSelected = selectedAreaIds?.some(id => id === area.franchiseeId) ?? false;
        const areaLayerId = `${area.franchiseeId}-area`;
        const polygonLayer: PolygonLayerConfig = {
            id: areaLayerId,
            type: 'polygon',
            label: area.franchiseeName,
            data: area.areaGeoJson,
            style: {
                color: area.color,
                opacity: 0.5,
                // applyHoverStyle: true,
            },
            isSelected: isThisFranchiseeSelected,
            disabled: (!isThisFranchiseeSelected && !!selectedAreaIds?.length) || layersAreDisabled,
        };

        layers.push(polygonLayer);

        layerGroupsDic[area.franchiseeId] = {
            label: area.franchiseeName,
            color: area.color,
            layerIds: [areaLayerId],
        };
        const markerFeatures = new Array<FranchiseeMarkerGeoJsonFeature>();

        Object.entries(area.markers).forEach(([customerMarkerId, customerJobMarker]) => {
            markerFeatures.push({
                type: 'Feature',
                properties: {
                    ...customerJobMarker,
                    selected: selectedMarkerIds?.some(id => id === customerMarkerId) ?? false,
                },
                geometry: {
                    type: 'Point',
                    coordinates: customerJobMarker.lngLat,
                },
            });
        });
        const markerLayerId = `${area.franchiseeId}-markers`;
        layers.push({
            id: markerLayerId,
            type: 'point',
            cluster: true,
            // TODO - Add defaults to cluster settings on MapV2
            clusterSettings: {
                maxZoom: 14,
                radius: 30,
            },
            data: {
                type: 'FeatureCollection',
                features: markerFeatures,
            },
            style: {
                color: area.color,
                opacity: 1,
            },
            disabled:
                (!isThisFranchiseeSelected && !!selectedAreaIds?.length) ||
                (layersAreDisabled && candidateArea?.features[0].properties.franchiseeId !== area.franchiseeId),
        });
        layerGroupsDic[area.franchiseeId].layerIds.push(markerLayerId);
    }

    return { layers, layerGroupsDic };
};
