import type { AccountUser, LngLat, TranslationKey } from '@nexdynamic/squeegee-common';
import { AlphanumericCharColourDictionary, JobOccurrenceStatus } from '@nexdynamic/squeegee-common';
import { EventAggregator } from 'aurelia-event-aggregator';
import { inject, transient } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { BindingSignaler } from 'aurelia-templating-resources';
import moment from 'moment';
import { ApplicationState } from '../../ApplicationState';
import { KEEP_OCCURRENCES_AFTER_X_WEEKS } from '../../Customers/ClientArchiveService';
import { DialogAnimation } from '../../Dialogs/DialogAnimation';
import { Prompt } from '../../Dialogs/Prompt';
import { ActionBarEvent } from '../../Events/ActionBarEvent';
import { LoaderEvent } from '../../Events/LoaderEvent';
import { DateFilterOption } from '../../Jobs/Filters/DateFilterOption';
import { Logger } from '../../Logger';
import { NotifyUserMessage } from '../../Notifications/NotifyUserMessage';
import { Stopwatch } from '../../Stopwatch';
import { AssignmentService } from '../../Users/Assignees/AssignmentService';
import type { Workload } from '../../Users/Workload';
import { Utilities } from '../../Utilities';
import { ScheduleDetailsDialog } from '../Components/ScheduleDetailsDialog';
import { ScheduleItem } from '../Components/ScheduleItem';
import { ScheduleService } from '../ScheduleService';
import { ScheduleView } from './ScheduleView';

class WeekData {
    weekDays: Array<string> = [];
    weekDayData: WeekDayData = new WeekDayData();
    totalPrice = '';
    totalTime = '';
    totalPriceVal = 0;
    totalTimeVal = 0;
    expanded = false;
    showPrice = false;
}

class WeekDayData {
    [day: string]: {
        label: string;
        price: number;
        showPrice: boolean;
        time: number;
        totalPrice: string;
        totalTime: string;
        items: Array<ScheduleItem>;
    };
}

@inject(EventAggregator, Router, BindingSignaler)
@transient()
export class MultiDayView extends ScheduleView {
    constructor(public eventAggregator: EventAggregator, public router: Router, public signaler: BindingSignaler) {
        super(eventAggregator, router, signaler);
        this.loadedWeeks.push(moment().startOf('isoWeek').format('YYYY-MM-DD'));
        this.chartMode = 'users';
    }
    public viewName = 'multiDay_';
    protected loadedWeeks: Array<string> = [];
    protected weeks: {
        [weekStarting: string]: WeekData;
    } = {};
    protected selectedScheduleItems: Array<ScheduleItem> = [];
    protected stickyWorkloadBar: boolean = ApplicationState.getSetting<boolean>('global.scheduling.sticky-multiview-workload', false);
    protected showDistanceMarkersBetweenJobs = !ApplicationState.hideDistanceMarkers;

    public selectableMode: boolean;
    public totalPrice: string;
    public totalTime: string;

    protected updateWeekData() {
        for (const weekStartIso of this.loadedWeeks) {
            const weekStart = moment(weekStartIso);

            let count = 7;

            const week = new WeekData();
            const weekId = weekStart.format('YYYY-MM-DD');
            while (count--) {
                const key = weekStart.format('YYYY-MM-DD');
                week.weekDays.push(key);
                const data = this.scheduleDataWide[key];
                let dataForDay = data ? Object.keys(data).map(key => data[key]) : [];
                if (this.selectedWorkLoad?.userOrTeam?._id === 'UNASSIGNED')
                    dataForDay = dataForDay.filter(x => AssignmentService.isUnassigned(x.occurrence));

                for (const item of Object.keys(data).map(key => data[key].job)) {
                    if (this._loadedJobIds.indexOf(item._id) === -1) this._loadedJobIds.push(item._id);
                }

                const activeUserEmailOrTeamId = this.activeUserEmailOrTeamId;

                const userFilter = activeUserEmailOrTeamId ? [activeUserEmailOrTeamId] : [];

                if (userFilter.length) dataForDay = dataForDay.filter(x => AssignmentService.isAssigned(x.occurrence, userFilter, true));

                dataForDay.sort((a, b) => {
                    return (a.plannedOrder || 0) - (b.plannedOrder || 0);
                });
                const prices = this.setPriceAndTimeOnItems(dataForDay);
                if (prices.showPrice) {
                    //if at least one day has pricing
                    week.showPrice = true;
                }
                week.weekDayData[key] = {
                    label: moment(key).format('ddd DD'),
                    price: prices.totalPrice,
                    showPrice: prices.showPrice && !this.viewOptions.hidePricesForPrivacy,
                    time: prices.totalTime,
                    totalPrice: prices.totalPriceVal,
                    totalTime: prices.totalTimeVal,
                    items: dataForDay,
                };

                weekStart.add(1, 'day');
            }

            this.weeks[weekId] = week;
            this.refreshWeekPrices(weekId);
        }
    }

    public async onRefresh() {
        new LoaderEvent(true);
        await this.updateWideDataForLoadedWeeks();
        await this.updateWeekData();
        await this.updateWorkloads();
        new LoaderEvent(false);
    }

    public async attached() {
        await super.attached();
        this.updateWeekData();
    }

    private refreshWeekPrices(weekStarting: string) {
        let weekTotalPrice = 0;
        let weekTotalTime = 0;
        const week = this.weeks[weekStarting];
        if (!week) return;
        for (const day in week.weekDayData) {
            weekTotalPrice += week.weekDayData[day].price;
            weekTotalTime += week.weekDayData[day].time;
        }
        week.totalPriceVal = weekTotalPrice;
        week.totalTimeVal = weekTotalTime;
        week.totalPrice = weekTotalPrice.toFixed(2).toString();
        if (!weekTotalTime) this.totalTime = '';
        else {
            const hours = Math.floor(weekTotalTime / 60);
            const minutes = weekTotalTime % 60;
            week.totalTime = '| ' + hours.toString() + 'h ' + (minutes ? minutes.toString() + 'm' : '') + ' ';
        }
    }

    protected multiUserEnabled = ApplicationState.multiUserEnabled;
    private static _colours = new AlphanumericCharColourDictionary();
    protected getAvatarTextAndColour(scheduleItem: ScheduleItem): { text: string; colour: string } {
        const assignee = scheduleItem.assignees?.[0];
        if (!assignee) return { text: '', colour: '' };
        const avatar = assignee.avatar;
        if (avatar || !ApplicationState.multiUserEnabled) return { text: '', colour: '' };

        let avatarText: string;
        if (scheduleItem.customer && assignee.name && assignee.name.trim().length > 0) {
            avatarText = Utilities.getAvatarTextFromName(assignee.name);
        } else {
            avatarText = '';
        }
        const avatarColour = scheduleItem.roundName
            ? MultiDayView._colours[scheduleItem.roundName.substring(0, 1).toUpperCase()]
            : MultiDayView._colours[avatarText.substring(0, 1).toUpperCase()];

        return { text: avatarText, colour: avatarColour || MultiDayView._colours.default };
    }

    protected getAvatarColour(text: string) {
        return `#${Utilities.hexFromString(text)}`;
    }

    protected getAssigneeBackground(scheduleItem: ScheduleItem) {
        const assignee = scheduleItem.assignees?.[0];
        if (!assignee) return;
        const avatar = assignee.avatar;
        if (avatar) {
            return `border-color: ${this.getAvatarColour(assignee.name)}; background-image:url(${avatar})`;
        }
        const backgroundColour = this.getAvatarColour(assignee.name);
        const red = parseInt(backgroundColour.substring(1, 3), 16);
        const green = parseInt(backgroundColour.substring(3, 5), 16);
        const blue = parseInt(backgroundColour.substring(5, 7), 16);
        const borderColour = red * 0.299 + green * 0.587 + blue * 0.114 > 186 ? '#555' : '#aaa';
        const fontColour = red * 0.299 + green * 0.587 + blue * 0.114 > 186 ? '#222' : '#eee';
        return `height: 18px; width: 18px; border-color: ${borderColour}; background-color: ${backgroundColour}; color: ${fontColour}`;
    }

    public async selectDay(targetDay: string, selectMode = false, event: Event) {
        if (selectMode) {
            this.selectAllItems(targetDay);
            event.stopImmediatePropagation();
            return;
        }

        let moved = false;
        if (this.selectedScheduleItems.length) {
            moved = await this.moveScheduleItems(targetDay, this.selectedScheduleItems);
        }
        this.unselectAllItemsSelected();
        if (!moved) this.updateWeekData();
    }

    public async loadWeek(forward = true) {
        const before12weeks = this._weekStart
            .clone()
            .isSameOrBefore(this.currentDate.clone().subtract(KEEP_OCCURRENCES_AFTER_X_WEEKS, 'weeks').startOf('weeks'));

        if (before12weeks && !forward && ApplicationState.getSetting('global.archive-occurrences-before-days', true))
            return new NotifyUserMessage('schedule.occurrences-are-archived-from-this-point-on');
        new LoaderEvent(true);
        setTimeout(async () => {
            try {
                const stopwatch = new Stopwatch('loading week');

                const currentStartIso = this.loadedWeeks[0] || moment().startOf('isoWeek').format('YYYY-MM-DD');
                const startIso = forward ? currentStartIso : moment(currentStartIso).subtract(1, 'week').format('YYYY-MM-DD');

                const currentEndIso = this.loadedWeeks[this.loadedWeeks.length - 1] || moment().startOf('isoWeek').format('YYYY-MM-DD');
                const endIso = forward ? moment(currentEndIso).add(1, 'week').format('YYYY-MM-DD') : currentEndIso;

                stopwatch.lap('configured start and end dates');

                forward ? this.loadedWeeks.push(endIso) : this.loadedWeeks.unshift(startIso);

                const dateFilter = this.getCurrentDateFilter();
                const updates = await ScheduleService.getScheduledJobsByDay(moment(this.loadedWeeks[0]), dateFilter, [], undefined);

                Object.assign(this.scheduleDataWide, updates);

                stopwatch.lap('assigned schedule data for week');

                stopwatch.lap('loaded new week to data');

                this._weekStart = moment(forward ? this.loadedWeeks[this.loadedWeeks.length - 1] : this.loadedWeeks[0]);

                stopwatch.lap('set new week start');

                this.updateWeekData();
                stopwatch.lap('updated the week detail data');

                // if (forward) {
                //     setTimeout(() => {
                //         const week = document.getElementById(`week${endIso}`);
                //         if (week) {
                //             const fitsInViewport = (week.offsetHeight + 36) <= (window.innerHeight - 64);
                //             const amount = fitsInViewport ? week.offsetHeight : week.offsetTop + 2;
                //            // Utilities.scrollTo(document.querySelector('main'), amount, 250, fitsInViewport, easeOut);
                //         }
                //         stopwatch.lap('initialised the scroll');
                //     }, 50);
                // }
                stopwatch.stop();
            } catch (error) {
                new Prompt('general.error', 'multi.day-view-error-text', { cancelLabel: '' }).show();
            }
            new LoaderEvent(false);
        }, 50);
    }

    protected async selectUserWide(workload: Workload) {
        try {
            await super.selectUser(workload);

            new LoaderEvent(true);
            requestAnimationFrame(async () => {
                await this.updateWideDataForLoadedWeeks();
                await this.updateWeekData();
            });
        } finally {
            new LoaderEvent(false);
        }
    }

    private async updateWideDataForLoadedWeeks() {
        let userFilter: Array<string>;
        if (this.selectedWorkLoad && this.selectedWorkLoad.userOrTeam && this.selectedWorkLoad.userOrTeam._id === 'UNASSIGNED') {
            userFilter = [];
        } else if ((this.selectedWorkLoad?.userOrTeam as AccountUser)?.email) {
            userFilter = ScheduleService.getUserAndTeamIds((this.selectedWorkLoad?.userOrTeam as AccountUser)?.email);
        } else {
            const activeUserEmailOrTeamId = this.activeUserEmailOrTeamId;
            userFilter =
                !!activeUserEmailOrTeamId &&
                this.selectedWorkLoad &&
                this.selectedWorkLoad.userOrTeam &&
                this.selectedWorkLoad.userOrTeam.name !== 'All'
                    ? [activeUserEmailOrTeamId]
                    : [];
        }

        const dateFilter = this.getCurrentDateFilter();

        const updates = await ScheduleService.getScheduledJobsForUsersAndTeamsByDay(
            userFilter,
            moment(this.loadedWeeks[0]),
            dateFilter,
            [],
            undefined,
            this._loadedJobIds,
            true
        );

        this.scheduleDataWide = updates;
    }

    public completeDragDrop = async (
        oldIndex: number | Array<number>,
        newIndex: number | Array<number>,
        sourceDay?: string,
        targetDay?: string | null
    ) => {
        try {
            if (!targetDay) return;

            if (Array.isArray(newIndex)) newIndex = newIndex[0];
            const sourceDayData = this.getDataForDay(sourceDay)
                .slice()
                .sort((a, b) => (a.plannedOrder || 0) - (b.plannedOrder || 0));
            const targetDayData = this.getDataForDay(targetDay)
                .slice()
                .sort((a, b) => (a.plannedOrder || 0) - (b.plannedOrder || 0));

            const itemsInDrag = (
                this.selectedScheduleItems.length || Array.isArray(oldIndex) ? this.selectedScheduleItems : [sourceDayData[oldIndex]]
            ).filter(Boolean);

            let moved = false;
            if (itemsInDrag.length) {
                moved = await this.moveScheduleItems(targetDay, itemsInDrag);
                if (moved) await ScheduleService.handleDragComplete(newIndex, targetDayData, itemsInDrag, false);
            }
            this.unselectAllItemsSelected();
            if (!moved) this.updateWeekData();
        } catch (error) {
            Logger.debug(`Failed to sort the job occurrences`, error);
        }
    };

    private getCurrentDateFilter() {
        const start = this.loadedWeeks[0];
        const end = moment(this.loadedWeeks[this.loadedWeeks.length - 1])
            .add(6, 'days')
            .format('YYYY-MM-DD');
        const dateFilter = new DateFilterOption('week', start, end);
        return dateFilter;
    }

    private async moveScheduleItems(targetDay: string, itemsInDrag: Array<ScheduleItem>) {
        itemsInDrag = itemsInDrag.filter(Boolean);
        if (!itemsInDrag.length) return false;
        if (itemsInDrag.every(x => x.date && x.date.slice(0, 10) === targetDay.slice(0, 10))) return true;
        const description = `Would you like to replan ${itemsInDrag.length} job${itemsInDrag.length > 1 ? 's' : ''} to ${moment(
            targetDay
        ).format('ddd, Do MMM')}`;
        if (
            !(await new Prompt('general.confirm', description as TranslationKey, {
                okLabel: 'actions.replan',
                cancelLabel: 'general.cancel',
            }).show())
        ) {
            return false;
        }

        await ScheduleService.replan(itemsInDrag, targetDay, ApplicationState.instance.account.schedulingBehavior === 'update-schedule');
        return true;
    }

    public async viewScheduleItem(scheduleItem: ScheduleItem, event: Event) {
        if (scheduleItem.disabled) return event && event.stopImmediatePropagation();

        if (this.selectableMode) {
            event && event.stopImmediatePropagation();
            return this.selectItem(scheduleItem, event);
        }
        try {
            const dialog = new ScheduleDetailsDialog(scheduleItem);
            await dialog.show(DialogAnimation.SLIDE);
        } catch (error) {
            Logger.error('Error in viewScheduleItem() on ScheduleItemListCustomElement', { scheduleItem, error });
        }

        if (event) event.stopImmediatePropagation();
    }

    private setSelectableMode() {
        this.selectableMode = !!this.selectedScheduleItems.length;

        if (this.selectableMode === true) {
            ActionBarEvent.fromSelectedScheduleItems(
                this.unselectAllItemsSelected,
                ScheduleService.getBulkActionsForScheduleItem(this.selectedScheduleItems, undefined, this.bulkActionWrapper),
                this.selectedScheduleItems
            );
        } else {
            new ActionBarEvent(false);
        }
    }

    protected selectAllItems = (day: string) => {
        const weekStartingSource = moment(day).startOf('isoWeek').format('YYYY-MM-DD');
        const weekSource = this.weeks[weekStartingSource];
        for (let i = 0; i < weekSource.weekDayData[day].items.length; i++) {
            const scheduleItem = weekSource.weekDayData[day].items[i];
            this.selectItem(scheduleItem, null);
        }
    };

    protected selectItem(scheduleItem: ScheduleItem, event?: Event | null) {
        if (scheduleItem.disabled) return;
        scheduleItem.selected = !scheduleItem.selected;
        if (scheduleItem.selected) {
            this.selectedScheduleItems.push(scheduleItem);
        } else {
            this.selectedScheduleItems.splice(this.selectedScheduleItems.indexOf(scheduleItem), 1);
        }
        this.setSelectableMode();
        if (event) event.stopImmediatePropagation();
    }

    private unselectAllItemsSelected = () => {
        for (const item of this.selectedScheduleItems) {
            item.selected = false;
        }
        this.selectedScheduleItems.splice(0);
        this.selectableMode = false;
        new ActionBarEvent(false);
    };

    private bulkActionWrapper = async (
        action: (scheduleItems: Array<ScheduleItem>, unselectMethod: () => void, allScheduleItems?: Array<ScheduleItem>) => Promise<any>,
        message: TranslationKey
    ) => {
        if (this.selectedScheduleItems.length) {
            try {
                if (await action(this.selectedScheduleItems, this.unselectAllItemsSelected)) {
                    new NotifyUserMessage(<TranslationKey>(this.selectedScheduleItems.length.toString() + ' ' + message), {
                        count: this.selectedScheduleItems.length.toString(),
                    });
                }
            } catch (error) {
                new NotifyUserMessage('notifications.oh-no');
                Logger.error(`Error during bulk action wrapper in schedule item list custome element`, { action, error });
            }
        }
    };

    public setPriceAndTimeOnItems(items: Array<ScheduleItem>) {
        const locatables = items
            .filter(s => s.occurrence.location && s.occurrence.location.lngLat)
            .map(s => s.occurrence.location?.lngLat)
            .filter(x => !!x) as Array<LngLat>;
        if (locatables.length > 1) {
            let minLng = locatables[0][0];
            let maxLng = locatables[0][0];
            let minLat = locatables[0][1];
            let maxLat = locatables[0][1];
            for (const latLng of locatables) {
                if (latLng[0] < minLng) minLng = latLng[0];
                else if (latLng[0] > maxLng) maxLng = latLng[0];

                if (latLng[1] < minLat) minLat = latLng[1];
                else if (latLng[1] > maxLat) maxLat = latLng[1];
            }
        }

        let totalPriceVal: string;
        let totalTimeVal: string;
        let totalPrice = 0;
        let totalTime = 0;
        let showPrice = true;

        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            if (item.status !== JobOccurrenceStatus.Skipped && item.canViewBalance) totalPrice += item.occurrence.price || 0;
            totalTime += Utilities.durationToMinutes(items[i].occurrence.duration);

            const from = items[i - 1] && items[i - 1].occurrence.location && items[i - 1].occurrence.location?.lngLat;
            if (from) item.refreshDistance(from);

            item.refreshPlannedOrder();
        }

        //only show week total price if any prices visible on items.
        showPrice =
            items.filter(i => {
                return i.canViewBalance;
            }).length > 0;
        totalPriceVal = totalPrice.toFixed(2).toString();

        if (!totalTime) totalTimeVal = '';
        else {
            const hours = Math.floor(totalTime / 60);
            const minutes = totalTime % 60;
            totalTimeVal = '| ' + hours.toString() + 'h ' + (minutes ? minutes.toString() + 'm' : '') + ' ';
        }
        return {
            showPrice,
            totalPrice,
            totalTime,
            totalPriceVal,
            totalTimeVal,
        };
    }

    protected itemIsHideable(scheduleItem: ScheduleItem) {
        return !(
            this.viewOptions.hideDoneAndSkippedItems &&
            (scheduleItem.status === JobOccurrenceStatus.Done || scheduleItem.status === JobOccurrenceStatus.Skipped)
        );
    }
}
