import { FormHelperText } from '@mui/material';
import { FormStep } from '@nexdynamic/nex-ui-react';
import { ErrorMessage, Field } from 'formik';
import { CheckboxWithLabel } from 'formik-mui';
import { t } from '../../../../../../t';

export const CampaignLiveStep = () => {
    const fieldName = 'ongoing';
    return (
        <FormStep fields={[fieldName]} title={t('campaigns-form.live')} description={t('campaigns-form.live-description')}>
            <Field data-testid="ongoing" type="checkbox" Label={{ label: 'Ongoing' }} name={fieldName} component={CheckboxWithLabel} />
            <FormHelperText error={true}>
                <ErrorMessage name={fieldName} />
            </FormHelperText>
        </FormStep>
    );
};
