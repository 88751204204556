import type {
    Customer,
    ExternalJobCalendarSyncConfigData,
    FrequencyData,
    Job,
    JobGroup,
    JobInitialFields,
    LngLat,
    QuotedJob,
    Tag,
    TranslationKey,
} from '@nexdynamic/squeegee-common';
import {
    AlphanumericCharColourDictionary,
    Frequency,
    FrequencyBuilder,
    FrequencyType,
    JobOccurrence,
    JobOccurrenceStatus,
    sortByDateAsc,
    sortByDateDesc,
    wait,
} from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { ApplicationState } from '../ApplicationState';
import { DateTimePicker } from '../Components/DateTimePicker/DateTimePicker';
import { CustomerSavedMessage } from '../Customers/CustomerMessage';
import { CustomerService } from '../Customers/CustomerService';
import { jobOffScheduleDiffDetails } from '../Customers/Views/jobOffScheduleDiffDetails';
import { Data } from '../Data/Data';
import type { DataChangeNotificationType } from '../Data/DataChangeNotificationType';
import { createBackup } from '../Data/createBackup';
import { DialogAnimation } from '../Dialogs/DialogAnimation';
import { FrequencyPickerDialog } from '../Dialogs/FrequencyPicker/FrequencyPickerDialog';
import { Prompt } from '../Dialogs/Prompt';
import { SimpleSelect } from '../Dialogs/SimpleSelect';
import { LoaderEvent } from '../Events/LoaderEvent';
import { LocationUtilities } from '../Location/LocationUtilities';
import { Logger } from '../Logger';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import { RoundService } from '../Rounds/RoundService';
import { ScheduleActionEvent } from '../Schedule/Actions/ScheduleActionEvent';
import type { ScheduleItem } from '../Schedule/Components/ScheduleItem';
import { ScheduleService } from '../Schedule/ScheduleService';
import { updateProjectedJobScheduleResultCache } from '../Schedule/updateProjectedJobScheduleResultCache';
import { Api } from '../Server/Api';
import { AssignmentService } from '../Users/Assignees/AssignmentService';
import { Utilities } from '../Utilities';
import { t } from '../t';
import type { DateFilterOption } from './Filters/DateFilterOption';
import { JobDeletedMessage, JobSavedMessage } from './JobMessage';
import { JobOccurrenceService } from './JobOccurrenceService';
import { JobSummary } from './JobSummary';

export class JobService {
    private static _colours = new AlphanumericCharColourDictionary();

    public static async pickFrequency(
        job: Job,
        showFrequency = true,
        showOneOff = true,
        showJobGroups = true,
        mode: 'frequency' | 'one-off' | 'external' = job.frequencyType === FrequencyType.NoneRecurring ? 'one-off' : 'frequency',
        fromLocation?: LngLat,
        round?: Tag,
        matchingText?: Array<string>,
        quoteConversion = false,
        applyRange = false,
        removeRange = false
    ) {
        const isQuoteJob = job.resourceType === 'quotedjobs';
        if (showOneOff && !showFrequency && !showJobGroups) {
            const datePicker = new DateTimePicker(false, job.date, 'dates.select-date', true);
            datePicker.init();
            await datePicker.open();
            if (!datePicker.canceled) {
                job.date = datePicker.selectedDate.slice(0, 10);
                job.frequencyType = FrequencyType.NoneRecurring;
                job.frequencyInterval = 0;
                delete job.frequencyDayOfMonth;
                delete job.frequencyDayOfWeek;
                delete job.frequencyWeekOfMonth;
                return true;
            }
        } else {
            const frequencyData: FrequencyData = {
                firstScheduledDate: job.date || '',
                interval: job.frequencyInterval,
                type: job.frequencyType,
                externalCalendarUrl: job.externalCalendarUrl,
                externalCalendarKeywords: job.externalCalendarKeywords,
                range: job.frequencyRange,
            };

            if (job.date) {
                const jobDate = moment(job.date);
                if (job.frequencyType === FrequencyType.MonthlyDayOfWeek) {
                    frequencyData.dayOfWeek = job.frequencyDayOfWeek
                        ? Array.isArray(job.frequencyDayOfWeek)
                            ? job.frequencyDayOfWeek[0]
                            : job.frequencyDayOfWeek
                        : jobDate.isoWeekday();
                    frequencyData.weekOfMonth = job.frequencyWeekOfMonth ? job.frequencyWeekOfMonth : Math.floor(jobDate.date() / 7);
                } else if (job.frequencyType === FrequencyType.Weeks) {
                    frequencyData.dayOfWeek = job.frequencyDayOfWeek
                        ? Array.isArray(job.frequencyDayOfWeek)
                            ? job.frequencyDayOfWeek[0]
                            : job.frequencyDayOfWeek
                        : jobDate.isoWeekday();
                } else if (job.frequencyType === FrequencyType.DayOfMonth) {
                    frequencyData.dayOfMonth = job.frequencyDayOfMonth ? job.frequencyDayOfMonth : jobDate.date();
                } else if (job.frequencyType === FrequencyType.NoneRecurring) {
                    frequencyData.interval = 0;
                }
            }

            const frequency = new Frequency(
                moment(job.date),
                frequencyData.interval,
                frequencyData.type,
                frequencyData.weekOfMonth,
                frequencyData.dayOfMonth,
                frequencyData.dayOfWeek,
                frequencyData.range
            );
            if (job.date) {
                const nextDate = frequency.getNextDate();
                if (nextDate) frequencyData.firstScheduledDate = nextDate;
            }

            const isNewFrequency = !Data.get<Customer>(job.customerId)?.jobs[job._id];

            const dialog = new FrequencyPickerDialog({
                frequencyData,
                showFrequency,
                showOneOff,
                showJobGroups,
                mode,
                distanceSortLngLat: fromLocation,
                round,

                defaultShowDateRequiredToggle: true,
                hasPreviouslySelectedDate: !!job.date,
                matchingText: matchingText,
                defaultDateRequired: !isQuoteJob,
                quoteConversion,
                applyRange,
                rangeText: removeRange ? `Add a frequency within the range ${frequency.summary}` : undefined,
                isNewFrequency,
            });
            const frequencyResult = await dialog.show(DialogAnimation.SLIDE_UP);

            if (!dialog.cancelled) {
                if (removeRange) {
                    job.frequencyRange = undefined;
                } else {
                    job.frequencyRange = frequencyResult.range;
                }

                job.date = dialog.dateRequired ? frequencyResult.firstScheduledDate : undefined;
                job.frequencyType = frequencyResult.type;
                job.frequencyInterval = frequencyResult.interval;
                job.frequencyDayOfMonth = frequencyResult.dayOfMonth;
                job.frequencyDayOfWeek = frequencyResult.dayOfWeek;
                job.frequencyWeekOfMonth = frequencyResult.weekOfMonth;
                //todo validate url
                job.externalCalendarUrl = frequencyResult.externalCalendarUrl;
                job.externalCalendarKeywords = frequencyResult.externalCalendarKeywords;
                job.scheduleAtExternalCalendarEndDateTime = frequencyResult.scheduleAtExternalCalendarEndDateTime;

                if (dialog.selectedJobGroup && dialog.selectedJobGroup.tag) {
                    const round = Data.get<JobGroup>(dialog.selectedJobGroup.tag._id);
                    if (round) job.rounds = [round];
                } else {
                    job.rounds = (job.rounds || []).filter(r => {
                        const round = Data.get<JobGroup>(r._id);
                        return !round || !round.frequencyData;
                    });
                }
                return true;
            }
        }
        return false;
    }

    public static getJobs() {
        const customers = Data.all<Customer>('customers');
        let jobs: Job[] = [];

        for (const customer of customers) {
            jobs = jobs.concat(Object.values(customer.jobs));
        }
        return jobs;
    }

    public static getActiveJobs() {
        const customers = Data.all<Customer>('customers');
        let jobs: Job[] = [];

        for (const customer of customers) {
            if (customer.state === 'inactive') continue;
            jobs = jobs.concat(Object.values(customer.jobs)).filter(x => !x.state || x.state === 'active');
        }
        return jobs;
    }

    public static getPendingJobs() {
        const jobs = Data.all<QuotedJob>('quotedjobs');
        return jobs.slice();
    }

    public static getActiveJobsInRound(roundId: string) {
        const customers = Data.all<Customer>('customers');
        let jobs: Job[] = [];

        for (const customer of customers) {
            if (customer.state === 'inactive') continue;
            jobs = jobs
                .concat(Object.values(customer.jobs))
                .filter(x => !x.state || (x.state === 'active' && x.rounds[0] && x.rounds[0]._id === roundId));
        }
        return jobs;
    }

    public static getPendingJobsInRound(roundId: string) {
        const jobs = Data.all<QuotedJob>('quotedjobs', x => x.rounds[0] && x.rounds[0]._id === roundId);
        return jobs.slice();
    }

    public static async getCustomerJobs(customerId: string) {
        const customer = Data.get<Customer>(customerId);
        return customer ? Object.keys(customer.jobs).map(jobId => customer.jobs[jobId]) : [];
    }

    public static getJob(customerId: string | undefined, jobId: string) {
        if (customerId) {
            const customer = Data.get<Customer>(customerId);
            return customer && customer.jobs[jobId];
        }
        for (const customer of Data.all<Customer>('customers')) {
            if (customer.jobs && customer.jobs[jobId]) return customer.jobs[jobId];
        }
    }

    public static validateJobAndNotifyUI(job: Job, dateRequired = false) {
        const errors = Utilities.validateAndNotifyUI(
            !!job.location && !!job.location.addressDescription,
            'validation.job-location-required'
        );
        Utilities.validateAndNotifyUI(!!job.services && !!job.services.length, 'validation.service-required', errors);
        if (dateRequired) Utilities.validateAndNotifyUI(!!job.date, 'validation.job-date-required', errors);
        return errors;
    }

    public static async hardResetSchedule(job: Job, from: string, beforeFrom = true) {
        let item = (beforeFrom && (await ScheduleService.getLastScheduleItemForJob(job, from))) || undefined;

        if (!item || item.date >= from) item = await ScheduleService.getNextDueScheduleItemForJob(job, from);

        if (!item) return;
        await JobService.updateJobSchedule(job, item.date, undefined, false, true);
    }

    public static async setJobDateToNextAvailable(job: Job) {
        const nextDate = JobService.getJobNextDueDate(job);
        if (nextDate) {
            job.date = nextDate;
            if (job.frequencyType === FrequencyType.MonthlyDayOfWeek || job.frequencyType === FrequencyType.Weeks) {
                const date = moment(nextDate);
                job.frequencyDayOfWeek = date.isoWeekday();
            }
        }
    }
    public static async hardResetSchedules(from = moment()) {
        new LoaderEvent(false);

        if (
            !(await new Prompt('reset-job-schedule.title', 'reset-job-schedule.overdue-confirm-text', {
                okLabel: 'general.confirm',
                cancelLabel: 'general.cancel',
                localisationParams: { date: from.format('LL') },
            }).show())
        )
            return;

        new LoaderEvent(true);
        const localisationParams = {
            dateFrom: from.format('LL'),
            user: ApplicationState.dataEmail,
        };
        const actionDetailsDescription = ApplicationState.localise('backup.reason.reset-schedule.description', localisationParams);
        if (!(await createBackup({ initiatorTask: 'reset-schedule', auditInfo: { items: [], actionDetailsDescription } }))) return;
        new LoaderEvent(true);
        const jobs = await JobService.getJobs();
        let count = 1;
        const date = from.clone().format().substring(0, 10);
        for (const job of jobs) {
            count++;
            count > 0 && count % 50 === 0 && (await wait(1));
            await JobService.hardResetSchedule(job, date, false);
        }

        await RoundService.hardResetRoundSchedules();

        new LoaderEvent(false);
        new ScheduleActionEvent('action-replan');
        new NotifyUserMessage('reset-job-schedule.success', { count: jobs.length.toString(), date: from.format('LL') });
    }

    public static async updateJobSchedule(
        job: Job,
        isoPlannedDate: string,
        originalOccurrencePlannedDate?: string,
        keepOldScheduleChoice?: boolean,
        quiet = false,
        originalOccurrenceDueDate?: string
    ) {
        job = Utilities.copyObject(job);
        job.date = isoPlannedDate;
        const jobDate = moment(isoPlannedDate, 'YYYY-MM-DD');

        switch (job.frequencyType) {
            case FrequencyType.MonthlyDayOfWeek:
                job.frequencyDayOfWeek = jobDate.isoWeekday();
                job.frequencyWeekOfMonth = Math.floor(jobDate.date() / 7);
                break;
            case FrequencyType.Weeks:
                job.frequencyDayOfWeek = jobDate.isoWeekday();
                break;
            case FrequencyType.DayOfMonth:
                job.frequencyDayOfMonth = jobDate.date();
                break;
        }

        await JobService.addOrUpdateJob(
            job.customerId,
            job,
            originalOccurrencePlannedDate,
            keepOldScheduleChoice,
            quiet,
            undefined,
            undefined,
            originalOccurrenceDueDate
        );
    }

    public static getJobNextDueDate(job: Job, from?: moment.Moment) {
        const frequency = new Frequency(
            moment(job.date),
            job.frequencyInterval,
            job.frequencyType,
            job.frequencyWeekOfMonth,
            job.frequencyDayOfMonth,
            Array.isArray(job.frequencyDayOfWeek) ? job.frequencyDayOfWeek[0] : job.frequencyDayOfWeek
        );
        const date = (from || moment()).format().substring(0, 10);
        const dates = frequency.getOccurrenceIsoDates(date, date, undefined, 1);

        if (dates.length) return dates[0];
    }

    public static async updateJobs(jobsToUpdate: Array<Job>, suppress = false) {
        const customersToUpdate = [];
        for (let index = 0; index < jobsToUpdate.length; index++) {
            const job = jobsToUpdate[index];
            const customer = CustomerService.getCustomer(job.customerId);
            if (customer) {
                customer.jobs[job._id] = job;
                customersToUpdate.push(customer);
            }
        }

        const notify: DataChangeNotificationType = suppress ? 'lazy' : 'immediate';
        await Data.put(customersToUpdate, true, notify);
    }

    public static async addOrUpdateJob(
        customerOrCustomerId: string | Customer,
        updatedJob: Job,
        originalOccurrencePlannedDate?: string,
        keepOldScheduleChoice?: boolean,
        suppressDataChangedEvent = false,
        ignoreExistingValue = false,
        fieldsToUpdate?: Array<keyof JobOccurrence>,
        originalOccurrenceDueDate?: string,
        initialFields?: JobInitialFields,
        assignees?: Array<string>
    ) {
        const errors = JobService.validateJobAndNotifyUI(updatedJob);
        if (errors.length) throw errors;

        const customer = typeof customerOrCustomerId === 'string' ? Data.get<Customer>(customerOrCustomerId) : customerOrCustomerId;
        if (!customer) throw 'Unable to save job as customer does not exist yet.';
        let originalJob: Job | undefined;
        if (customer && customer.jobs[updatedJob._id]) {
            originalJob = customer.jobs[updatedJob._id];
        }

        const originalAssignees = !originalJob ? [] : AssignmentService.getAssignees(originalJob).map(x => x._id);

        const scheduleHasChanged =
            originalJob &&
            (updatedJob.date !== originalJob.date ||
                updatedJob.frequencyDayOfMonth !== originalJob.frequencyDayOfMonth ||
                updatedJob.frequencyDayOfWeek !== originalJob.frequencyDayOfWeek ||
                updatedJob.frequencyInterval !== originalJob.frequencyInterval ||
                updatedJob.frequencyType !== originalJob.frequencyType ||
                keepOldScheduleChoice === false)
                ? true
                : false;

        if (scheduleHasChanged) {
            const isNewJobDateAfterOldDate =
                (originalJob && updatedJob.date && originalJob.date && updatedJob.date > originalJob.date) || false;
            const isNewDateInTheFuture = updatedJob.date && updatedJob.date > moment().format('YYYY-MM-DD');

            const wasAOneOff = !!originalJob && originalJob.frequencyType === FrequencyType.NoneRecurring;

            if (!wasAOneOff && (isNewJobDateAfterOldDate || isNewDateInTheFuture)) {
                if (keepOldScheduleChoice === undefined) {
                    new LoaderEvent(false);
                    keepOldScheduleChoice = await JobService.keepOldScheduleChoice(moment(updatedJob.date, 'YYYY-MM-DD').format('ll'));
                    new LoaderEvent(true);
                }
            }

            if (originalJob && keepOldScheduleChoice && !wasAOneOff) {
                const concreteToDate = originalOccurrencePlannedDate || moment().format('YYYY-MM-DD');
                await JobService.makeExistingScheduleConcrete(
                    updatedJob,
                    originalJob,
                    customer._id,
                    concreteToDate,
                    originalOccurrenceDueDate
                );
            } else if (!wasAOneOff) {
                await JobService.clearModifiedNotDoneJobOccurrences(updatedJob);
            } else if (originalJob?.date && wasAOneOff) {
                const job = originalJob;
                const appointments = Data.all<JobOccurrence>(
                    'joboccurrences',
                    o => o.isoDueDate === job.date || o.isoPlannedDate === job.date,
                    { jobId: job._id, status: JobOccurrenceStatus.NotDone }
                );
                if (appointments.length) await Data.delete(appointments.slice());
            }
        }

        if (originalJob) {
            const assigneesHaveChangedAndNeedUpdating = !!assignees && assignees?.sort().toString() !== originalAssignees.sort().toString();
            const assigneesToUpdate = assigneesHaveChangedAndNeedUpdating ? assignees : undefined;
            await JobOccurrenceService.updateExistingOccurrencesOnJobUpdate(
                originalJob,
                updatedJob,
                ignoreExistingValue,
                fieldsToUpdate,
                assigneesToUpdate
            );
        }

        customer.jobs[updatedJob._id] = updatedJob;

        const notify: DataChangeNotificationType = suppressDataChangedEvent ? 'lazy' : 'immediate';
        await Data.put(customer, true, notify);

        if (assignees) {
            if (assignees.length) {
                await AssignmentService.assign(updatedJob, assignees, false, true, suppressDataChangedEvent);
            } else {
                await AssignmentService.unassign(updatedJob, undefined, false, suppressDataChangedEvent);
            }
        }

        if (!originalJob) {
            await ScheduleService.addJobToOrderData(updatedJob._id);
            await Data.setUniqueExternalId(updatedJob, 'JOB');
        }

        // Only Do this if we have initial fields
        if (initialFields && updatedJob.date && (initialFields.firstDate !== undefined || initialFields.initialPrice !== undefined)) {
            const initialOccurrence = new JobOccurrence(updatedJob, initialFields.firstDate || updatedJob.date);
            if (typeof initialFields.initialPrice === 'number') initialOccurrence.price = initialFields.initialPrice;

            await Data.put(initialOccurrence, true, notify);
        }

        //external calendar processing
        JobService.updateJobExternalCalendarSettings(updatedJob);

        if (!suppressDataChangedEvent) {
            new JobSavedMessage(updatedJob, updatedJob._id);
            new LoaderEvent(false);
        }

        updateProjectedJobScheduleResultCache();

        return updatedJob;
    }

    public static async syncExternalJobCalendarEvents(jobIds?: string): Promise<number> {
        try {
            const resp = await Api.get<{ count: number }>(null, '/api/process-calendar-sync' + (jobIds ? `?jobIds=${jobIds}` : ''));
            if (resp?.status === 200) {
                return resp.data ? resp.data.count : 0;
            }
            throw new Error('Non successful response ' + resp?.status);
        } catch (error) {
            Logger.error('Unable to refresh external calander feed for job Ids ' + jobIds, error);
            throw error;
        }
    }

    private static emptyCalendars: ExternalJobCalendarSyncConfigData = { externalJobCalendars: [] };
    private static async updateJobExternalCalendarSettings(job: Job, deleted = false) {
        const externalCalendarsSetting = await ApplicationState.getSetting('global.jobs.external-job-calendars', JobService.emptyCalendars);

        const isCalendarFeedEnabled =
            !deleted &&
            (job.state || 'active') === 'active' &&
            job.frequencyType === FrequencyType.ExternalCalendar &&
            job.externalCalendarUrl &&
            job.externalCalendarUrl.length > 0;

        const url = job.externalCalendarUrl || '';

        const existingJobExternalCalendar = externalCalendarsSetting.externalJobCalendars.find(c => c.jobId === job._id);

        // Remove existing calendar if it exists
        if (!isCalendarFeedEnabled) {
            if (!existingJobExternalCalendar) return;

            const index = externalCalendarsSetting.externalJobCalendars.indexOf(existingJobExternalCalendar);
            externalCalendarsSetting.externalJobCalendars.splice(index, 1);
            return await ApplicationState.setSetting('global.jobs.external-job-calendars', externalCalendarsSetting);
        }

        // If the existing entry is already identical, no need to update.
        if (
            existingJobExternalCalendar &&
            existingJobExternalCalendar.customerId === job.customerId &&
            existingJobExternalCalendar.externalCalendarUrl === url &&
            existingJobExternalCalendar.scheduleAtEnd === job.scheduleAtExternalCalendarEndDateTime &&
            existingJobExternalCalendar.externalCalendarKeywords === job.externalCalendarKeywords
        )
            return;

        // Replace existing calendar
        if (existingJobExternalCalendar) {
            existingJobExternalCalendar.customerId = job.customerId;
            existingJobExternalCalendar.externalCalendarUrl = url;
            existingJobExternalCalendar.scheduleAtEnd = job.scheduleAtExternalCalendarEndDateTime;
            existingJobExternalCalendar.externalCalendarKeywords = job.externalCalendarKeywords || '';
            return await ApplicationState.setSetting('global.jobs.external-job-calendars', externalCalendarsSetting);
        }

        // Add new calendar
        const calendar = {
            customerId: job.customerId,
            jobId: job._id,
            externalCalendarUrl: url,
            lastSyncedDate: '2020-01-01',
            scheduleAtEnd: job.scheduleAtExternalCalendarEndDateTime,
            externalCalendarKeywords: job.externalCalendarKeywords || '',
        };

        externalCalendarsSetting.externalJobCalendars.push(calendar);
        await ApplicationState.setSetting('global.jobs.external-job-calendars', externalCalendarsSetting);
    }

    public static async cleanJobExternalCalendarSettings() {
        const externalCalendarsSetting = JobService.emptyCalendars;
        externalCalendarsSetting.externalJobCalendars = [];
        await ApplicationState.setSetting('global.jobs.external-job-calendars', externalCalendarsSetting);

        for (const customer of Data.all<Customer>('customers')) {
            for (const job of Object.values(customer.jobs || {})) {
                await JobService.updateJobExternalCalendarSettings(job);
            }
        }
    }

    private static async makeExistingScheduleConcrete(
        job: Job,
        originalJob: Job,
        customerId: string,
        concreteToDate: string,
        occurrenceBeingMovedDueDate?: string
    ) {
        if (!originalJob.date) return;

        const refDate = job.date && job.date < originalJob.date ? job.date : originalJob.date;
        const scheduleItems = ScheduleService.getJobScheduleByCustomerIdAndJobId(customerId, job._id, moment(refDate), undefined, [
            JobOccurrenceStatus.NotDone,
        ]);
        const occurrencesToBeDeleted = [];
        const occurrencesToBeSaved = [];

        for (let index = 0; index < scheduleItems.length; index++) {
            const scheduleItem = scheduleItems[index];
            if (scheduleItem.status === JobOccurrenceStatus.Done && Data.get(scheduleItem.occurrence._id)) continue;
            if (occurrenceBeingMovedDueDate && scheduleItem.occurrence.isoDueDate?.slice(0, 10) === occurrenceBeingMovedDueDate) continue;
            if (moment(scheduleItem.date).isBefore(concreteToDate)) {
                const occurrence = Utilities.copyObject(scheduleItem.occurrence);
                occurrence.isoPlannedDate = scheduleItem.date;
                // don't want to save the date you were replanning froms
                if (!concreteToDate || occurrence.isoPlannedDate !== concreteToDate) occurrencesToBeSaved.push(occurrence);
            } else {
                // if this is a concrete saved occurrence then delete it
                if (scheduleItem.occurrence.updatedByUser) {
                    occurrencesToBeDeleted.push(scheduleItem.occurrence);
                }
            }
        }
        await Data.put(occurrencesToBeSaved, true, 'lazy');
        await Data.delete(occurrencesToBeDeleted, true, 'lazy');
    }

    private static async clearModifiedNotDoneJobOccurrences(job: Job) {
        const toDelete = Data.all<JobOccurrence>('joboccurrences', { jobId: job._id, status: JobOccurrenceStatus.NotDone }).slice();
        Data.delete(toDelete, true);
    }

    private static async keepOldScheduleChoice(newDateFormatted: string): Promise<boolean> {
        const options: Array<{ value: boolean; text: string }> = [
            { value: false, text: t('schedule.remove-previous-schedule') },
            { value: true, text: t('schedule.keep-concrete-previous-schedule') },
        ];
        const instructionsHtml = `Edited Schedule will start on <em>${newDateFormatted}</em>.`;
        const occurrenceChoiceDialog = new SimpleSelect<{ value: boolean; text: string }>(
            'dialogs.schedule-edited-title',
            options,
            'text',
            'value',
            instructionsHtml,
            undefined,
            {
                allowCancel: false,
                allowClickOff: false,
                okLabel: '',
                cancelLabel: '',
            }
        );
        const selectedChoice = await occurrenceChoiceDialog.show();
        if (!occurrenceChoiceDialog.cancelled) {
            return selectedChoice.value;
        } else {
            return true;
        }
    }

    public static async removeJob(customerId: string, job: Job) {
        const customer = Data.get<Customer>(customerId);
        if (customer) {
            delete customer.jobs[job._id];
            customer.deletedJobs = customer.deletedJobs || {};
            Data.updateStoredObjectMetaData(job, moment().toISOString(), new Date().valueOf());
            customer.deletedJobs[job._id] = job;
            await Data.put(customer);
            new CustomerSavedMessage(customer);
            // need this as well as customer saved so any schedule items for deleted job can also close
            // but can stay open if other customer details have changed
            new JobDeletedMessage(job._id);
        }

        await JobService.updateJobExternalCalendarSettings(job, true);
    }

    public static getJobSummary(customerId: string, jobId: string) {
        const customer = Data.get<Customer>(customerId);
        return customer && JobService.getJobSummaryForJobAndCustomer(customer.jobs[jobId], customer);
    }

    public static async getJobSummaries() {
        const customers = Data.all<Customer>('customers');
        const jobsData: Array<JobSummary> = [];
        for (let i = 0; i < customers.length; i++) {
            const customer = customers[i];
            const jobIds = Object.keys(customer.jobs);
            for (let i = 0; i < jobIds.length; i++) {
                const jobId = jobIds[i];
                jobsData.push(JobService.getJobSummaryForJobAndCustomer(customer.jobs[jobId], customer));
            }
        }
        return jobsData;
    }

    private static _today?: string;
    protected static get today() {
        if (!JobService._today) {
            JobService._today = moment().format('YYYY-MM-DD');
            setTimeout(() => {
                JobService._today = undefined;
            }, 60000);
        }
        return JobService._today;
    }

    public static async getJobSummariesForCustomer(
        customer: Customer,
        dateFilter?: DateFilterOption,
        maxFutureOccurrences = 1,
        includeOffScheduleInfo = false
    ) {
        const jobsWithDetails: Array<JobSummary> = [];

        for (const job of Object.values(customer.jobs) || []) {
            const jobSummary = JobService.getJobSummaryForJobAndCustomer(job, customer);

            const scheduleItemsArray = await ScheduleService.getScheduleForJobs(
                jobSummary.job,
                moment().add(1, 'day').startOf('day'),
                dateFilter,
                [],
                maxFutureOccurrences
            );

            jobSummary.scheduleItemsArray = scheduleItemsArray.sort(sortByDateAsc);

            if (includeOffScheduleInfo) jobSummary.offScheduleInfo = jobOffScheduleDiffDetails(job, JobService.today);

            jobsWithDetails.push(jobSummary);

            // WTF: Prevent memory leak detection on iOS from killing the app.
            await wait(5);
        }
        return jobsWithDetails;
    }

    public static getJobSummaryForJobAndCustomer(job: Job, customer: Customer) {
        const jobSummary: JobSummary = new JobSummary();

        jobSummary.job = job;
        jobSummary.frequency = FrequencyBuilder.getFrequency(
            job.frequencyInterval,
            job.frequencyType,
            job.date,
            job.frequencyDayOfMonth,
            job.frequencyWeekOfMonth,
            Array.isArray(job.frequencyDayOfWeek) ? job.frequencyDayOfWeek[0] : job.frequencyDayOfWeek
        );

        if (job.rounds && job.rounds.length) {
            const roundsText = Utilities.localiseList(job.rounds.map(r => r.description as TranslationKey));
            jobSummary.roundsDescription = roundsText.substring(0, 1).toUpperCase() + roundsText.substring(1).toLowerCase();
        }

        if (job.services && job.services.length) {
            const servicesText = Utilities.localiseList(
                job.services.map(s => {
                    const quantity = s.quantity && s.quantity > 1 ? ` x ${s.quantity}` : '';
                    return `${s.description}${quantity}` as TranslationKey;
                })
            );
            jobSummary.servicesDescription = servicesText.substring(0, 1).toUpperCase() + servicesText.substring(1).toLowerCase();
        }

        jobSummary.customer = customer;

        if (job.location && job.location.lngLat && job.location.isVerified) {
            const distance = LocationUtilities.getDistance(job.location.lngLat);
            if (distance) {
                jobSummary.distance = distance;
                jobSummary.indicators.push({
                    description: distance.toFixed(2) + (ApplicationState.distanceUnits === 'miles' ? 'mi' : 'km'),
                    state: 'default',
                });
            }
        }

        JobService._updateJobSummaryAvatarAndColour(jobSummary);
        JobService._updateJobSummaryRoundsColour(jobSummary);

        return jobSummary;
    }

    private static _updateJobSummaryAvatarAndColour(jobSummary: JobSummary) {
        if (jobSummary && jobSummary.customer && jobSummary.customer.name && jobSummary.customer.name.trim().length > 0) {
            jobSummary.avatar = Utilities.getAvatarTextFromName(jobSummary.customer.name);
            jobSummary.avatarColour = JobService._colours[jobSummary.avatar.substring(0, 1).toUpperCase()] || JobService._colours.default;
        }
    }
    private static _updateJobSummaryRoundsColour(jobSummary: JobSummary) {
        if (jobSummary && jobSummary.job && jobSummary.job.rounds && jobSummary.job.rounds.length > 0) {
            for (const round of jobSummary.job.rounds) {
                if (!round.color) {
                    round.color === JobService._colours[round.description.substring(0, 1).toUpperCase()];
                }
            }
        }
    }

    public static getJobSummariesFromJobs(jobs: Array<Job>) {
        const jobsWithDetails: Array<JobSummary> = [];

        for (const job of jobs) {
            const customer = Data.get<Customer>(job.customerId);
            if (customer) {
                const jobSummary = JobService.getJobSummaryForJobAndCustomer(job, customer);
                jobsWithDetails.push(jobSummary);
            }
        }

        return jobsWithDetails;
    }

    public static async getJobSummariesForRound(roundId: string) {
        const jobsWithDetails: Array<JobSummary> = [];
        const customers = Data.all<Customer>('customers');

        for (let i = 0; i < customers.length; i++) {
            const customer = customers[i];
            const jobIds = Object.keys(customer.jobs);

            for (let i = 0; i < jobIds.length; i++) {
                const job = customer.jobs[jobIds[i]];
                for (let i = 0; i < job.rounds.length; i++) {
                    const round = job.rounds[i];
                    if (round._id === roundId) {
                        const jobSummary = JobService.getJobSummaryForJobAndCustomer(job, customer);
                        jobsWithDetails.push(jobSummary);
                    }
                }
            }
        }

        return jobsWithDetails;
    }

    public static getJobOccurancesPerYear(job: Job) {
        if (job.frequencyInterval === 0 || job.frequencyType === FrequencyType.NoneRecurring) return 1;

        switch (job.frequencyType) {
            case FrequencyType.Weeks:
                return 52 / job.frequencyInterval;
            case FrequencyType.Days:
                return 365 / job.frequencyInterval;
            case FrequencyType.DayOfMonth:
            case FrequencyType.MonthlyDayOfWeek:
                return 12 / job.frequencyInterval;
            default:
                return 0;
        }
    }

    public static getJobAppointments(customerId: string, jobId: string): Array<ScheduleItem> {
        const customer = Data.get<Customer>(customerId);
        if (!customer) throw 'getJobAppointments no customer found with id ' + customerId;

        const job = customer.jobs[jobId];
        if (!job) throw 'getJobAppointments no job with found with id ' + jobId + ' on customer ' + customerId;

        const appointments = JobService.getAppointmentsForJob(job);

        return appointments;
    }

    private static getAppointmentsForJob(job: Job): Array<ScheduleItem> {
        const appointments: Array<ScheduleItem> = [];
        const scheduleItemsArray = ScheduleService.getScheduleForJobs(job, moment(), undefined, [], 1);
        scheduleItemsArray.sort(sortByDateDesc);
        appointments.push(...scheduleItemsArray);

        return appointments;
    }
}
